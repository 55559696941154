import classes from './HomepageCtaBlock.module.css';
import { useNavigate } from 'react-router-dom';
import Button from '../UI/Button';
import PageHeaderText from '../UI/PageHeaderText';
import TextBlock from '../UI/TextBlock';
import devicesImage from '../../images/promo_image_small.png';

const HomepageCtaBlock = () => {

    const navigate = useNavigate();

    return (
        <div className={classes.wrapper}>
            <div className={classes.card}>
                <img src={devicesImage} className={classes.image} alt="Brain Raider works on all devices."/>
            </div>
            <div className={classes.card}>
                <div className={classes.inner}>
                    <PageHeaderText>Assessment Made Easy</PageHeaderText>
                    <TextBlock>Brain Raider is your one-stop-shop for formative assessment of the new Science TEKS. Assign any combination of questions from our huge original item bank, monitor your students' progress in real-time to prevent cheating, and receive concrete action steps based on specific student strengths and weaknesses. <strong>Let us handle assessment creation, delivery, and data analysis so you can focus on moving learning forward in your classroom!</strong></TextBlock>
                    <br />
                    <Button confirm onClick={() => {navigate('/signup')}}>Create a Free Teacher Account</Button>
                </div>
            </div>
        </div>
    );
};

export default HomepageCtaBlock;