import { FaCheck } from 'react-icons/fa';
import { FaXmark } from 'react-icons/fa6';
import { BiSort, BiSortAZ, BiSortZA } from 'react-icons/bi';
import classes from './VerificationTable.module.css';

const toTimePassed = (timestamp) => {
    const currentTime = new Date();
    const elapsedTimeInSeconds = Math.floor((currentTime - timestamp) / 1000) + 5; // Add 5 seconds

    if (elapsedTimeInSeconds < 60) {
        return `${elapsedTimeInSeconds} second${elapsedTimeInSeconds !== 1 ? 's' : ''} ago`;
    } else if (elapsedTimeInSeconds < 3600) { // Less than an hour
        const minutes = Math.floor(elapsedTimeInSeconds / 60);
        return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
    } else { // More than an hour
        const hours = Math.floor(elapsedTimeInSeconds / 3600);
        return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
    }
};

const toMobileTimePassed = (timestamp) => {
    const currentTime = new Date();
    const elapsedTimeInSeconds = Math.floor((currentTime - timestamp) / 1000) + 5; // Add 5 seconds

    if (elapsedTimeInSeconds < 60) {
        return `${elapsedTimeInSeconds} sec`;
    } else if (elapsedTimeInSeconds < 3600) { // Less than an hour
        const minutes = Math.floor(elapsedTimeInSeconds / 60);
        return `${minutes} min`;
    } else { // More than an hour
        const hours = Math.floor(elapsedTimeInSeconds / 3600);
        return `${hours} hr`;
    }
};

const ensureHttps = (url) => {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
        return `https://${url}`;
    }
    return url;
};

const VerificationTable = (props) => {
    return (
        <>
        <table className={ classes.contentTable }>
                <thead>
                <tr className={ classes.headers }>
                    <th className={ `${classes.header} ${classes.timeHeader}` }>
                        <div className={ classes.aligner}>
                            <div className={ classes.text }>Time</div>
                            <div className={ classes.icon } onClick={ props.onSort }>
                                { props.sorted === 0 && <BiSort /> }
                                { props.sorted === 1 && <BiSortZA /> }
                                { props.sorted === -1 && <BiSortAZ /> }
                            </div>
                        </div>
                    </th>
                    <th className={ classes.desktopHeader }>Name</th>
                    <th className={ classes.desktopHeader }>Email</th>
                    <th className={ classes.desktopHeader }>Provided Link</th>
                    <th className={ classes.mobileHeader}>Info</th>
                    <th className={ `${classes.header} ${classes.actionsHeader}`}>Actions</th>
                </tr>
                </thead>
                { props.queue && Object.keys(props.queue)?.length > 0 &&
                    <tbody>
                    {
                        Object.keys(props.queue)?.map(
                            (request, index) =>
                                <tr key={index}>
                                    <td className={ `${index === Object.keys(props.queue).length - 1 ? classes.last : ''} ${ classes.rightBorder }  ${ classes.cell} ${classes.timeCell}` }><span className={classes.desktopTime}>{toTimePassed(props.queue[request].time)}</span><span className={classes.mobileTime}>{toMobileTimePassed(props.queue[request].time)}</span></td>
                                    <td className={ `${index === Object.keys(props.queue).length - 1 ? classes.last : ''} ${ classes.rightBorder } ${ classes.desktopCell}` }>
                                        {`${props.queue[request].first_name} ${props.queue[request].last_name}`}
                                    </td>
                                    <td className={ `${index === Object.keys(props.queue).length - 1 ? classes.last : ''} ${ classes.rightBorder }  ${ classes.desktopCell}` }>
                                        {props.queue[request].email}
                                    </td>
                                    <td className={ `${index === Object.keys(props.queue).length - 1 ? classes.last : ''} ${ classes.rightBorder }  ${ classes.desktopCell}` }>
                                        <a href={ensureHttps(props.queue[request].identity_link)} target="_blank" rel="noopener noreferrer">{props.queue[request].identity_link}</a>
                                    </td>
                                    <td className={ `${index === Object.keys(props.queue).length - 1 ? classes.last : ''} ${ classes.rightBorder }  ${ classes.mobileCell}` }>
                                        {`${props.queue[request].first_name} ${props.queue[request].last_name}`}<br />
                                        {props.queue[request].email}<br />
                                        <a href={ensureHttps(props.queue[request].identity_link)} target="_blank" rel="noopener noreferrer">{props.queue[request].identity_link}</a>
                                    </td>
                                    <td className={ `${index === Object.keys(props.queue).length - 1 ? classes.last : ''} ${ classes.actionsCell } ${ classes.cell}` }>
                                        <button
                                            className={ classes.approve }
                                            onClick={ props.onApprove.bind(null, request) }
                                        >
                                            <FaCheck />
                                        </button>
                                        <button
                                            className={ classes.deny }
                                            onClick={ props.onDeny.bind(null, request) }
                                        >
                                            <FaXmark />
                                        </button>
                                    </td>
                                </tr>
                        )
                    }
                </tbody>
            }
            </table>
        </>
    );
};

export default VerificationTable;