import SingleColumn from '../components/UI/SingleColumn';
import { useEffect } from 'react';
import { useOutletContext, useNavigate } from 'react-router-dom';
import TextBlock from '../components/UI/TextBlock';
import PageHeaderText from '../components/UI/PageHeaderText';
import HomepageBottomBar from '../components/Cards/HomepageBottomBar';

const ChildrensPrivacyPage = () => {
    const [setHeaderSize] = useOutletContext();
    useEffect(() => {setHeaderSize('small');}, [setHeaderSize]);

    const navigate = useNavigate();

  return (
    <SingleColumn>
        <PageHeaderText>Brain Raider, LLC Children’s Privacy Policy</PageHeaderText>
        <TextBlock>
            Effective date: July 1, 2023<br /><br />
            Brain Raider, LLC ("us", "we", or "our") is committed to protecting the privacy of children in compliance with the Children's Online Privacy Protection Act ("COPPA"). This Children’s Privacy Policy outlines how we collect, use, and disclose personal information from children under the age of 13 who use the Brain Raider website (the "Service").
            <br /><br /><strong>Collection of Information</strong><br /><br />
            We do not knowingly collect personal information from children under the age of 13 unless provided with parental consent or as permitted by law. If we become aware that we have collected personal information from a child under 13 without parental consent, we will take steps to delete that information.
            <br /><br /><strong>Information We Collect</strong><br /><br />
            For children under the age of 13, we may collect limited personal information necessary for educational purposes, such as:
            <ul>
            <li>First name</li>
            <li>Last name</li>
            <li>Email address </li>
            </ul>
            We may also collect non-personally identifiable information for educational and operational purposes, such as usage patterns and interactions with the Service.
            <br /><br /><strong>Use of Information</strong><br /><br />
            The personal information collected from children under 13 is used solely for educational purposes within the Brain Raider Materials. We do not use this information for marketing or advertising purposes.
            <br /><br /><strong>Disclosure of Information</strong><br /><br />
            We may disclose personal information from children under 13 to third-party service providers for the sole purpose of delivering the educational services provided by the Brain Raider Materials. These service providers are contractually obligated to handle personal information securely and confidentially. For more information, please see our list of <span onClick={() => navigate('/third-party-vendors')} style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}>Third Party Vendors</span>.
            <br /><br />We may also disclose personal information if required by law or to protect the safety of children.
            <br /><br /><strong>Parental Rights</strong><br /><br />
            Parents have the right to review, delete, or refuse further collection of their child's personal information. Parents can contact us at <a href="mailto:support@brainraider.com">support@brainraider.com</a> to exercise these rights or to request a copy of the personal information we have collected about their child.
            <br /><br /><strong>Data Security</strong><br /><br />
            We prioritize the security and confidentiality of all personal information, especially that of children under 13. The following steps are taken to safeguard against unauthorized use, disclosure, or access to personal data:
            <ul>
            <li>Having users create unique usernames and confidential passwords.</li>
            <li>Prioritizing security in engineering decisions.</li>
            <li>Encrypting all User Managed Data during transmission and while at rest on Brain Raider servers.</li>
            <li>Employing secure storage and transmission mechanisms for passwords. Passwords are never stored in plain text but rather are salted and hashed using encryption libraries.</li>
            </ul>
            While we try to maintain the security of our information systems, we cannot guarantee absolute security against various potential threats such as outages, attacks, human error, system failures, or unauthorized usage. 
            <br /><br /><strong>Contact Us</strong><br /><br />
            If you have any questions or concerns about our COPPA Privacy Policy or our practices regarding children's personal information, please contact us at <a href="mailto:support@brainraider.com">support@brainraider.com</a>.
            <br /><br />This COPPA Privacy Policy applies solely to information collected from children under 13 in compliance with COPPA regulations. For information about our general privacy practices, please refer to our <span onClick={() => navigate('/privacy-policy')} style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}>Privacy Policy</span>.
        </TextBlock>
        <HomepageBottomBar />
    </SingleColumn>
  );
}

export default ChildrensPrivacyPage;