import { useState, useEffect } from 'react';
import classes from './ImageInput.module.css';
import { FaCloudUploadAlt } from 'react-icons/fa';

const ImageInput = (props) => {
    const [imageUpload, setImageUpload] = useState(props.initialImageURL || null);

    const uploadChangeHandler = (event) => {
        const newFile = event.target.files[0];
        setImageUpload(newFile);
        props.onUpload(newFile);
    };

    useEffect(() => {
        setImageUpload(props.initialImageURL || null);
    }, [props.initialImageURL]);

    const resetImageUpload = () => {
        setImageUpload(null);
        props.onUpload(null);

        const inputElement = document.getElementById('image-upload');
        if (inputElement) {
            inputElement.value = '';
        };
    };
    
    return(
        <>
            { imageUpload && (
                <div className={ classes.imagePreview }>
                    <img src={((typeof imageUpload) === 'string') ? imageUpload : URL.createObjectURL(imageUpload)} alt='Upload' />
                    <br />
                    <button className={classes.resetButton} onClick={ resetImageUpload }>Remove Image</button>
                </div>
            )}
            <div className={ `${classes.imageUpload} ${ imageUpload ? classes.hasImage : '' }` }>
                <label
                    htmlFor='image-upload'
                    className={ classes.imageUploadLabel }
                >
                    <FaCloudUploadAlt className={ classes.icon } />
                    <p>Drag & Drop to Upload Image</p>
                </label>
                <input id='image-upload' type='file' accept="image/*" className={classes.hidden} onChange={uploadChangeHandler} />
            </div>
        </>
    );
};

export default ImageInput;