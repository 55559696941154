import classes from './ImageCanvas.module.css';
import { useState, useEffect, useRef } from "react";

const ImageCanvas = (props) => {
    const overlayRef = useRef(null);
    const [isLeftClicking, setIsLeftClicking] = useState(false);
    const [clickStartPoint, setClickStartPoint] = useState(null);
    const [answerRegions, setAnswerRegions] = useState([]);
    const [currentMousePosition, setCurrentMousePosition] = useState(null);
    const [isCtrlKeyDown, setIsCtrlKeyDown] = useState(false);
  
    const handleMouseMove = (event) => {
        if(props.static || props.studentMode) return;

        const overlayRect = overlayRef.current.getBoundingClientRect();
        const xPercent = (((event.clientX - overlayRect.left) / overlayRect.width) * 100).toFixed(4);
        const yPercent = (((event.clientY - overlayRect.top) / overlayRect.height) * 100).toFixed(4);
        setCurrentMousePosition({ x: xPercent, y: yPercent });
    };

    const startImgClickHandler = (event) => {
        if(props.static || props.studentMode) return;
        setIsLeftClicking(true);
        const overlayRect = overlayRef.current.getBoundingClientRect();
    
        const xPercent = (((event.clientX - overlayRect.left) / overlayRect.width) * 100).toFixed(4);;
        const yPercent = (((event.clientY - overlayRect.top) / overlayRect.height) * 100).toFixed(4);;
    
        setClickStartPoint({ x: xPercent, y: yPercent });
    };

    const endImgClickHandler = (event) => {
        if(props.static || props.studentMode) return;
        if(!clickStartPoint) return;

        setIsLeftClicking(false);
        const overlayRect = overlayRef.current.getBoundingClientRect();
    
        const xPercent = (((event.clientX - overlayRect.left) / overlayRect.width) * 100).toFixed(4);;
        const yPercent = (((event.clientY - overlayRect.top) / overlayRect.height) * 100).toFixed(4);;
    
        // console.log(`Drew rectangle from (${clickStartPoint.x}%, ${clickStartPoint.y}%) to (${xPercent}%, ${yPercent}%)`);
        addAnswerRegion(clickStartPoint.x, clickStartPoint.y, xPercent, yPercent);
        setClickStartPoint(null);
    };

    const cancelImgClickHandler = () => {
        setIsLeftClicking(false);
        setClickStartPoint(null);
    }

    const addAnswerRegion = (startX, startY, endX, endY) => {
        props.onError('');
        const updatedAnswerRegions = [...answerRegions];

        let isDuplicate = false;

        // TODO: Add logic to keep the answer regions from overlapping or being too close to one another

        if(Math.abs(startX - endX) < 5 || Math.abs(startY - endY) < 5) {
            props.onError && props.onError('Answer regions must be at least a mimimum height and length.');
            return;
        }

        if(!isDuplicate) {
            updatedAnswerRegions.push({ startX, startY, endX, endY, isCorrect: false });
            setAnswerRegions(updatedAnswerRegions);
            props.onChangeAnswerRegions(updatedAnswerRegions);
        }
    };

    const deleteAnswerRegion = (index) => {
        props.onError('');
        const updatedAnswerRegions = [];

        for(let i = 0; i < answerRegions.length; i ++) {
            if(i !== index) updatedAnswerRegions.push(answerRegions[i]);
        }

        setAnswerRegions(updatedAnswerRegions);
        props.onChangeAnswerRegions(updatedAnswerRegions);
    }

    const toggleCorrectness = (index) => {
        if(!props.studentMode && !props.static) props.onError('');
        if(props.static) return;
        if(isCtrlKeyDown && !props.studentMode) {
            deleteAnswerRegion(index)
            return;
        }

        const updatedAnswerRegions = [...answerRegions];

        updatedAnswerRegions[index].isCorrect = !updatedAnswerRegions[index].isCorrect;
        setAnswerRegions(updatedAnswerRegions);
        props.onChangeAnswerRegions(updatedAnswerRegions);
    }

    useEffect(() => {
        if(props.static) return;
        // Attach keydown and keyup event listeners
        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('keyup', handleKeyUp);

        // Cleanup function to remove event listeners
        return () => {
            if(props.static) return;
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('keyup', handleKeyUp);
        };
    }, [props.static]);

    const [hasLoadedAnswers, setHasLoadedAnswers] = useState(false);
    const [renderingQuestion, setRenderingQuestion] = useState('');
    useEffect(() => {
        if(props.capstoneData?.question?.question_key === renderingQuestion) {
            if(hasLoadedAnswers) return;
            if(answerRegions.length > 1 && !props.static) return;
        } else {
            setRenderingQuestion(props.capstoneData?.question?.question_key);
        }

        // console.log('ImageCanvas received props:', props);
        const updatedAnswerRegions = props.initialAnswers?.map(val => ({...val.rect, isCorrect: val.isCorrect})) || [];
        setAnswerRegions(updatedAnswerRegions);
        props.onChangeAnswerRegions && props.onChangeAnswerRegions(updatedAnswerRegions);
        setHasLoadedAnswers(true);
        // eslint-disable-next-line
    }, [props.initialAnswers]);

    const handleKeyDown = (event) => {
        if (event.ctrlKey) {
            setIsCtrlKeyDown(true);
        }
    };

    const handleKeyUp = (event) => {
        if (!event.ctrlKey) {
            setIsCtrlKeyDown(false);
        }
    };

    return (
        <div className={classes.bigWrapper}>
            <div className={`${classes.wrapper} ${answerRegions.length > 0 && !props.static && !props.studentMode ? classes.hasResetButton : ''} ${props.static ? classes.static : ''} ${props.studentMode ? classes.studentMode : '' }`}>
                <img className={classes.image} src={props.imagesrc} alt="" />
                <div className={`${classes.overlay} ${props.static ? classes.static : ''} ${props.studentMode ? classes.studentMode : ''}`} ref={overlayRef} onMouseDown={startImgClickHandler} onMouseUp={endImgClickHandler} onMouseLeave={() => isLeftClicking && cancelImgClickHandler()} onMouseMove={handleMouseMove}/>
                {
                    answerRegions.map((val, index) => <div key={index} className={`${classes.answerRegion} ${val.isCorrect ? classes.correct : ''} ${props.static ? classes.static : ''} ${props.studentMode ? classes.studentMode : ''}`} style={{top: `${Math.min(val.startY, val.endY)}%`, bottom: `${100-Math.max(val.startY, val.endY)}%`, left: `${Math.min(val.startX, val.endX)}%`, right: `${100-Math.max(val.startX, val.endX)}%`, pointerEvents: isLeftClicking ? 'none' : 'all'}} onClick={toggleCorrectness.bind(null, index)}/>)
                }
                {
                    isLeftClicking &&
                    <div className={classes.answerRegion} style={{top: `${Math.min(clickStartPoint.y, currentMousePosition.y)}%`, bottom: `${100-Math.max(clickStartPoint.y, currentMousePosition.y)}%`, left: `${Math.min(clickStartPoint.x, currentMousePosition.x)}%`, right: `${100-Math.max(clickStartPoint.x, currentMousePosition.x)}%`, userSelect: 'none', pointerEvents: 'none'}} />
                }
            </div>
            {
                answerRegions.length > 0 && !props.static && !props.studentMode && 
                <button className={classes.resetButton} onClick={() => {setAnswerRegions([]); props.onChangeAnswerRegions([]);}}>Reset All Answer Regions</button>
            }
        </div>
    );
};

export default ImageCanvas;