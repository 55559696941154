import SingleColumn from '../components/UI/SingleColumn';
import { useState, useEffect } from 'react';
import { useOutletContext, useNavigate } from 'react-router-dom';
import PageHeaderText from '../components/UI/PageHeaderText';
import TextInput from '../components/UI/TextInput';
import Button from '../components/UI/Button';
import HorizontalLine from '../components/UI/HorizontalLine';
import { database } from '../util/firebase';
import { ref, get, push, child, update } from 'firebase/database';


const JoinPage = () => {
    const [setHeaderSize] = useOutletContext();
    useEffect(() => {setHeaderSize('large');}, [setHeaderSize]);
    const navigate = useNavigate();

    const [joinData, setJoinData] = useState({firstName: '', lastName: '', joinCode: '', errors: {firstName: '', lastName: '', joinCode: ''} });
    const [isJoining, setIsJoining] = useState(false)

    const last_attempt_id = localStorage.getItem('brain_raider_last_attempt_id');

    const joinHandler = async (event) => {
        event.preventDefault();

        let errorCounter = 0;
        if(joinData.firstName === '') {
            setJoinData(state => ({...state, errors: {...state.errors, firstName: 'This field is required.'}}));
            errorCounter++
        }
        if(joinData.lastName === '') {
            setJoinData(state => ({...state, errors: {...state.errors, lastName: 'This field is required.'}}));
            errorCounter++
        }
        if(joinData.joinCode === '') {
            setJoinData(state => ({...state, errors: {...state.errors, joinCode: 'This field is required.'}}));
            errorCounter++
        }
        const joinCodeValid = await isValidJoinCode(joinData.joinCode);
        if(!joinCodeValid && joinData.joinCode !== '') {
            setJoinData(state => ({...state, errors: {...state.errors, joinCode: 'Invalid join code.'}}));
            errorCounter++
        }
        if(errorCounter !== 0) {
            return;
        }

        setIsJoining(true);

        // TODO: Backend needs to run any of these updates that the user doesn't have permission to run (like the commented-out ones)
        const updates = {}
        const newAttemptKey = push(child(ref(database), 'attempts')).key;
        localStorage.setItem('brain_raider_last_attempt_id', newAttemptKey);
        updates[`attempts/${newAttemptKey}/user`] = { first_name: joinData.firstName, last_name: joinData.lastName };
        /* if(assigningAssessment.personal_id) updates[`attempts/${newAttemptKey}/assessment`] = { name: assigningAssessment.name, assessment_id: assigningAssessment._id, personal_id: assigningAssessment.personal_id };
        else updates[`attempts/${newAttemptKey}/assessment`] = { name: assigningAssessment.name, assessment_id: assigningAssessment._id }; */
        updates[`attempts/${newAttemptKey}/monitoring_group_id`] = joinData.joinCode;
        updates[`attempts/${newAttemptKey}/status`] = 'queued';
        updates[`attempts/${newAttemptKey}/is_anonymous`] = true;
        /* updates[`attempts/${newAttemptKey}/teacher_id`] = authUserData.token; */
        /* updates[`users/${key}/unfinished_attempts/${newAttemptKey}`] = { name: assigningAssessment.name, description: assigningAssessment.description, assessment_id: assigningAssessment._id, status: 'queued' }; */
        updates[`monitoring_groups/${newAttemptKey}/attempts/${newAttemptKey}`] = { first_name: joinData.firstName, last_name: joinData.lastName, status: 'queued' };
    
        try {
            await update(ref(database), updates);
            navigate(`/attempts/${newAttemptKey}`);
        } catch (error) {
            console.error("Error updating data:", error);
        }

    };

    const isValidJoinCode = async (code) => {
        if(code === '') return false;

        const routeRef = ref(database, `monitoring_groups/${code}`);
        try {
            const snapshot = await get(routeRef);
            if(snapshot.exists()) {
                console.log(snapshot.val())
                if(snapshot.val() === null) return false;
                if(snapshot.val()?.is_open === false) return false;
                else return true;
            } else {
                console.log('no snap')
                return false;
            }
        } catch (error) {
            console.error("Error checking data existence:", error);
            return false;
        }
    };

    const rejoinHandler = () => {
        navigate(`/attempts/${last_attempt_id}`)
    };

    return (
        <SingleColumn>
            <PageHeaderText>Join a Quiz</PageHeaderText>
            <form onSubmit= { joinHandler }>
                <TextInput name='user-first-name' value={ joinData.firstName } placeholder='First Name' leftIcon='person' error={ joinData.errors.firstName } onChange={(value) => {setJoinData(state => ({...state, firstName: value, errors: {...state.errors, firstName: ''}}))}} half left />
                <TextInput name='user-last-name' value={ joinData.lastName } placeholder='Last Name' error={ joinData.errors.lastName } onChange={(value) => {setJoinData(state => ({...state, lastName: value, errors: {...state.errors, lastName: ''}}))}} half right />
                <TextInput name='join-code' value={ joinData.joinCode } placeholder='Join Code' error={ joinData.errors.joinCode } onChange={(value) => {setJoinData(state => ({...state, joinCode: value, errors: {...state.errors, joinCode: ''}}))}} />
                <input type="submit" style={{display: 'none'}} />
                <Button blue onClick={ joinHandler } disabled={ isJoining }>{ isJoining ? 'Joining Quiz...' : 'Join Quiz' }</Button>
            </form>
            { last_attempt_id && 
                <>
                    <HorizontalLine>or</HorizontalLine>
                    <Button altblue onClick={ rejoinHandler }>Rejoin Last Quiz</Button>
                </>
            }
        </SingleColumn>
    );
};

export default JoinPage;