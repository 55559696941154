import classes from './HideBelow.module.css';

const HideBelow = (props) => {
    return(
        <div className={classes.hide}>
            {props.children}
        </div>
    );
};

export default HideBelow;