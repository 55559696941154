import classes from './TextAreaInput.module.css';

const TextAreaInput = (props) => {
    const textChangeHandler = (event) => {
        props.onChange(event.target.value);
    };

    const inputClasses = `${ classes.input }
        ${ props.nospaceafter ? classes.noSpaceAfter : '' }
        ${ props.nospacebefore ? classes.noSpaceBefore : '' }
        ${ props.error ? classes.hasError : '' }`;

    return (
        <div className={ `${ classes.inputWrapper} ${ props.half ? classes.half : '' } ${ props.right ? classes.right : '' }` }>
            <textarea
                className={ inputClasses }
                id={ props.name }
                value={ props.value }
                onChange={ textChangeHandler }
                placeholder={ props.placeholder }
                disabled={ props.disabled }
            />
            { props.error && <div className={ `${classes.errorText} ${ props.nospaceafter ? classes.noSpaceAfter : '' }` }>{props.error}</div> }
        </div>
    )
};

export default TextAreaInput;
