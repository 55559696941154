import { Fragment } from 'react';
import classes from './StudentResultDetail.module.css';
import StudentResultQuestion from './StudentResultQuestion';
import { FaInfo } from "react-icons/fa";
import HorizontalLine from '../UI/HorizontalLine';

const StudentResultDetail = (props) => {
    return(
        <div className={`${classes.stimuliWrapper} ${props.last ? classes.last : ''}`}>
            { props.data?.image &&
                <div className={ classes.imageWrapper }>
                    <img src={ props.data.image } alt='' className={ classes.stimuliImage }/>
                </div>
            }
            <div className={ classes.promptWrapper }>{props.index + 1}.&nbsp;{props.data?.text}</div>
            {
                props.data?.questions &&
                props.data.questions.map((questionData, index) => 
                    <Fragment key={index}>
                        <HorizontalLine/>
                        <StudentResultQuestion
                            key={questionData.question_key}
                            questionData={{ ...questionData, key: questionData.question_key, stimuliKey: props.data.stimuli_key,
                            image: props.data.image, stimulus_text: props.data.text }}
                            expanded={true}
                            claimExpanded={() => {}}
                            selectedAnswers={questionData.selected_answers}
                            showCorrect={true}
                        />
                        {
                            !questionData.selected_answers?.was_correct &&
                                <div className={classes.feedbackWrapper}>
                                    <div className={classes.infoIcon}><FaInfo /></div>
                                    <b>Feedback:</b> {questionData.incorrect_message}
                                </div>
                        }
                        <br />
                    </Fragment>
                )
            }
        </div>
    )
};

export default StudentResultDetail;