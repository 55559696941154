import { useState, useEffect } from 'react';
import classes from './TapTheWordInput.module.css';

const TapTheWordInput = (props) => {
    const [selectedChar, setSelectedChar] = useState(null);
    const [answerRanges, setAnswerRanges] = useState([]);
    const [isCtrlKeyDown, setIsCtrlKeyDown] = useState(false);

    const [hasLoadedAnswers, setHasLoadedAnswers] = useState(false);
    useEffect(() => {
        if(hasLoadedAnswers) return;
        if(answerRanges.length > 1 && !props.static) return;

        // console.log('TapTheWordInput received props:', props);
        const updatedAnswerRanges = props.initialAnswers?.map(val => ({...val.region, isCorrect: val.isCorrect})) || [];
        setAnswerRanges(updatedAnswerRanges);
        props.onChangeAnswerRanges && props.onChangeAnswerRanges(updatedAnswerRanges);
        setHasLoadedAnswers(true);
        // eslint-disable-next-line
    }, [props.initialAnswers]);

    const charClickHandler = (index) => {
        if(props.static) return;

        // check if the character is part of an existing answer range
        for(let i = 0; i < answerRanges.length; i++) {
            if(index >= answerRanges[i].startChar && index <= answerRanges[i].endChar) {
                // if character is part of an answer range (and ctrl is not held down), toggle correctness
                if(!isCtrlKeyDown || props.studentMode) toggleCorrectness(i);
                // if character is part of an answer range (and ctrl is held down), remove the answer range
                else deleteAnswerRange(i);
                return;
            };
        };

        // students can't do anything beyong this point
        if(props.studentMode) return;

        // if no character selected, select the current character
        if(selectedChar === null) {
            setSelectedChar(index)
            return;
        };

        // if the same character was clicked, cancel selection
        if(index === selectedChar) {
            setSelectedChar(null);
            return;
        }

        // if a character is selected, add the selected range as an answer choice
        const start = Math.min(index, selectedChar);
        const end = Math.max(index, selectedChar);
        addAnswerRange(start, end);
        setSelectedChar(null);
    };

    useEffect(() => {
        if(props.static) return;
        // Attach keydown and keyup event listeners
        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('keyup', handleKeyUp);

        // Cleanup function to remove event listeners
        return () => {
            if(props.static) return;
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('keyup', handleKeyUp);
        };
    }, [props.static]);

    const handleKeyDown = (event) => {
        if (event.ctrlKey) {
            setIsCtrlKeyDown(true);
        }
    };

    const handleKeyUp = (event) => {
        if (!event.ctrlKey) {
            setIsCtrlKeyDown(false);
        }
    };

    const addAnswerRange = (startChar, endChar) => {
        props.onError('');
        const updatedAnswerRanges = [...answerRanges];

        let isDuplicate = false;

        // check each index to make sure it isn't inside any existing ranges
        for(let i = startChar; i < endChar + 1; i++) {
            for(let j = 0; j < answerRanges.length; j++) {
                if(i >= answerRanges[j].startChar && i <= answerRanges[j].endChar) {
                    isDuplicate = true;
                    props.onError('Answer ranges may not overlap.')
                }
            };
        };

        if(!isDuplicate) {
            const text = props.stimuliText.substring(startChar, endChar + 1);
            updatedAnswerRanges.push({ startChar, endChar, isCorrect: false, text });
            setAnswerRanges(updatedAnswerRanges);
            props.onChangeAnswerRanges(updatedAnswerRanges);
        }
    };

    const toggleCorrectness = (index) => {
        if(!props.studentMode && !props.static) props.onError('');
        /* if(props.static) return; */

        const updatedAnswerRanges = [...answerRanges];

        updatedAnswerRanges[index].isCorrect = !updatedAnswerRanges[index].isCorrect;
        setAnswerRanges(updatedAnswerRanges);
        props.onChangeAnswerRanges(updatedAnswerRanges);
    };

    const deleteAnswerRange = (index) => {
        props.onError('');
        const updatedAnswerRanges = [];

        for(let i = 0; i < answerRanges.length; i ++) {
            if(i !== index) updatedAnswerRanges.push(answerRanges[i]);
        }

        setAnswerRanges(updatedAnswerRanges);
        props.onChangeAnswerRanges(updatedAnswerRanges);
    }

    let mappedPrompt = props.stimuliText?.split('').map(char => ({ char, color: 'none' }))

    if(mappedPrompt && mappedPrompt.length > 0) {
        if(answerRanges.length > 0) {
            for(let i = 0; i < answerRanges.length; i++) {
                for(let j = answerRanges[i].startChar; j < answerRanges[i].endChar + 1; j++) {
                    if(!props.studentMode) {
                        if(answerRanges[i].isCorrect) mappedPrompt[j].color = 'green';
                        else mappedPrompt[j].color = 'red';
                    } else {
                        if(answerRanges[i].isCorrect) mappedPrompt[j].color = 'yellow';
                        else mappedPrompt[j].color = 'gray';
                    }
                };
            };
        };
    };

    return(
        <div className={`${classes.wrapper} ${props.hasError ? classes.noSpace : ''} ${props.static ? classes.noBorder : ''} ${props.studentMode ? classes.studentMode : ''}`}>
            {
                mappedPrompt && mappedPrompt.map((obj, index) => <span key={index} onClick={charClickHandler.bind(null, index)} className={`${classes.chars} ${obj.color === 'green' ? classes.green : ''} ${obj.color === 'red' ? classes.red : ''} ${obj.color === 'gray' ? classes.gray : ''} ${obj.color === 'yellow' ? classes.yellow : ''} ${index === selectedChar ? classes.selected : ''} ${props.static ? classes.static : ''} ${props.studentMode ? classes.studentMode : ''}`}>{obj.char}</span>)
            }
        </div>
    );
};

export default TapTheWordInput;