import Button from './Button';
import classes from './PriceCard.module.css';
import { MdOutlineCheckBox,MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import yellowBrain from '../../images/yellow_brain.svg';
import groupOfBrains from '../../images/group_of_brains.svg';

const PriceCard = (props) => {
    const pictureSrc = () => {
        switch(props.planName) {
            case 'Individuals': return yellowBrain;
            case 'Organizations': return groupOfBrains;
            default: return null;
        }
    };

    return(
        <div className={classes.card}>
            <div className={classes.name}>For {props.planName}</div>
            <div className={ props.planName === 'Individuals' ? classes.logo : classes.logoBig }>
                {
                    pictureSrc() !== null && <img src={pictureSrc()} alt={`${props.planName}s`} />
                }
            </div>
            <div className={classes.price}>{props.planPrice}</div>
            <div className={classes.subtext}>{props.planSubtext}</div>
            {
                props.features &&
                props.features.map((feature, index) => <div key={index} className={`${ classes.feature } ${feature.offered ? classes.offered : classes.excluded}`}>
                    <div className={`${classes.icon} ${feature.offered ? '' : classes.excluded }`}>{feature.offered ? <MdOutlineCheckBox /> : <MdOutlineCheckBoxOutlineBlank />}</div>
                    <div className={classes.description}>{feature.description}</div>
                </div>)
            }
            { props.buttonColor === "green" ? <Button confirm disabled={props.buttonDisabled} onClick={props.buttonFunction}>{props.buttonText}</Button> : ( props.buttonColor === "purple" ? <Button disabled={props.buttonDisabled} onClick={props.buttonFunction}>{props.buttonText}</Button> : <Button altblue disabled={props.buttonDisabled} onClick={props.buttonFunction}>{props.buttonText}</Button>)}
            <div className={classes.offer}>
                {
                    props.afterButtonText &&
                    <><b>{props.afterButtonTextLabel}</b> {props.afterButtonText}</>
                }
            </div>
        </div>
    )
};

export default PriceCard;