import classes from './ReviewImageCanvas.module.css';
import { useState, useEffect, useRef } from "react";

const ReviewImageCanvas = (props) => {
    const overlayRef = useRef(null);
    const [answerRegions, setAnswerRegions] = useState([]);

    const [hasLoadedAnswers, setHasLoadedAnswers] = useState(false);
    const [renderingQuestion, setRenderingQuestion] = useState('');
    useEffect(() => {
        if(props.capstoneData?.question?.question_key === renderingQuestion) {
            if(hasLoadedAnswers) return;
            if(answerRegions.length > 1 && !props.static) return;
        } else {
            setRenderingQuestion(props.capstoneData?.question?.question_key);
        }

        // console.log('ImageCanvas received props:', props);
        const updatedAnswerRegions = props.initialAnswers?.map(val => ({...val.rect, isCorrect: val.isCorrect, isSelected: val.isSelected})) || [];
        setAnswerRegions(updatedAnswerRegions);
        props.onChangeAnswerRegions && props.onChangeAnswerRegions(updatedAnswerRegions);
        setHasLoadedAnswers(true);
        // eslint-disable-next-line
    }, [props.initialAnswers]);


    return (
        <div className={classes.bigWrapper}>
            <div className={`${classes.wrapper} ${answerRegions.length > 0 && !props.static && !props.studentMode ? classes.hasResetButton : ''} ${props.static ? classes.static : ''} ${props.studentMode ? classes.studentMode : '' }`}>
                <img className={classes.image} src={props.imagesrc} alt="" />
                <div className={`${classes.overlay} ${props.static ? classes.static : ''} ${props.studentMode ? classes.studentMode : ''}`} ref={overlayRef}/>
                {
                    answerRegions.filter(region => region.isCorrect || region.isSelected).map((val, index) =>
                        <div
                            key={index}
                            className={`${classes.answerRegion}
                                ${(val.isCorrect && val.isSelected) ? classes.correct : ''}
                                ${(val.isCorrect && !val.isSelected) ? classes.shouldBeCorrect : ''}
                            `}
                            style={{
                                top: `${Math.min(val.startY, val.endY)}%`,
                                bottom: `${100-Math.max(val.startY, val.endY)}%`,
                                left: `${Math.min(val.startX, val.endX)}%`,
                                right: `${100-Math.max(val.startX, val.endX)}%`
                            }}
                        />)
                }
            </div>
        </div>
    );
};

export default ReviewImageCanvas;