import classes from './QuestionAndAnswers.module.css';
import { MdCheckBoxOutlineBlank, MdCheckBox, MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md';
import { HiOutlineTrophy } from 'react-icons/hi2';
import TextInput from '../UI/TextInput';
import TextAreaInput from '../UI/TextAreaInput';
import ImageCanvas from '../UI/ImageCanvas';
import { DndContext } from '@dnd-kit/core';
import DraggableCard from '../UI/DraggableCard';
import DragDropStudentCanvas from '../UI/DragDropStudentCanvas';
import TapTheWordInput from '../UI/TapTheWordInput';

const QuestionAndAnswers = (props) => {
    const handleDragEnd = (event) => {
        const {active, over} = event;
    
        if(over) props.onMatchAnswer(active.id, over.id)
        else props.onMatchAnswer(active.id, null);
    }

    return(
        <>
        { props.capstoneData !== null && 
            <DndContext onDragEnd={handleDragEnd}>
                <div className={ classes.stimuliWrapper }>
                    <div className={ classes.imageWrapper }>
                        {
                            props.capstoneData.question.type !== 'hot_spot' && props.capstoneData.question.type !== 'drag_and_drop' &&
                            <img src={ props.capstoneData.image } alt='' className={ classes.stimuliImage }/>
                        }
                        {
                            props.capstoneData.question.type === 'hot_spot' &&
                                <>
                                    <ImageCanvas imagesrc={props.capstoneData.image} initialAnswers={Object.keys(props.capstoneData.question.answers)?.map(key => ({ rect: props.capstoneData.question.answers[key], isCorrect: false}))} studentMode onChangeAnswerRegions={props.onSelectRegion} onError={null} capstoneData={props.capstoneData}/>
                                </>
                        }
                        {
                            props.capstoneData.question.type === 'drag_and_drop' &&
                            <>
                                <DragDropStudentCanvas imagesrc={props.capstoneData.image} initialAnchors={props.capstoneData.question.anchors} studentMode anchorAnswerPairs={props.capstoneData.question.anchor_answer_pairs} capstoneData={props.capstoneData}/>
                            </>
                        }
                    </div>
                    {   props.capstoneData.question.type !== 'tap_the_word' &&
                        <div className={classes.promptWrapper} >{/* {props.numbered && props.index + 1 + '. ' */}{props.capstoneData.text}</div>
                    }
                    {
                        props.capstoneData.question.type === 'tap_the_word' &&
                        <TapTheWordInput key={props.capstoneData.question.question_key} stimuliText={props.capstoneData.text} initialAnswers={Object.keys(props.capstoneData.question.answers)?.map(key => ({ region: props.capstoneData.question.answers[key], isCorrect: false}))} studentMode onChangeAnswerRanges={props.onSelectRange} onError={null}/>
                    }
                    { props.capstoneData.question &&
                        <div className={ `${props.capstoneData.question.is_capstone ? classes.capstoneWrapper : ''} ${(props.capstoneData.question.type === 'hot_spot' || props.capstoneData.question.type === 'tap_the_word' ? classes.noChoices : '')}`}>
                            {   !props.capstoneData?.question?.is_capstone && 
                                <div className={ `${classes.questionTextWrapper} ${ (props.capstoneData.question.type === 'short_constructed_response' || props.capstoneData.question.type === 'text_entry' ) ? classes.spaceAfter : ''}` }>{props.capstoneData.question.prompt}</div>
                            }
                            {   props.capstoneData?.question?.is_capstone &&
                                <div className={ `${classes.questionAndIconTextWrapper} ${ (props.capstoneData.question.type === 'short_constructed_response' || props.capstoneData.question.type === 'text_entry' ) ? classes.spaceAfter : ''}` }>
                                    <div className={classes.iconSpace}>
                                        <HiOutlineTrophy />
                                    </div>
                                    <div className={classes.questionText}>
                                        {props.capstoneData.question.prompt}
                                    </div>
                                </div>
                            }
                            {
                                (props.capstoneData.question.type === 'multiple_choice' || props.capstoneData.question.type === 'multi_select') &&
                                    Object.keys(props.capstoneData.question.answers).map((key, index) =>
                                        <div className={ `${classes.answerRow} ${props.capstoneData.selected_answer === key ? classes.selected : ''} ${index === Object.keys(props.capstoneData.question.answers).length - 1 ? classes.last : ''} ${props.capstoneData.question.is_capstone ? classes.invertedColors : ''}`} key={key} onClick={props.onSelect.bind(null, key)}>
                                            <div className={classes.iconSpace}>
                                            { props.capstoneData.question.type === 'multi_select' ? props.capstoneData.selected_answers.includes(key) ? <MdCheckBox /> : <MdCheckBoxOutlineBlank  /> : ''}
                                            { props.capstoneData.question.type === 'multiple_choice' ? props.capstoneData.selected_answers.includes(key) ? <MdRadioButtonChecked /> : <MdRadioButtonUnchecked /> : ''}
                                            </div>
                                            {props.capstoneData.question.answers[key]}
                                        </div>
                                    )
                            }
                            {
                                props.capstoneData.question.type === 'text_entry' &&
                                    <>
                                        <TextInput onChange={props.onEditTextEntry} />
                                    </>
                            }
                            {
                                props.capstoneData.question.type === 'short_constructed_response' && 
                                <>
                                    <TextAreaInput onChange={props.onEditTextEntry} />
                                </>
                            }
                            {
                                props.capstoneData.question.type === 'drag_and_drop' &&
                                <div className={classes.cardWrapper}>
                                    {
                                        Object.keys(props.capstoneData.question.answers).filter(key => !Object.values(props.capstoneData.question.anchor_answer_pairs).includes(key)).map(key => <DraggableCard id={key} key={key}>{props.capstoneData.question.answers[key]}</DraggableCard>)
                                    }
                                </div>
                            }
                        </div>
                    }
                </div>
            </DndContext>
        }
        </>
    );  
};

export default QuestionAndAnswers;