import SingleColumn from '../components/UI/SingleColumn';
import { useEffect } from 'react';
import { useOutletContext, useNavigate } from 'react-router-dom';
import TextBlock from '../components/UI/TextBlock';
import PageHeaderText from '../components/UI/PageHeaderText';
import HomepageBottomBar from '../components/Cards/HomepageBottomBar';

const PrivacyPage = () => {
    const [setHeaderSize] = useOutletContext();
    useEffect(() => {setHeaderSize('small');}, [setHeaderSize]);

    const navigate = useNavigate();

  return (
    <SingleColumn>
        <PageHeaderText>Brain Raider, LLC Privacy Policy</PageHeaderText>
        <TextBlock>
            Effective date: July 1, 2023<br /><br />
            This privacy policy (the “Privacy Policy”) governs the collection, use, and sharing of personal data by Brain Raider, LLC ("us", "we", or "our") through the Brain Raider website (the "Service").
            <br /><br /><strong>Introduction</strong><br /><br />
            We've developed this Privacy Policy to outline how Brain Raider, LLC collects, uses, and shares information gathered through the Brain Raider website. This Privacy Policy applies to all users, including educators, students, and institutions. By using our Service, you agree to the terms outlined in this Privacy Policy.
            <br /><br /><strong>Definitions</strong><br /><br />
            For the purpose of this Privacy Policy:
            <ul>
            <li>"Educator" refers to a user who is a teacher or administrator and is not a student.</li>
            <li>"Institution" refers to a school, district, or educational agency using the Brain Raider Materials.</li>
            <li>"Student" refers to a user who is accessing the Brain Raider Materials for educational purposes.</li>
            <li>"Personal Information" refers to any information that can be used to identify an individual.</li>
            </ul>
            <strong>What Information Do We Collect?</strong><br /><br />
            The information we collect depends on your role within the Brain Raider Materials:
            <br /><br />a. Website Visitors: When you visit the Brain Raider website, we automatically collect non-personally identifiable information such as IP address and device identifiers. We may also gather data on your usage patterns and interactions with our website.
            <br /><br />b. Account Holders: If you create an account with us, we may collect personal information such as your name, email address, institution, and password to provide our Service.
            <br /><br />c. Students: For students accessing Brain Raider, we collect limited information as permitted by the institution, which may include first name, last name, and email address. We also collect student-generated assessment data and during their interactions with the platform.
            <br /><br /><strong>Basis for Processing Information</strong><br /><br />
            We collect, process, and handle personal information with the user's consent or as required by law. Educators and institutions are responsible for obtaining any additional consents required for student data processing.
            <br /><br /><strong>Compliance with Privacy Laws</strong><br /><br />
            Brain Raider, LLC complies with relevant privacy laws, including but not limited to FERPA and COPPA. For more information, see our <span onClick={() => navigate('/childrens-privacy-policy')} style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}>Children’s Privacy Policy</span>.
            <br /><br /><strong>Does Brain Raider Share Information with Third Parties?</strong><br /><br />
            We do not sell personal information to advertisers or other third parties. We do not disclose personal information to any third parties except in specific circumstances outlined in this Privacy Policy:
            <ul>
            <li>Exporting and Third-Party Integrations: Within the Brain Raider service, teachers may choose to export assessment results and student data to various file formats or directly to other systems like gradebook software or a learning management system ("LMS"). This sharing occurs solely at the direction of the teacher within the Brain Raider website.</li>
            <li>Service Providers: We collaborate with vendors, service providers, and partners to assist us in delivering the Service by completing tasks as instructed. While these providers may require access to user account information to fulfill certain functions such as sending emails on our behalf, managing databases, hosting servers, and ensuring security, they do not have generalized access to user managed data. We ensure that these providers adhere to confidentiality and security obligations through contractual agreements consistent with this Privacy Policy, covering aspects such as security, data retention, and privacy. Please see the <span onClick={() => navigate('/third-party-vendors')} style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}>Third Party Vendors list</span> for more details.</li>
            <li>Legal Requirements: We may disclose personal information in response to a duly authorized subpoena from a court of law or equity or when compelled by a government agency. Additionally, we may disclose personal information to protect the rights, property, or safety of Brain Raider, our employees, or others, or to prevent violations of our Terms of Service or other agreements.</li>
            </ul>
            <strong>How Do We Protect Your Information?</strong><br /><br />
            Brain Raider accounts are safeguarded by a unique username (a valid email address) and a password of your creation. You play a role in securing your account against unauthorized access by selecting a distinct password and maintaining its confidentiality at all times.
            <br /><br />Ensuring the security of your personal and student information is very important to us. Some steps we take to safeguard against unauthorized use, disclosure, or access to personal data include:
            <ul>
            <li>Prioritizing security in engineering decisions.</li>
            <li>Encrypting all User Managed Data during transmission and while at rest on Brain Raider servers.</li>
            <li>Employing secure storage and transmission mechanisms for passwords. Passwords are never stored in plain text but rather are salted and hashed using encryption libraries.</li>
            </ul>
            While we try to maintain the security of our information systems, we cannot guarantee absolute security against various potential threats such as outages, attacks, human error, system failures, or unauthorized usage. 
            How Can You Access, Change, or Delete Personally Identifiable Information?<br /><br />
            Users can request access to, correction of, or deletion of their personal information by contacting us at <a href="mailto:support@brainraider.com">support@brainraider.com</a>. However, some functionalities may be affected by such changes, and deletion of data may result in account termination.
            <br /><br /><strong>Communication</strong><br /><br />
            If you're a Brain Raider user, we may send you updates regarding your account. These updates could include privacy and security notices, service updates, or information about new features or offerings from Brain Raider. Your email address will also be used for customer service matters or any legal issues that may arise during our business interactions.
            <br /><br />If you do not wish to receive these communications, just send us an email at <a href="mailto:support@brainraider.com">support@brainraider.com</a>. Keep in mind, though, that even if you opt out of receiving legal notices, like this Privacy Policy, they'll still apply to your use of Brain Raider. So, it's important to stay up to date with any changes by reviewing these notices regularly.
            <br /><br /><strong>How to Contact Us</strong><br /><br />
            If you have any questions or concerns about this Privacy Policy, you can contact us at <a href="mailto:support@brainraider.com">support@brainraider.com</a>.
            <br /><br />For previous versions of this Privacy Policy, please contact us directly.
        </TextBlock>
        <HomepageBottomBar />
    </SingleColumn>
  );
}

export default PrivacyPage;