import classes from './RadioInput.module.css';
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md';

const RadioInput = (props) => {
    return (
        <div className={ `${classes.inputRowWrapper} ${ props.last ? classes.last : '' } ${ props.disabled ? classes.disabled : '' }` } onClick={ props.disabled ? null : props.onChange.bind(null, props.value) }>
            <label className={ classes.label } htmlFor={`correct ${props.value}`}>
                { props.checked ? <MdRadioButtonChecked /> : <MdRadioButtonUnchecked />}
                <div className={ classes.labelText }>
                    { props.children }
                </div>
            </label>
            <input id={`correct ${props.value}`} type='radio' className={ classes.radioInput } value={ props.value } checked={ props.checked } onChange={ props.disabled ? null : props.onChange.bind(null, props.value) } readOnly={props.disabled} />
        </div>
    )
}

export default RadioInput;