import classes from './RevealBelow.module.css';

const RevealBelow = (props) => {
    return(
        <div className={classes.hide}>
            {props.children}
        </div>
    );
};

export default RevealBelow;