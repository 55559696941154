import classes from './ScrollableCard.module.css';

const ScrollableCard = (props) => {
    return (
        <div className={ `${ classes.popupWrapper } ${ props.show ? classes.show : '' }` }>
            {props.children}
        </div>
    );
};

export default ScrollableCard;