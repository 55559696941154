import classes from './Assessment.module.css';
import { FaUserFriends } from "react-icons/fa";
import { FaUser } from "react-icons/fa";

const Assessment = (props) => {
    let standards = '';

    let matchingStandards = [];
    if(props.assessment.standards && props.standardsToShow) {
        Object.keys(props.standardsToShow).forEach(state => {
            Object.keys(props.standardsToShow[state]).forEach(standard_group => {
                if(props.assessment.standards[state]) {
                    if(props.assessment.standards[state]) {
                        if(props.assessment.standards[state][standard_group]) {
                            Object.keys(props.assessment.standards[state][standard_group]).forEach(code => {
                                matchingStandards.push(code);
                            })
                        };
                    };
                };
            })
        })
    };

    if (matchingStandards.length > 0) {
        let firstParts = matchingStandards.map(standard => standard.split(' ').slice(0, 1).join(' '));
        let lastParts = matchingStandards.map(standard => standard.split(' ').slice(-1).join(''));
        standards = firstParts[0] + ' ' + lastParts.join(', ');
    }

    if(props.assessment.name === 'my assessment') console.log(props.assessment)

    return (
        <div className={`${classes.wrapper} ${ !props.assessment.published ? classes.faded : '' } ${ props.last ? classes.last : '' }` } onClick={ props.onClick.bind(null, props.assessment)}>
            <div className={`${classes.header} ${props.assessment.author === 'You' ? classes.own : ((typeof props.assessment.author === 'string' && props.assessment.author.includes(' ')) ? classes.friend : '')}`}>
                {props.assessment.name}
            </div>
            <div className={`${classes.questions} ${props.assessment.author === 'You' ? classes.own : ((typeof props.assessment.author === 'string' && props.assessment.author.includes(' ')) ? classes.friend : '')}`}>
                {props.assessment.capstone_num} Stimul{props.assessment.capstone_num === 1 ? 'us' : 'i'} &<br />{props.assessment.question_num ?? '??'} Question{props.assessment.question_num === 1 ? '' : 's'}
            </div>
            <div className={classes.description}>
                {props.assessment.description}
            </div>
            <div className={classes.bottomRow}>
                <div className={classes.bottomLeft}>
                    {props.assessment.author === 'You' ?
                        <span style={{fontSize: '14px'}}><FaUser /></span> :
                        <FaUserFriends />
                    }
                    &nbsp;<span className={classes.owner}>{props.assessment.author === 'You' ? 'You' : ((typeof props.assessment.author === 'string' && props.assessment.author.includes(' ')) ? props.assessment.author : 'Brain Raider')}</span>
                </div>
                <div className={classes.bottomRight}>{ standards }</div>
            </div>
        </div>
    );
};

export default Assessment;