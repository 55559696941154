import SingleColumn from '../components/UI/SingleColumn';
import { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../util/firebase';
import PageHeaderText from '../components/UI/PageHeaderText';
import TextInput from '../components/UI/TextInput';
import Button from '../components/UI/Button';

const LoginPage = () => {
    const [setHeaderSize, authUserData] = useOutletContext();
    useEffect(() => {setHeaderSize('large');}, [setHeaderSize]);

    const [email, setEmail] = useState(authUserData?.email || '');
    const [emailError, setEmailError] = useState('');
    const [isSendingEmail, setIsSendingEmail] = useState(false);
    const [passwordResetSent, setPasswordResetSent] = useState(false);

    const resetPasswordHandler = (event) => {
        event.preventDefault();

        if(email === '') {
            setEmailError('This field is required.');
            return;
        };

        setIsSendingEmail(true);

        sendPasswordResetEmail(auth, email)
        .then(() => {
            setPasswordResetSent(true);
            setEmail('');
            setEmailError('');
            setIsSendingEmail(false);
        }).catch((error) => {
            setIsSendingEmail(false);
            if(error.message === 'Firebase: Error (auth/user-not-found).') {setEmailError('User not found.');};
            if(error.message === 'Firebase: Error (auth/invalid-email).') {setEmailError('Invalid email address.');};
        });
    };

    return (
        <SingleColumn>
            <div>
                <PageHeaderText>Password Reset</PageHeaderText>
                <form onSubmit= { resetPasswordHandler }>
                    <TextInput name='user-email' value={ email } placeholder='Email' leftIcon='mail' error={ emailError } onChange={ (value) => {setEmail(value); setEmailError('')} } />
                    <Button onClick={ resetPasswordHandler } disabled={ passwordResetSent || isSendingEmail }>
                        { passwordResetSent && 'Email Sent!' }
                        { !passwordResetSent && isSendingEmail && 'Sending...' }
                        { !passwordResetSent && !isSendingEmail && 'Send Password Reset Email' }
                    </Button>
                </form>
            </div>
        </SingleColumn>
    );
};

export default LoginPage;