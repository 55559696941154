import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import groupArt from '../../images/group-art-small.svg';
import actionsIcon from '../../images/actions-icon.svg';
import upIcon from '../../images/up-icon.svg';
import downIcon from '../../images/down-icon.svg';
import { BsPeopleFill } from "react-icons/bs";
import { FaClipboardList } from "react-icons/fa6";
import classes from './Group.module.css';
import InlineButton from '../UI/InlineButton';

const Group = (props) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);
    
    if (props.showReorder && isExpanded) {
        setIsExpanded(false);
    }

    const navigate = useNavigate();
    const navigateToDetailsHandler = () => {
        navigate(`/groups/${props.group._id}`);
    };

    const moveUpClickHandler = () => {
        props.onMoveUp(props.group._id);
        setIsAnimating(true);
        setTimeout(() => setIsAnimating(false), 200);
    };

    const moveDownClickHandler = () => {
        props.onMoveDown(props.group._id);
        setIsAnimating(true);
        setTimeout(() => setIsAnimating(false), 200);
    };

    return (
        <div className={classes.groupObjectWrapper}>
            { props.showReorder && 
                <div className={ classes.reorderWrapper }>
                    { !props.first && <img src={ upIcon } alt='Move Up' className={ classes.upIcon } onClick={ moveUpClickHandler }/>}
                    { !props.last && <img src={ downIcon } alt='Move Down' className={ classes.downIcon } onClick={ moveDownClickHandler }/>}
                </div>
            }
            <div className={ `${ classes.groupWrapper } ${ isExpanded ? classes.expanded : '' } ${ props.showReorder ? classes.narrower : ''} ${ isAnimating ? classes.shake : '' } ${props.last ? classes.last : ''}` }>
                <img src={ groupArt } alt='' className={ classes.groupArt } />
                <div className={ classes.clickableRegion} onClick={ navigateToDetailsHandler }/>
                <div className={ classes.groupName }>{ props.group.name }{props.isDemo ? '*' : ''}</div>
                <div className={ classes.groupCount }><span className={classes.gray}><BsPeopleFill /></span>&nbsp;{ props.group.student_count } Student{props.group.student_count !== 1 && 's'}</div>
                <div className={ classes.assessmentCount }><span className={classes.gray}><FaClipboardList /></span>&nbsp;{props.group.active_assessments} Active Assessments</div>
                { !props.showReorder && <img src={ actionsIcon } alt='More...' className={ classes.actionsIcon } onClick={() => {setIsExpanded(value => !value) }}/>}
                <div className={ classes.actionsWrapper }>
                    <InlineButton icon='editAlt' onFocus={ setIsExpanded } onClick={ props.onEdit.bind(null, props.group._id, props.group.name, props.group.style) } light disabled={props.isDemo}>Edit...</InlineButton>
                    <InlineButton icon='merge' onFocus={ setIsExpanded } onClick={ props.onMerge.bind(null, props.group._id, props.group.name)} disabled={props.group.student_count < 1 || props.disableMerge } light>Merge to...</InlineButton>
                    <InlineButton icon='archiveAlt' onFocus={ setIsExpanded } onClick={ props.onArchive.bind(null, props.group._id) } light disabled={props.disableArchive }>{props.isDemo ? 'Delete' : 'Archive'}</InlineButton>
                </div>
            </div>
        </div>
    );
};

export default Group;