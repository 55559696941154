import { MdOutlineDelete, MdOutlineEdit } from 'react-icons/md';
import { BiSort, BiSortAZ, BiSortZA } from 'react-icons/bi';
import classes from './FeedbackTable.module.css';
import EmptyBlock from '../UI/EmptyBlock';

const FeedbackTable = (props) => {
    const toTitleCase = (string) => {
        let splitString = string.toLowerCase().split(' ');
        for(let i = 0; i < splitString.length; i++) {
            splitString[i] = splitString[i].charAt().toUpperCase() + splitString[i].substring(1);
        }

        return splitString.join(' ');
    };

    return(
        <>
        <table className={ classes.contentTable }>
                <thead>
                <tr className={ classes.headers }>
                    <th className={ classes.conceptHeader }>
                        <div className={ classes.aligner}>
                            <div className={ classes.text }>Concept</div>
                            <div className={ classes.icon } onClick={ props.onSort }>
                                { props.sorted === 0 && <BiSort /> }
                                { props.sorted === 1 && <BiSortZA /> }
                                { props.sorted === -1 && <BiSortAZ /> }
                            </div>
                        </div>
                    </th>
                    <th className={ classes.skillsHeader }>Skills</th>
                    <th className={ classes.misconceptionsHeader }>Misconceptions</th>
                    <th className={ classes.actionsHeader }>Actions</th>
                </tr>
                </thead>
                { props.feedback?.filter(feedback => !feedback.archived).length > 0 &&
                    <tbody>
                    {
                        props.feedback.filter(feedback => !feedback.archived).map(
                            (feedback, index) =>
                                <tr key={index}>
                                    <td className={ `${index === props.feedback.length - 1 ? classes.last : ''} ${ classes.rightBorder }` }><div className={ classes.cell }>{toTitleCase(feedback.concept)}</div></td>
                                    <td className={ `${index === props.feedback.length - 1 ? classes.last : ''} ${ classes.rightBorder }` }>
                                        <div className={ `${classes.cell} ${classes.forDesktop}` }>{feedback.skills.join(', ')}</div>
                                        <div className={ `${classes.cell} ${classes.forMobile}` }>{feedback.skills?.length || 0}</div>
                                    </td>
                                    <td className={ `${index === props.feedback.length - 1 ? classes.last : ''} ${ classes.rightBorder }` }>
                                        <div className={ `${classes.cell} ${classes.forDesktop}` }>{feedback.misconceptions?.join(', ')}</div>
                                        <div className={ `${classes.cell} ${classes.forMobile}` }>{feedback.misconceptions?.length || 0}</div>
                                    </td>
                                    <td className={ `${index === props.feedback.length - 1 ? classes.last : ''} ${ classes.actions }` }>
                                        <button
                                            className={ classes.edit }
                                            onClick={ props.onEdit.bind(null, feedback) }
                                        >
                                            <MdOutlineEdit />
                                        </button>
                                        <button
                                            className={ classes.delete }
                                            onClick={ props.onDelete.bind(null, feedback._id) }
                                        >
                                            <MdOutlineDelete />
                                        </button>
                                    </td>
                                </tr>
                        )
                    }
                </tbody>
            }
            </table>
            { props.feedback.length === 0 && <EmptyBlock>No matching feedback found.</EmptyBlock>}
        </>
    );
};

export default FeedbackTable;