import lineArt from '../../images/line-art.svg';
import classes from './HorizontalLine.module.css';

const HorizontalLine = (props) => {
    return (
        <div className={ classes.lineWrapper }>
            <img src={ lineArt } className={ classes.lineArt } alt='' />
            { props.children && <div className={ classes.text }>{ props.children }</div> }
        </div>
    )
};

export default HorizontalLine;