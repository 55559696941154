import classes from './ProgressBar.module.css'

const ProgressBar = (props) => {
    return (
        <div className={ classes.progressOutline }>
            <div className={ classes.progressBarWrapper}>
                <div className={ classes.progressBar } style={{width: `${Math.min(props.percent, 100)}%`}}>&nbsp;</div>
            </div>
            <div className={ classes.progressText }>
                {`${Math.min(props.percent, 100)}%`}
            </div>
        </div>
    );
};

export default ProgressBar;