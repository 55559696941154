import { MdOutlineClose } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import classes from './PulloutDrawer.module.css';

const PulloutDrawer = (props) => {
    const nav = useNavigate();
    const location = useLocation();

    window.onkeydown = (key) => {
        if(key.keyCode === 27 && props.show) {
            props.onCancel();
        } else if(key.keyCode === 27 && !props.show && location.pathname.startsWith('/groups/')) {
            nav('/groups');
        } 
    };

    return (
        <>
            <div className={ `${classes.drawerWrapper} ${props.show ? classes.show : ''}` }>
                <div className={ classes.header }>
                    <div className={ classes.text }>{ props.header }</div>
                    <div className={ classes.icon } onClick={ props.onCancel }><MdOutlineClose /></div>
                </div>
                <div className={ classes.content }>{ props.children }</div>
            </div>
            <div className={ `${classes.screenOverlay} ${props.show ? classes.show : ''}` } onClick={ props.onCancel }></div>
        </>
    )
};

export default PulloutDrawer;