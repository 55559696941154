import classes from './DragDropZoneSelector.module.css';

const DragDropZoneSelector = (props) => {
    
    return (
        <div className={classes.wrapper}>
            <div className={classes.label}>
                Matching Anchor(s):
            </div>
            <div className={classes.anchors}>
                {Object.keys(props.anchors)?.length === 0 && <span className={classes.gray}>None</span>}
                {Object.keys(props.anchors)?.sort((a, b) => props.anchors[a].display_num - props.anchors[b].display_num).map(key =>
                <div key={key} className={`${classes.anchor} ${Object.keys(props.selections)?.length > 0 && props.selections[key] === true ? classes.selected : ''}`} onClick={props.onChoiceAnchorToggle.bind(null, key, props.answerIndex)}>{props.anchors[key].display_num}</div>)
                }
            </div>
        </div>
    );
}

export default DragDropZoneSelector;