import { useState } from 'react';
import playIcon from '../../images/play-icon.svg';
import pauseIcon from '../../images/pause-icon.svg';
import queuedIcon from '../../images/queued-icon.svg';
import visibilityIcon from '../../images/visibility-icon.svg';
import visibilityIconAlt from '../../images/visibility-icon-alt.svg';
import actionsIconAlt from '../../images/actions-icon-alt.svg';
import InlineButton from '../UI/InlineButton';
import classes from './StudentMonitor.module.css';

const Student = (props) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <div className={ `${classes.studentWrapper} ${props.last ? classes.last : '' } ${ isExpanded ? classes.expanded : '' }` }>
            <div className={ classes.studentName }>
                <span className={ classes.studentName }>{ props.attempt.last_name }, { props.attempt.first_name }</span>
            </div>
            { (props.attempt.status === 'queued' || props.attempt.status === 'ready') && <img src={ queuedIcon } alt='Queued' className={ classes.queuedIcon } /> }
            { (props.attempt.status === 'available' || props.attempt.status === 'in_progress') && <img src={ playIcon } alt='In Progress' className={ classes.playIcon } /> }
            { props.attempt.status === 'paused' && <img src={ pauseIcon } alt='Paused' className={ classes.pauseIcon } /> }
            <div className={classes.percentage}>{props.attempt.percent_complete || 0}%</div>
            { props.attempt.is_focus ? <img src={ visibilityIcon } alt='Viewing' className={ classes.visibilityIcon } /> : <img src={ visibilityIconAlt } alt='Not Viewing' className={ classes.visibilityIcon } />}
            <img src={ actionsIconAlt } alt='More...' className={ classes.actionsIcon } onClick={() => {setIsExpanded(value => !value) }} />
            <div className={ classes.actionsWrapper }>
                <InlineButton icon='play' onClick={() => {props.onStart(props.attempt.user_id, props.attempt.key, props.monitoringGroup, props.attempt.status); setIsExpanded(false); }}>Start</InlineButton>
                <InlineButton icon='pause' onClick={() => {props.onPause(props.attempt.user_id, props.attempt.key, props.monitoringGroup, props.attempt.status); setIsExpanded(false); }}>Pause</InlineButton>
                <InlineButton icon='end' onClick={() => {props.onEnd(props.attempt.user_id, props.attempt.key, props.monitoringGroup, props.assessmentName, props.attempt.status); setIsExpanded(false); }}>End & Score</InlineButton>
            </div>
        </div>
    );
};

export default Student;