import { useState, useEffect } from 'react';
import classes from './ReviewTapTheWordInput.module.css';

const ReviewTapTheWordInput = (props) => {
    const [answerRanges, setAnswerRanges] = useState([]);

    const [hasLoadedAnswers, setHasLoadedAnswers] = useState(false);
    useEffect(() => {
        if(!props.initialAnswers) return;
        if(hasLoadedAnswers) return;
        if(answerRanges.length > 1) return;

        // console.log('TapTheWordInput received props:', props);
        const updatedAnswerRanges = props.initialAnswers?.map(val => ({...val.region, isCorrect: val.isCorrect, isSelected: val.isSelected})) || [];
        setAnswerRanges(updatedAnswerRanges);
        props.onChangeAnswerRanges && props.onChangeAnswerRanges(updatedAnswerRanges);
        setHasLoadedAnswers(true);
        // eslint-disable-next-line
    }, [props.initialAnswers]);

    let mappedPrompt = props.stimuliText?.split('').map(char => ({ char, color: 'none' }))

    if(mappedPrompt && mappedPrompt.length > 0) {
        if(answerRanges.length > 0) {
            for(let i = 0; i < answerRanges.length; i++) {
                for(let j = answerRanges[i].startChar; j < answerRanges[i].endChar + 1; j++) {
                    if(answerRanges[i].isCorrect && answerRanges[i].isSelected) mappedPrompt[j].color = 'green';
                    else if (answerRanges[i].isCorrect && !answerRanges[i].isSelected) mappedPrompt[j].color = 'yellow';
                    else if (!answerRanges[i].isCorrect && answerRanges[i].isSelected) mappedPrompt[j].color = 'red';
                };
            };
        };
    };

    return(
        <div className={`${classes.wrapper} ${props.hasError ? classes.noSpace : ''} ${props.static ? classes.noBorder : ''} ${props.studentMode ? classes.studentMode : ''}`}>
            {
                mappedPrompt && mappedPrompt.map((obj, index) =>
                    <span
                        key={index}
                        className={
                            `${classes.chars} ${obj.color === 'green' ? classes.green : ''}
                            ${obj.color === 'red' ? classes.red : ''}
                            ${obj.color === 'gray' ? classes.gray : ''}
                            ${obj.color === 'yellow' ? classes.yellow : ''}
                            ${props.static ? classes.static : ''}
                            ${props.studentMode ? classes.studentMode : ''}`
                        }>
                            {obj.char}
                    </span>
                )
            }
        </div>
    );
};

export default ReviewTapTheWordInput;