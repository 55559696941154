import classes from './ImportValueGroup.module.css';

const ImportValueGroup = (props) => {
    return (
        <>
            <div
                className={`${classes.groupWrapper} ${props.big ? classes.big : ''} ${props.none ? classes.none : ''} ${props.disabled ? classes.disabled : ''} ${props.isSelected ? classes.selected : ''}`}
                onClick={() => {props.onSelect((props.isSelected &&  props.selection !== 'email_part') ? '' : props.index, props.selection)}} >
                <div className={classes.dataWrapper}>
                    {props.values &&
                        props.values.map((value, index) =>  index < 5 ? 
                                <div key={index} className={classes.dataRow}>
                                    {value}
                                </div> : 
                                ''
                            
                        )
                    }
                </div>
                {
                    props.none &&
                    'None of these'
                }
            </div>
        </>
    )
};

export default ImportValueGroup;