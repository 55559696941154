import { useEffect, useState } from 'react';
import { Outlet, ScrollRestoration, useLocation, /* useNavigate */ } from 'react-router-dom';
import { auth, database } from '../util/firebase';
import { ref, onValue, /* get, child, */ update } from 'firebase/database';
import { onAuthStateChanged, getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import MainNavigation from '../components/UI/MainNavigation';
import HeaderBar from '../components/UI/HeaderBar';

const RootLayout = () => {
    const [headerSize, setHeaderSize] = useState('small');
    const [authUserData, setAuthUserData] = useState(null);
    const [authUserToken, setAuthUserToken] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        let userListener;
    
        if (authUserToken) {
            const userGroupsRef = ref(database, `users/${authUserToken}`);
            onValue(userGroupsRef, (snapshot) => {
                const auth = getAuth();
                const user = auth.currentUser;
                const userData = snapshot.val();
    
                if (userData && userData.is_impersonating) {
                    const impersonatingUserRef = ref(database, `users/${userData.is_impersonating}`);
                    if (userListener) {
                        userListener(); // Clean up the previous listener
                    }
                    userListener = onValue(impersonatingUserRef, (impSnapshot) => {
                        setAuthUserData({
                            token: userData.is_impersonating,
                            ...impSnapshot.val(),
                            is_impersonating: true,
                            isEmailVerified: true
                        });
                    });
                } else {
                    setAuthUserData({
                        token: authUserToken,
                        ...userData,
                        isEmailVerified: user?.emailVerified
                    });
                }
            });
        } else {
            // console.log('No authUserToken');
            setAuthUserData(null);
        }
    
        return () => {
            if (userListener) {
                userListener(); // Clean up listener on component unmount
            }
        };
    }, [authUserToken, navigate, location]);

    /* useEffect(() => {
        if (authUserToken) {

            const userGroupsRef = ref(database, `users/${authUserToken}`);
            onValue(userGroupsRef, (snapshot) => {
                const auth = getAuth();
                const user = auth.currentUser;
                setAuthUserData({token: authUserToken, ...snapshot.val(), isEmailVerified: user?.emailVerified})
            });

            get(child(ref(database), `users/${authUserToken}`))
                .then((snap) => {
                    console.log('got snap')
                    if (snap.exists() && snap.val()?.is_impersonating) { 
                        // console.log(`Impersonating ${snap.val()?.is_impersonating}.`)
                        const impersonatingUserRef = ref(database, `users/${snap.val()?.is_impersonating}`)
                        onValue(impersonatingUserRef, (imp_snap) => {
                            console.log({token: snap.val()?.is_impersonating, ...imp_snap.val(), is_impersonating: true, isEmailVerified: true})
                            setAuthUserData({token: snap.val()?.is_impersonating, ...imp_snap.val(), is_impersonating: true, isEmailVerified: true})
                        })
                    } else {
                        const userGroupsRef = ref(database, `users/${authUserToken}`);
                        onValue(userGroupsRef, (snapshot) => {
                            const auth = getAuth();
                            const user = auth.currentUser;
                            console.log({token: authUserToken, ...snapshot.val(), isEmailVerified: user?.emailVerified})
                            setAuthUserData({token: authUserToken, ...snapshot.val(), isEmailVerified: user?.emailVerified})
                        });
                    }
            })


        } else {
            console.log('was no authusertoken')
            setAuthUserData(null);
        };
    }, [authUserToken, navigate, location]) */

    useEffect(() => {
        /* const allowedUnauthPages = ['/', '/login', '/password-reset', '/terms-of-service', '/privacy-policy', '/childrens-privacy-policy', '/third-party-vendors', '', '/classlink', '/signup' ];
        const isAllowedUnauthPage = allowedUnauthPages.includes(location.pathname); */

        /* if (authUserData === null && !isAllowedUnauthPage) {
            navigate('/');
        } else  */
        if (authUserData?.roles && location.pathname === '/classlink') {
            if ('student' in authUserData.roles) {
                navigate('/dashboard');
            } else {
                navigate('/groups');
            }
        }
    }, [authUserData, navigate, location]);

    useEffect(() => {
        const authListener = onAuthStateChanged(auth, (user) => {
          if (user) {
            setAuthUserToken(user.uid);
          } else {
            setAuthUserToken(null);
          }
        });
      
        // Immediately fetch the current authentication state once on mount
        const initialUser = getAuth().currentUser;
        if (initialUser) {
          setAuthUserToken(initialUser.uid);
        } else {
          setAuthUserToken(null);
        }
      
        return () => authListener(); // Clean up the listener on unmount
      }, []);

    let studentMode = false;
    if(authUserData) {
        if(!authUserData.roles || 'student' in authUserData.roles) {
            studentMode = true;
        };
    };

    const stopImpersonatingHandler = () => {
        if(!authUserToken) return;
        
        const updates = {};
        updates[`users/${authUserToken}/is_impersonating`] = {};
        update(ref(database), updates);
        navigate('/users');
    }

    return (
        <>
            <ScrollRestoration />
            { authUserToken && !studentMode &&
                <MainNavigation userData={authUserData} onStopImpersonating={stopImpersonatingHandler} /> 
            }
            <HeaderBar size={ headerSize } showLogin={!authUserToken} location={location.pathname} studentMode={studentMode} studentName={`${authUserData?.first_name} ${authUserData?.last_name}`}/>
            <main>
                <Outlet context={ [setHeaderSize, authUserData] }/>
            </main>
        </>
    );
}

export default RootLayout;
