import SingleColumn from '../components/UI/SingleColumn';
import PageHeaderText from '../components/UI/PageHeaderText';
import Student from '../components/Cards/Student';
import Button from '../components/UI/Button';
import EmptyBlock from '../components/UI/EmptyBlock';
import ButtonGroup from '../components/UI/ButtonGroup';
import PulloutDrawer from '../components/UI/PulloutDrawer';
import TextInput from '../components/UI/TextInput';
import DropdownInput from '../components/UI/DropdownInput';
import CSVUploadInput from '../components/UI/CSVUploadInput';
import { useState, useEffect } from 'react';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';
import { auth, secondaryAuth, database } from '../util/firebase';
import { ref, onValue, update, increment, push, child } from 'firebase/database';
import { createUserWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { ref as storageRef, uploadBytes } from 'firebase/storage';
import { storage } from '../util/firebase';
import { v4 } from 'uuid';
import TextBlock from '../components/UI/TextBlock';
import { getAnalytics, logEvent } from "firebase/analytics";

const GroupDetailPage = () => {
    const navigate = useNavigate();
    const [setHeaderSize, authUserData] = useOutletContext();
    useEffect(() => {setHeaderSize('small');}, [setHeaderSize]);

    const [showDrawer, setShowDrawer] = useState(false);
    const [editingStudent, setEditingStudent] = useState({ _id: '', first_name: '', last_name: '', email: '', teacher_ids: [], auto_group: null, initial_password: '', sis_id: '' })
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [editingStudentErrors, setEditingStudentErrors] = useState({ first_name: '', last_name: '', email: '', sis_id: '' })

    const [hasLoaded, setHasLoaded] = useState(false);
    const [groupData, setGroupData] = useState(null);
    const [isUploadingCsvLocal, setIsUploadingCsvLocal] = useState(false);
    const { id: group_id } = useParams()
    useEffect(() => {
        const groupRef = ref(database, `groups/${group_id}`);
        const unsubscribe = onValue(groupRef, (snapshot) => {
            setGroupData(snapshot.val());
            // console.log(snapshot.val())
            if(!snapshot.val().is_uploading_csv) {
                setIsUploadingCsvLocal(false);
            }});
            
        setHasLoaded(true);

        return () => unsubscribe();
    }, [group_id]);

    const backButtonClickHandler = () => {
        navigate('/groups')
    };

    var alpha_students = []
    if (groupData?.student_users) {
        Object.keys(groupData.student_users).forEach((key, index) => {
            alpha_students.push({_id: key, ...groupData.student_users[key]})
        });
        alpha_students = alpha_students.sort((a, b) => a.last_name.toLowerCase().localeCompare(b.last_name.toLowerCase()));
    }

    const editingStudentFirstNameChangeHandler = (value) => {
        setEditingStudent(studentData => ({...studentData, first_name: value}))
        setEditingStudentErrors((errors) => ({...errors, first_name: ''}));
    };

    const editingStudentLastNameChangeHandler = (value) => {
        setEditingStudent(studentData => ({...studentData, last_name: value}))
        setEditingStudentErrors((errors) => ({...errors, last_name: ''}));
    };

    const editingStudentEmailChangeHandler = (value) => {
        setEditingStudent(studentData => ({...studentData, email: value}))
        setEditingStudentErrors((errors) => ({...errors, email: ''}));
    };

    const editingStudentIdChangeHandler = (value) => {
        setEditingStudent(studentData => ({...studentData, sis_id: value}))
        setEditingStudentErrors((errors) => ({...errors, sis_id: ''}));
    };

    const editingStudentPasswordChangeHandler = (value) => {
        setEditingStudent(studentData => ({...studentData, initial_password: value}))
        setEditingStudentErrors((errors) => ({...errors, initial_password: ''}));
    }

    const addStudentClickHandler = () => {
        setShowDrawer(true);
        setEditingStudent({ _id: '', first_name: '', last_name: '', email: '', teacher_ids: [], auto_group: '', initial_password: '', sis_id: '' });
        setEditingStudentErrors({ first_name: '', last_name: '', email: '', initial_password: '', sis_id: '' });
    };

    const editStudentClickHandler = (id, first_name, last_name, email, sis_id) => {
        setShowDrawer(true);
        setEditingStudent({ _id: id, first_name: first_name, last_name: last_name, email: email, teacher_ids: [], auto_group: '', initial_password: '', sis_id: sis_id });
        setEditingStudentErrors({ first_name: '', last_name: '', email: '', sis_id: '' });
    }

    const submitFormHandler = async (event) => {
        event.preventDefault();

        var errorCounter = 0;
        if(editingStudent.first_name === '') {
            errorCounter++;
            setEditingStudentErrors((errors) => ({...errors, first_name: 'First name cannot be blank.'}));
        };
        if(editingStudent.last_name === '') {
            errorCounter++;
            setEditingStudentErrors((errors) => ({...errors, last_name: 'Last name cannot be blank.'}));
        };
        if(editingStudent.email === '') {
            errorCounter++;
            setEditingStudentErrors((errors) => ({...errors, email: 'Email cannot be blank.'}));
        };
        if(editingStudent._id === '' && editingStudent.initial_password.length < 7) {
            errorCounter++;
            setEditingStudentErrors((errors) => ({...errors, initial_password: 'Password must be at least 7 characters.'}));
        }
        console.log(editingStudentErrors)
        if(errorCounter > 0) {return;}

        setIsSubmitting(true);

        // TODO: If the email is already in use, make this instead find the account and associate the user.
        if(editingStudent._id === '') {
            createUserWithEmailAndPassword(secondaryAuth, editingStudent.email, editingStudent.initial_password)
            .then((userCredential) => {
                const userData = { first_name: editingStudent.first_name, last_name: editingStudent.last_name, email: editingStudent.email, teacher_ids: {}, auto_group: false, roles: { student: true } };
                if(editingStudent.sis_id !== '') userData.sis_id = editingStudent.sis_id;
                userData.teacher_ids[authUserData.token] = true;
                
                const updates = {};
                updates[`users/${userCredential.user.uid}`] = userData;
                updates[`groups/${group_id}/student_count`] = increment(1);
                updates[`groups/${group_id}/student_users/${userCredential.user.uid}`] = { first_name: userData.first_name, last_name: userData.last_name, auto_group_id: '', auto_group_name: '', sis_id: userData.sis_id };
                updates[`users/${authUserData.token}/groups/${group_id}/student_count`] = increment(1);
                updates[`users/${authUserData.token}/student_users/${userCredential.user.uid}`] = { first_name: userData.first_name, last_name: userData.last_name };
                updates[`user_accounts/${authUserData.token}/has_added_individual_student`] = true;

                try {
                    const analytics = getAnalytics();
                    logEvent(analytics, 'has_added_individual_student');
                } catch (error) {
                    console.error('Error logging event:', error);
                }

                update(ref(database), updates)

                sendPasswordResetEmail(auth, userData.email);

                setIsSubmitting(false);
                setShowDrawer(false);
                setEditingStudent({ _id: '', first_name: '', last_name: '', email: '', teacher_ids: [], auto_group: '', initial_password: '', sis_id: '' });
            }).catch((error) => {
                if(error.message === 'Firebase: Error (auth/invalid-email).') {setEditingStudentErrors((errors) => ({...errors, email: 'Invalid email address.'}));};
                if(error.message === 'Firebase: Error (auth/email-already-in-use).') {setEditingStudentErrors((errors) => ({...errors, email: 'Email address already in use.'}));};
                setIsSubmitting(false);
            });
        } else {
            const updates = {};
            updates[`users/${editingStudent._id}/first_name`] = editingStudent.first_name;
            updates[`users/${editingStudent._id}/last_name`] = editingStudent.last_name;
            updates[`users/${editingStudent._id}/email`] = editingStudent.email;
            if(editingStudent.sis_id !== '') updates[`users/${editingStudent._id}/sis_id`] = editingStudent.sis_id;
            else updates[`users/${editingStudent._id}/sis_id`] = {};
            updates[`groups/${group_id}/student_users/${editingStudent._id}/first_name`] = editingStudent.first_name;
            updates[`groups/${group_id}/student_users/${editingStudent._id}/last_name`] = editingStudent.last_name;
            updates[`groups/${group_id}/student_users/${editingStudent._id}/email`] = editingStudent.email;
            if(editingStudent.sis_id !== '') updates[`groups/${group_id}/student_users/${editingStudent._id}/sis_id`] = editingStudent.sis_id;
            else updates[`groups/${group_id}/student_users/${editingStudent._id}/sis_id`] = {};
            updates[`users/${authUserData.token}/student_users/${editingStudent._id}/first_name`] = editingStudent.first_name;
            updates[`users/${authUserData.token}/student_users/${editingStudent._id}/last_name`] = editingStudent.last_name;
            if(editingStudent.sis_id !== '') updates[`users/${authUserData.token}/student_users/${editingStudent._id}/sis_id`] = editingStudent.sis_id;
            else updates[`users/${authUserData.token}/student_users/${editingStudent._id}/sis_id`] = {};
            update(ref(database), updates);

            setIsSubmitting(false);
            setShowDrawer(false);
            setEditingStudent({ _id: '', first_name: '', last_name: '', email: '', teacher_ids: [], auto_group: '', initial_password: '', sis_id: '' });
        }

    };

    // TODO: Make this archive things instead of deleting them?
    const archiveStudentClickHandler = async (user_id) => {
        if(window.confirm('Are you sure?')) {
            const updates = {};
            updates[`groups/${group_id}/student_count`] = increment(-1);
            updates[`groups/${group_id}/student_users/${user_id}`] = {};
            updates[`users/${authUserData.token}/groups/${group_id}/student_count`] = increment(-1);
            updates[`users/${authUserData.token}/student_users/${user_id}`] = {};
            update(ref(database), updates);
        };
    };

    const [movingStudent, setMovingStudent] = useState({ _id: '', first_name: '', last_name: '', auto_group_id: '', auto_group_name: '' });
    const [destinationGroup, setDestinationGroup] = useState({ _id: '', name: '' })
    const [showSecondaryDrawer, setShowSecondaryDrawer] = useState(false);

    const destinationGroups = []
    if (authUserData?.groups) {
        Object.keys(authUserData.groups).filter(key => key !== group_id).filter(key => !authUserData.groups[key].archived).forEach((key) => {
            destinationGroups.push({ value: key, text: authUserData.groups[key].name, index: authUserData.groups[key].index });
            destinationGroups.sort((a, b) => a.index - b.index);
        });
    };

    const moveStudentClickHandler = async (id, first_name, last_name, auto_group_id, auto_group_name, sis_id) => {
        setShowSecondaryDrawer(true);
        setMovingStudent({ _id: id, first_name: first_name, last_name: last_name, auto_group_id: auto_group_id, auto_group_name: auto_group_name, sis_id: sis_id});
        setDestinationGroup(destinationGroups[0].value);
    };

    const destinationGroupChangeHandler = (value) => {
        setDestinationGroup(value);
    };

    const submitSecondaryFormHandler = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        const updates = {};
        updates[`groups/${group_id}/student_count`] = increment(-1);
        updates[`groups/${group_id}/student_users/${movingStudent._id}`] = [];
        updates[`groups/${destinationGroup}/student_count`] = increment(1);
        updates[`groups/${destinationGroup}/student_users/${movingStudent._id}`] = { first_name: movingStudent.first_name, last_name: movingStudent.last_name, auto_group_id: '', auto_group_name: '', sis_id: movingStudent.sis_id || {} };
        updates[`users/${authUserData.token}/groups/${group_id}/student_count`] = increment(-1);
        updates[`users/${authUserData.token}/groups/${destinationGroup}/student_count`] = increment(1);
        update(ref(database), updates);

        setIsSubmitting(false);
        setShowSecondaryDrawer(false);
        setMovingStudent({ _id: '', first_name: '', last_name: '', auto_group_id: '', auto_group_name: ''});

    };

    const [showTertiaryDrawer, setShowTertiaryDrawer] = useState(false);

    const addMultipleStudentsClickHandler = () => {
        navigate('/quick-import');
        // TODO: Remove the CSV upload method of adding students?
        // setShowTertiaryDrawer(true);
    };

    const [csvFileInfo, setCsvFileInfo] = useState(null);
    const uploadChangeHandler = (fileInfo) => {
        setCsvFileInfo(fileInfo);
    };

    const submitCsvFormHandler = async (event) => {
        event.preventDefault();

        // Check that a valid CSV was entered and that every user in the CSV has at least a first_name, last_name, and email
        // The server will also do this but it would be best to display the error message before letting the teacher send the file
        // TODO: Write this code.
    
        setIsSubmitting(true);

        // Upload the associated image and get the URL where it landed
        let gsPath = '';
        if(csvFileInfo) {
            const csvName = `csvs/${csvFileInfo.name.replace(' ','') + v4()}`
            const csvRef = storageRef(storage, csvName);
        
            await uploadBytes(csvRef, csvFileInfo);
            gsPath = csvName;
        };

        // Store the CSV file's URL in the database to activate a Function that creates the accounts
        const newCsvKey = push(child(ref(database), `users/${authUserData.token}/csv_uploads`)).key;

        const updates = {};
        updates[`users/${authUserData.token}/csv_uploads/${newCsvKey}`] = { url: gsPath, group_id: group_id };
        updates[`user_accounts/${authUserData.token}/has_uploaded_csv`] = true;

        try {
            const analytics = getAnalytics();
            logEvent(analytics, 'has_uploaded_csv');
        } catch (error) {
            console.error('Error logging event:', error);
        }

        update(ref(database), updates)

        setIsUploadingCsvLocal(true);

        // Reset the form
        setIsSubmitting(false);
        setCsvFileInfo(null);
        setShowTertiaryDrawer(false);
    };

    const isClasslinkRostered = () => {
        if(authUserData?.identity_link === "classlink_rostered" || authUserData?.identify_link === "classlink_rostered") {
            return true;
        };
        return false;
    }

    const getBlankCsv = async () => {
        const headers = ['first_name', 'last_name', 'email', 'password'];

        // Convert array of objects to CSV string
        const csvString = headers.join(',')

        // Create a Blob object from the CSV string
        const blob = new Blob([csvString], { type: 'text/csv' });

        // Create a URL for the Blob object
        const url = URL.createObjectURL(blob);

        // Create an anchor element
        const a = document.createElement('a');
        a.href = url;
        a.download = `upload_template.csv`; // Set the filename for download
        a.click();

        // Release the URL object
        URL.revokeObjectURL(url);
    };

    return (
        <SingleColumn>
            <div>
                <PageHeaderText><MdArrowBack onClick={backButtonClickHandler} style={{cursor: 'pointer'}}/>&nbsp;{ groupData?.name }</PageHeaderText>
                { hasLoaded && groupData && !groupData.is_uploading_csv && !isUploadingCsvLocal &&
                    <>
                        {alpha_students.length > 0 && alpha_students?.map((student, index) => <Student student={student} last={index === alpha_students.length - 1} key={student._id} onEdit={editStudentClickHandler} onArchive={archiveStudentClickHandler} onMove={moveStudentClickHandler} moveDisabled={destinationGroups.length === 0} canMove={authUserData?.groups && Object.keys(authUserData?.groups)?.length > 1} disableChages={isClasslinkRostered() || groupData.is_demo_group}/>)}
                        { hasLoaded && alpha_students.length === 0 && <EmptyBlock>No students found.</EmptyBlock>}
                    </>
                }
                {
                    hasLoaded && groupData && (groupData.is_uploading_csv || isUploadingCsvLocal) && 
                    <EmptyBlock>Uploading students {groupData.csv_upload_percent ? `(${groupData.csv_upload_percent}%) ` : ''}- please check back in a few moments!</EmptyBlock>
                }
                { hasLoaded && groupData && !isClasslinkRostered() && !groupData.is_demo_group &&
                    <ButtonGroup>
                        <Button onClick={ addStudentClickHandler } fitted confirm disabled={groupData.is_uploading_csv} >Add Student</Button>
                        <Button onClick={ addMultipleStudentsClickHandler } fitted confirm disabled={groupData.is_uploading_csv} >Add Multiple Students</Button>
                    </ButtonGroup>
                }
                { groupData && groupData.is_demo_group &&
                    <TextBlock bigspace gray><br />A demo group functions just like a regular class except the students are robots ready to automatically take any assessment at any time. Demo groups help you see how Brain Raider's live monitoring and data analysis features work without having to administer an assessment to live students.</TextBlock>
                }
                <PulloutDrawer show={showDrawer} header={editingStudent?._id ? 'Edit Student' : 'Add New Student'} onCancel={() => {setShowDrawer(false); setEditingStudent({ _id: '', first_name: '', last_name: '', email: '', teacher_ids: [], auto_group: '', initial_password: '', sis_id: '' });}}>
                    <form>
                        <PageHeaderText small labelFor='student-first-name'>First Name</PageHeaderText>
                        <TextInput name='student-first-name' value={ editingStudent?.first_name } onChange={ editingStudentFirstNameChangeHandler } error={ editingStudentErrors.first_name } />
                        <PageHeaderText small labelFor='student-last-name'>Last Name</PageHeaderText>
                        <TextInput name='student-last-name' value={ editingStudent?.last_name } onChange={ editingStudentLastNameChangeHandler } error={ editingStudentErrors.last_name } />
                        <PageHeaderText small labelFor='student-email'>Email</PageHeaderText>
                        <TextInput name='student-email' value={ editingStudent?.email } onChange={ editingStudentEmailChangeHandler } error={ editingStudentErrors.email } />
                        <PageHeaderText small labelFor='student-id'>Student ID (Optional)</PageHeaderText>
                        <TextInput name='student-id' value={ editingStudent?.sis_id } onChange={ editingStudentIdChangeHandler } error={ editingStudentErrors.id } />
                        { editingStudent._id === '' &&
                            <>
                                <PageHeaderText small labelFor='student-initial-password'>Initial Password</PageHeaderText>
                                <TextInput name='student-initial-password' value={ editingStudent.initial_password } onChange={ editingStudentPasswordChangeHandler } error={ editingStudentErrors.initial_password } />
                            </>
                        }
                        <br />
                        <Button onClick={ submitFormHandler } disabled={ isSubmitting }>{isSubmitting ? 'Submitting...' : 'Submit'}</Button>
                    </form>
                </PulloutDrawer>
                <PulloutDrawer show={showSecondaryDrawer} header={'Move to Group'} onCancel={() => {setShowSecondaryDrawer(false); setMovingStudent({ _id: '', first_name: '', last_name: '', auto_group_id: '', auto_group_name: '' });}}>
                    <form>
                        <PageHeaderText small labelFor='move-to'>Move {movingStudent?.first_name} {movingStudent?.last_name} to...</PageHeaderText>
                        <DropdownInput name='move-to' value={ destinationGroup } onChange={ destinationGroupChangeHandler } options={ destinationGroups } />
                        <Button onClick={ submitSecondaryFormHandler } disabled={ isSubmitting }>{isSubmitting ? 'Submitting...' : 'Submit'}</Button>
                    </form>
                </PulloutDrawer>
                <PulloutDrawer show={showTertiaryDrawer} header={'Add Multiple Students'} onCancel={() => {setShowTertiaryDrawer(false);}}>
                    <form>
                        <PageHeaderText small>Instructions</PageHeaderText>
                        <TextBlock>Create multiple student accounts at once by uploading a .CSV file. The "password" column is optional; if passwords are not included, each student will have to click the 'Forgot Password' link on the Login page to manually set his or her password. <strong style={{color: '#f2903a'}}>IMPORTANT: Your columns must be titled "first_name", "last_name", "email" and "password". Download a blank template <span style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer'}} onClick={getBlankCsv}>here</span>.</strong></TextBlock>
                        <p>&nbsp;</p>
                        <PageHeaderText small>Upload a CSV File</PageHeaderText>
                        { showTertiaryDrawer && <CSVUploadInput onUpload={uploadChangeHandler} /> }
                        <Button onClick={ submitCsvFormHandler } disabled={ isSubmitting || !csvFileInfo }>{isSubmitting ? 'Submitting...' : 'Submit'}</Button>
                        <TextBlock>Stuck? Email us at <a href="mailto:support@brainraider.com">support@brainraider.com</a> and we can answer any questions or schedule a complimentary 15-minute Zoom call to walk you through the .CSV upload process.</TextBlock>
                    </form>
                </PulloutDrawer>
            </div>
        </SingleColumn>
    );
}

export default GroupDetailPage;