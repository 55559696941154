import classes from './SingleColumn.module.css';

const SingleColumn = (props) => {
    return (
        <div className={ classes.centered }>
            <div className={ `${classes.columnWrapper} ${ props.wide ? classes.wide : '' }` }>
                { props.children }
            </div>
        </div>
    );
};

export default SingleColumn;