import classes from './Video.module.css';

const Video = (props) => {
    return (
        <div className={classes.container}>
            <div className={classes.wrapper}>
                <iframe
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/dXxg5zH4E7g?si=SOIaNBTUfoUBmxwa"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                ></iframe>
            </div>
        </div>
    );
}

export default Video;