import classes from './SkillsPerformance.module.css';

const SkillsPerformance = (props) => {
    const calculateBackgroundColor = (percent_correct) => {
        // Desired color: #5C60F5
        const targetColor = [92, 96, 245]; // RGB values
    
        // Calculate transparency based on percent_correct
        const maxTransparency = 1; // 100% transparency (fully opaque)
        const minTransparency = 0.38; // 60% transparency (slightly transparent)
    
        const transparency = (percent_correct / 100) * (maxTransparency - minTransparency) + minTransparency;
    
        const redValue = targetColor[0];
        const greenValue = targetColor[1];
        const blueValue = targetColor[2];
    
        return `rgba(${redValue}, ${greenValue}, ${blueValue}, ${transparency})`;
    };

    return(
            <>
                { props.conceptsAndSkills && props.conceptsAndSkills.map((item, index) =>
                    <div
                        className={`${classes.conceptOrSkill} ${ index === props.conceptsAndSkills.length - 1 ? classes.last : '' }`}
                        key={index}
                        style={{backgroundColor: calculateBackgroundColor(item.percent_correct)}}
                        onClick={() => props.onLaunch({ type: item.type, _id: item._id })}
                    >
                        <div className={classes.conceptOrSkillPercentage}>{item.percent_correct}%</div>
                        <div className={classes.conceptOrSkillText}>{item._id}</div>
                    </div>)
                }
            </>
    )
}

export default SkillsPerformance;