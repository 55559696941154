import { useState, useEffect } from 'react';
import EmptyBlock from '../components/UI/EmptyBlock';
import PageHeaderText from '../components/UI/PageHeaderText';
import SingleColumn from '../components/UI/SingleColumn';
import { MdArrowBack } from 'react-icons/md';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import { query, collection, where, getDocs } from 'firebase/firestore';
import { firestoreDb } from '../util/firebase';
import { database } from '../util/firebase';
import { ref, get, onValue } from 'firebase/database';
import StudentResultDetail from '../components/Cards/StudentResultDetail';

const IndividualResultPage = () => {
    const [setHeaderSize, authUserData] = useOutletContext();
    useEffect(() => {setHeaderSize('small');}, [setHeaderSize]);

    const navigate = useNavigate();
    const { id: attempt_id } = useParams()

    const [subStatus, setSubStatus] = useState('loading')
    const [trialStatus, setTrialStatus] = useState('loading');
    const [remainingDays, setRemainingDays] = useState(1);
    // handle subscriptions
    useEffect(() => {
        if(!authUserData) return;

        const fetchSubData = async () => {
            // create a query object to the current users active subscriptions
            const q = query(
                // currentUser is provided by firebase, via getAuth().currentUser
                collection(firestoreDb, 'customers', authUserData.token, 'subscriptions'), 
                where('status', 'in', ['trialing', 'active'])
            );
            
            // fetch the active subscriptions
            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                const trialStatusRef = ref(database, `users/${authUserData.token}/trial_expiry`);
                const trailStatusInfo = await get(trialStatusRef)

                if (trailStatusInfo.exists()) {
                    const givenDateStr = trailStatusInfo.val();
                    const givenDate = new Date(givenDateStr);
                    const currentDate = new Date();

                    const hasPassed = currentDate > givenDate;
                    if(!hasPassed) {
                        setTrialStatus('active')
                    } else {
                        currentDate.setDate(currentDate.getDate() - 15);
                        const hasPassed = currentDate > givenDate;

                        const timeDifference = givenDate - currentDate;
                        const remainingDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

                        setTrialStatus(hasPassed ? null : 'expiring_soon')
                        setRemainingDays(remainingDays)
                    }
                }
            }

            // assuming user only has one active subscription max
            if(querySnapshot?.docs[0]?.data()) {
                setSubStatus('active');
            } else {
                setSubStatus(null);
            }
        }

        fetchSubData()
    }, [authUserData]);

    const navigateToPricing = (event) => {
        event.preventDefault();
        navigate('/#pricing');
    };

    const [attemptData, setAttemptData] = useState({});
    useEffect(() => {
        if(!authUserData || !attempt_id || attempt_id === '') return;

        
        const unsubscribe = onValue(ref(database, `attempts/${attempt_id}`), (snapshot) => {
            setAttemptData(snapshot.val());
        })

        return () => unsubscribe();
        
    }, [attempt_id, authUserData])

    const backButtonClickHandler = () => {
        navigate(-1);
    };

    const [questionElements, setQuestionElements] = useState([])
    useEffect(() => {
        let questionData = [];

        for(const stimuliKey in attemptData.stimuli) {
            let questionObj = { stimuli_key: stimuliKey, text: attemptData.stimuli[stimuliKey].text, image: attemptData.stimuli[stimuliKey].image, questions: [] }
            for(const questionKey in attemptData.stimuli[stimuliKey].questions) {
                let smallObj = {...attemptData.stimuli[stimuliKey].questions[questionKey], question_key: questionKey, selected_answers: (attemptData.selected_answers && attemptData.selected_answers[stimuliKey] && attemptData.selected_answers[stimuliKey][questionKey]) ? attemptData.selected_answers[stimuliKey][questionKey] : {}}
                questionObj.questions.push(smallObj)
            }
            questionData.push(questionObj);
        }

        setQuestionElements(questionData);
    }, [attemptData])

    console.log(questionElements)

    return(
        <div>
            { authUserData && attemptData && Object.keys(attemptData)?.length > 0 && !('student' in authUserData?.roles) && (subStatus === 'active' || trialStatus === 'active' || trialStatus === 'expiring_soon' || authUserData.classlink_paid) && 
                <SingleColumn>
                    { authUserData && !('student' in authUserData?.roles) && trialStatus === 'expiring_soon' && subStatus !== 'loading' && 
                        <EmptyBlock sideMargins noSpaceAfter>Warning! Your free trial has ended and you do not have an active subscription. You will lose access to this resource in {remainingDays} days. <span onClick={navigateToPricing} style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer'}}>Upgrade</span></EmptyBlock>
                    }
                    <PageHeaderText tight>
                        <div style={{padding: '6px 6px 0px 0px'}}><MdArrowBack onClick={backButtonClickHandler} style={{cursor: 'pointer'}}/></div>
                        {attemptData.assessment.name}&nbsp;({attemptData.user.first_name}&nbsp;{attemptData.user.last_name})
                    </PageHeaderText>
                    {
                        questionElements.map((el, index) => <StudentResultDetail key={el.stimuli_key} data={el} index={index} last={index === questionElements.length - 1}/>)
                    }
                </SingleColumn>
            }
            { authUserData && !('student' in authUserData?.roles) && (subStatus !== 'active' && trialStatus !== 'active' && trialStatus !== 'expiring_soon' && subStatus !== 'loading' && !authUserData.classlink_paid ) && 
                <EmptyBlock sideMargins>Your free trial has expired and you do not have an active subscription. Please <span onClick={navigateToPricing} style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer'}}>upgrade</span> to access this resource.</EmptyBlock>
            }
            { (!authUserData || ('student' in authUserData?.roles) || !attemptData || !Object.keys(attemptData)?.length > 0) &&
                <EmptyBlock>You are not authorized to view this resource.</EmptyBlock>
            }
        </div>
    )
};

export default IndividualResultPage;