import classes from './FaqExpander.module.css';
import { useState } from 'react';

const FaqExpander = () => {
    const [expanded, setExpanded] = useState(false);

    return(
        <div className={ classes.faqWrapper }>
            <div className={ classes.questionWrapper } onClick={() => {setExpanded(state => !state)}}>
                    <strong>FAQ:</strong>&nbsp;What is a capstone question?
            </div>
            <div className={ `${classes.explanation} ${expanded ? classes.expanded : ''}` }>
                <strong>Capstones</strong> are STAAR-level questions. Before each capstone question, students will see a few low-level questions that help build specific skills needed to answer the STAAR-level question. 
            </div>
        </div>
    )
};

export default FaqExpander;