import classes from './ResultPreview.module.css';
import { ref, onValue } from 'firebase/database';
import { useState, useEffect, useCallback } from 'react';
import { database } from '../../util/firebase';
import { FaArrowRight } from "react-icons/fa";
import { IoDocumentTextOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';

const ResultPreview = (props) => {
    const [resultData, setResultData] = useState({});
    const navigate = useNavigate();

    const listenToMonitoringGroupHandler = useCallback (() => {
        const unsubscribes = [];
        let single_unsubscribe

        if(props.result._id) {
            const monitoringGroupRef = ref(database, `monitoring_groups/${props.result._id}`);
            single_unsubscribe = onValue(monitoringGroupRef, (snapshot) => {
                setResultData(snapshot.val());
            });
        } else if (props.result.monitoring_group_ids) {
            for(let i = 0; i < props.result.monitoring_group_ids.length; i++) {
                const monitoringGroupRef = ref(database, `monitoring_groups/${props.result.monitoring_group_ids[i]}`);
                const unsubscribe = onValue(monitoringGroupRef, (snapshot) => {
                    setResultData(data => {
                        // first, update the invidual pieces of data
                        let newData = { results: {}};
                        if(data && data.individual_data) newData.individual_data = {...data.individual_data}
                        else newData.individual_data = {}
                        newData.individual_data[props.result.monitoring_group_ids[i]] = {}
                        newData.individual_data[props.result.monitoring_group_ids[i]] = snapshot.val();

                        // then update the overall data and return it
                        const mergeResults = (targetTree, sourceTree) => {
                            Object.keys(sourceTree).forEach(key => {
                                if (typeof sourceTree[key] === 'object' && !Array.isArray(sourceTree[key])) {
                                    if (!targetTree[key]) {
                                        targetTree[key] = {};  // Initialize the target key if not present
                                    }
                                    // Recursively merge objects
                                    mergeResults(targetTree[key], sourceTree[key]);
                                } else if (key === 'correct_count' || key === 'total_count') {
                                    // If it's a count, add the values (initialize if not present)
                                    targetTree[key] = (targetTree[key] || 0) + sourceTree[key];
                                }
                            });
                        };
                    
                        // Now merge all the individual results into newData.results
                        Object.values(newData.individual_data).forEach(individualData => {
                            if (individualData && individualData.results) {
                                // Safely merge if results exist
                                mergeResults(newData.results, individualData.results);
                            }
                        });

                        return newData;
                    });
                });
                unsubscribes.push(unsubscribe);
            }
        }

        return () => {
            if(single_unsubscribe) single_unsubscribe()
            else if (unsubscribes.length > 0) unsubscribes.forEach(unsub => unsub());
        };
    }, [props.result._id, props.result.monitoring_group_ids]);

    useEffect(() => {
        if(props.result.groupedBy && (!resultData || Object.keys(resultData)?.length === 0)) {
            listenToMonitoringGroupHandler();
        }
    }, [listenToMonitoringGroupHandler, resultData, props.result.groupedBy]);

    let conceptsAndSkills = [];
    /* if(resultData?.results?.concepts) {
        for(const key in resultData.results.concepts) {
            conceptsAndSkills.push({ _id: key, type: 'concept', percent_correct: Math.round(resultData.results.concepts[key].correct_count / resultData.results.concepts[key].total_count * 100)})
        }
    } */
    if(resultData?.results?.skills) {
        for(const key in resultData.results.skills) {
            conceptsAndSkills.push({ _id: key, type: 'skill', percent_correct: Math.round(resultData.results.skills[key].correct_count / resultData.results.skills[key].total_count * 100)})
        }
    }
    conceptsAndSkills.sort((a, b) => b.percent_correct - a.percent_correct);

    let standards = [];
    if(resultData?.results?.standards) {
        for(const key in resultData.results.standards) {
            standards.push({ _id: key, type: 'standard', percent_correct: Math.round(resultData.results.standards[key].correct_count / resultData.results.standards[key].total_count * 100)})
        }
    }
    standards.sort((a, b) => b.percent_correct - a.percent_correct);

    let bestStandards = [];
    let worstStandards = [];
    if(standards.length > 1 && standards.length <= 3) {
        bestStandards.push(standards[0]);
        worstStandards.push(standards[standards.length - 1]);
    }
    else if(standards.length > 3) {
        bestStandards.push(standards[0]);
        bestStandards.push(standards[1]);
        worstStandards.push(standards[standards.length - 2]);
        worstStandards.push(standards[standards.length - 1]);
    }

    let majorMisconceptions = [];
    if(resultData?.results?.misconceptions) {
        for(const key in resultData.results.misconceptions) {
            if(Math.round(resultData.results.misconceptions[key].correct_count / resultData.results.misconceptions[key].total_count * 100) < 51) {
                majorMisconceptions.push({ _id: key, type: 'misconceptions', percent_correct: Math.round(resultData.results.misconceptions[key].correct_count / resultData.results.misconceptions[key].total_count * 100)})
            }
        }
        majorMisconceptions.sort((a, b) => a.percent_correct - b.percent_correct);
        majorMisconceptions = majorMisconceptions.slice(0, 3); // Trim to the first 3 values
    }

    let custom_text = 'Generating...'
    if(conceptsAndSkills?.length > 1) {
        const length = conceptsAndSkills.length;
        custom_text = `Your students showed strengths in "${conceptsAndSkills[0]?._id}"${length > 3 ? ` and "${conceptsAndSkills[1]?._id}"`:''}. However, they struggled with "${conceptsAndSkills[length - 1]._id}" and could use remediation in this area.`
    }

    const navigateToResultDetail = () => {
        let monitoring_groups = ''
        if(props.result._id) monitoring_groups += props.result._id
        else if(props.result.groupedBy) {
            for(let i = 0; i < props.result.monitoring_group_ids.length; i++) {
                monitoring_groups += props.result.monitoring_group_ids[i];
                if(i + 1 !== props.result.monitoring_group_ids.length) monitoring_groups += ','
            }
        }
        navigate(`/result_detail?monitoring_groups=${monitoring_groups}`);
    };

    return (
        <div className={ `${classes.resultWrapper} ${ props.last ? classes.lastResult : '' }` } onClick={navigateToResultDetail}>
            <div className={ classes.innerWrapper }>
                <div className={`${classes.submissions_sidebar} ${props.result.has_friend_results ? classes.withFriends : ''}`}>
                    <div className={classes.submission_icon}><IoDocumentTextOutline /></div>
                    <div className={classes.submission_text}>{props.result.submission_count}</div>
                </div>
                <div className={classes.moreIcon}><FaArrowRight /></div>
                <div className={ classes.resultName }>
                    {(props.result._id || props.result.groupedBy === 'assessment') ? props.result.assessment_name : '' }
                    {props.result._id && ` (${props.result.group_name})`}
                    {(props.result.groupedBy === 'group') ? props.result.group_name : '' }
                </div>
                <div className={ classes.summarizedFeedback }>{ props.result.groupedBy ? custom_text : props.result.feedback_text }</div>
            </div>
        </div>
    )
};

export default ResultPreview;