import classes from './StudentScoresBlock.module.css';
import { FaUser } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

const StudentScoresBlock = (props) => {
    const navigate = useNavigate();

    const getColor = (percent) => {
        if (percent > 69) return '#089b08'; // Green
        if (percent >= 40) return '#ffd900'; // Yellow
        return '#f44336'; // Red
    };

    return(
        <div className={classes.flexWrapper}>
            { props.attempts && Object.keys(props.attempts).sort((a, b) => {
                const lastNameA = props.attempts[a].last_name?.toLowerCase();
                const lastNameB = props.attempts[b].last_name?.toLowerCase();
                
                if (lastNameA < lastNameB) return -1;
                if (lastNameA > lastNameB) return 1;
                return 0;
            }).map(key => 
                <div key={key} className={classes.wrapper} onClick={() => {navigate(`/individual_result/${key}`)}}>
                    <div className={classes.sideIcon} style={{backgroundColor: getColor(props.attempts[key].score)}}><FaUser /></div>
                    {props.attempts[key].first_name}&nbsp;{props.attempts[key].last_name}
                    <div className={classes.score}>{props.attempts[key].score}</div>
                </div>
            )
            }
        </div>
    )
}

export default StudentScoresBlock;