import { useState, useEffect } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { HiOutlineHome, HiOutlineUserGroup, HiOutlineClipboardList } from 'react-icons/hi';
import { BiCctv, BiSolidUserCircle } from 'react-icons/bi';
import { GiAxeSword } from 'react-icons/gi';
import { FaRegLightbulb } from 'react-icons/fa';
import { VscGraph } from 'react-icons/vsc';
import { AiOutlineDashboard } from 'react-icons/ai';
import { RiQuestionAnswerLine } from 'react-icons/ri';
import { MdOutlineVerifiedUser, MdManageAccounts  } from 'react-icons/md';
import { NavLink, useNavigate } from 'react-router-dom';
import { auth /* , database */ } from '../../util/firebase';
/* import { ref, onValue } from 'firebase/database'; */
import classes from './MainNavigation.module.css';
/* import { onAuthStateChanged } from 'firebase/auth'; */
import DropdownInput from './DropdownInput';

const MainNavigation = (props) => {
    const [showNav, setShowNav] = useState(false);
    const [authUser, setAuthUser] = useState(null);

    /* useEffect(() => {
        // eslint-disable-next-line
        const listen = onAuthStateChanged(auth, (user) => {
            if (user) {
                const userGroupsRef = ref(database, `users/${user.uid}`);
                onValue(userGroupsRef, (snapshot) => {
                    setAuthUser(snapshot.val());
                });
            } else {
                setAuthUser(null);
            };
        });
    }, []); */

    const [view, setView] = useState('teacher');
    useEffect(() => {
        setAuthUser(props.userData);
        if(!props.userData?.roles?.admin && view === 'admin') {
            if(props.userData.roles.student) {
                setView('student');
            } else {
                setView('teacher');
            }
        }
    }, [props.userData, view])

    const showNavHandler = () => {
        setShowNav(true);
    };

    const hideNavHandler = () => {
        setShowNav(false);
    };

    const navigate = useNavigate();
    const navigateToHome = () => {
        navigate(`/`);
        setShowNav(false);
    };

    const logoutHandler = () => {
        auth.signOut();
        setShowNav(false);
    
        setTimeout(() => {
            navigate('/login');
        }, 300);
      }

    const view_options = [{ value: 'admin', text: 'Admin', index: 1 }, { value: 'teacher', text: 'Teacher', index: 2 }, { value: 'student', text: 'Student', index: 3 }]
    const viewToggleHandler = (view) => {
        setView(view);
    };

    return (
        <>
            <div className={ classes.hamburger } onClick={ showNavHandler }>
                <GiHamburgerMenu />
            </div>
            <div className={ `${ classes.navExpander } ${ showNav ? classes.show : '' }`}>
                <div className={ `${classes.navWrapper} ${authUser?.is_impersonating ? classes.impersonating : ''}` }>
                    <div className={ `${classes.popinHeader} ${authUser?.is_impersonating ? classes.impersonating : ''}` }>
                        <div className={ classes.centerWrapper } onClick={ navigateToHome }>
                            BRAIN <GiAxeSword /> RAIDER
                        </div>
                    </div>
                    <ul className={ classes.navListWrapper }>
                        <li className={ classes.navLink }>
                            <div className={ classes.centerWrapper }>
                                <HiOutlineHome />
                                <NavLink className={ classes.linkText } to='/' onClick={ hideNavHandler }>Home</NavLink>
                            </div>
                        </li>
                        { view === 'teacher' &&
                            <>
                                <li className={ classes.navLink }>
                                    <div className={ classes.centerWrapper }>
                                        <HiOutlineUserGroup />
                                        <NavLink className={ classes.linkText } to='/groups' onClick={ hideNavHandler }>Groups</NavLink>
                                    </div>
                                </li>
                                <li className={ classes.navLink }>
                                    <div className={ classes.centerWrapper }>
                                        <HiOutlineClipboardList />
                                        <NavLink className={ classes.linkText } to='/assessments' onClick={ hideNavHandler }>Assessments</NavLink>
                                    </div>
                                </li>
                                <li className={ classes.navLink }>
                                    <div className={ classes.centerWrapper }>
                                        <BiCctv />
                                        <NavLink className={ classes.linkText } to='/monitor' onClick={ hideNavHandler }>Monitor</NavLink>
                                    </div>
                                </li>
                                <li className={ classes.navLink }>
                                    <div className={ classes.centerWrapper }>
                                        <VscGraph />
                                        <NavLink className={ classes.linkText } to='/results' onClick={ hideNavHandler }>Results</NavLink>
                                    </div>
                                </li>
                            </>
                        }
                        { view === 'admin' &&
                            <>
                                <li className={ classes.navLink }>
                                    <div className={ classes.centerWrapper }>
                                        <HiOutlineClipboardList />
                                        <NavLink className={ classes.linkText } to='/assessments' onClick={ hideNavHandler }>Assessments</NavLink>
                                    </div>
                                </li>
                                <li className={ classes.navLink }>
                                    <div className={ classes.centerWrapper }>
                                        <FaRegLightbulb />
                                        <NavLink className={ classes.linkText } to='/feedback' onClick={ hideNavHandler }>Feedback</NavLink>
                                    </div>
                                </li>
                                <li className={ classes.navLink }>
                                    <div className={ classes.centerWrapper }>
                                        <RiQuestionAnswerLine />
                                        <NavLink className={ classes.linkText } to='/questions' onClick={ hideNavHandler }>Questions</NavLink>
                                    </div>
                                </li>
                                <li className={ classes.navLink }>
                                    <div className={ classes.centerWrapper }>
                                        <MdOutlineVerifiedUser />
                                        <NavLink className={ classes.linkText } to='/verification' onClick={ hideNavHandler }>Verification</NavLink>
                                    </div>
                                </li>
                                <li className={ classes.navLink }>
                                    <div className={ classes.centerWrapper }>
                                    <MdManageAccounts />

                                        <NavLink className={ classes.linkText } to='/users' onClick={ hideNavHandler }>Accounts</NavLink>
                                    </div>
                                </li>
                            </>
                        }
                        { view === 'student' &&
                            <li className={ classes.navLink }>
                                <div className={ classes.centerWrapper }>
                                    <AiOutlineDashboard />
                                    <NavLink className={ classes.linkText } to='/dashboard' onClick={ hideNavHandler }>Dashboard</NavLink>
                                </div>
                            </li>
                        }
                        { authUser && ('admin' in authUser?.roles) &&
                            <li className={ `${classes.navLink} ${classes.toRight}` }>
                                <div className={ `${classes.centerWrapper} ${classes.horizontalCenter}` }>
                                    <div className={ classes.description }>View:&nbsp;</div><DropdownInput name='view-toggle' value={ view } onChange={ viewToggleHandler } nospace options={ view_options } alt/>
                                </div>
                            </li>
                        }
                    </ul>
                    { authUser &&
                        <div className={ `${classes.userInfoWrapper} ${authUser?.is_impersonating ? classes.impersonating : ''}` }>
                            <BiSolidUserCircle />
                            <NavLink className={ classes.userText } to='/account-settings' onClick={ hideNavHandler }>{ authUser.first_name }</NavLink>
                        </div>
                    }
                    <div className={ classes.logoutWrapper }>
                        { authUser && !authUser.is_impersonating && <button className={ classes.logoutText } onClick={ logoutHandler }>Log Out</button>}
                        { authUser && authUser.is_impersonating && <button className={ classes.logoutText } onClick={ props.onStopImpersonating }>Back</button>}
                        { !authUser && <NavLink className={ classes.loginText } to='/login' >Log In</NavLink>}
                    </div>
                </div>
            </div>
            <div className={ `${ classes.screenOverlay } ${ showNav ? classes.show : '' }` } onClick={ hideNavHandler }/>
        </>
    );
};

export default MainNavigation;