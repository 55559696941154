import classes from './ResearchCard.module.css';
import brainImage from '../../images/homepage-brain-circle.svg';
import outcomesImage from '../../images/homepage-outcomes-circle.svg';
import feedbackImage from '../../images/homepage-feedbackbox-circle.svg';

const ResearchCard = (props) => {
    const content = () => {
        switch(props.variant) {
            case 'feedback':
                return {
                    title: 'Formative Assessments and Feedback',
                    image: 'feedback',
                    text: "Research by Paul Black and Dylan Wiliam (1998) in \"Inside the Black Box: Raising Standards Through Classroom Assessment\" demonstrates that formative assessments significantly improve student achievement. John Hattie's research (2009) in \"Visible Learning: A Synthesis of Over 800 Meta-Analyses Relating to Achievement\" highlights feedback as a top influence on student learning, emphasizing the importance of timely and specific feedback to help students understand their progress and areas for improvement.",
                    extra: ""
                
                };
            case 'data':
                return {
                    title: 'Data-Driven Instruction',
                    image: 'outcomes',
                    text: "Hamilton et al. (2009), in \"Using Student Achievement Data to Support Instructional Decision Making,\" underscore the importance of data-driven decision-making. Teachers who analyze student data can better tailor their instruction to meet diverse learning needs, providing targeted support that addresses specific areas where students may be struggling. The study also points out that regular analysis of data helps educators identify trends and gaps, allowing for timely interventions.",
                    extra: ""
                };
            case 'targeting':
                return {
                    title: 'Targeting Specific Skills and Misconceptions',
                    image: 'brain',
                    text: "Research by Margaret Heritage (2010) in \"Formative Assessment: Making It Happen in the Classroom\" emphasizes the importance of targeting specific student skills and misconceptions. This approach allows teachers to pinpoint exactly what students need to relearn, making instruction more effective and efficient, and leading to better learning outcomes. Heritage's work highlights that addressing misconceptions early prevents them from becoming entrenched, making future learning more successful.",
                    extra: ""
                };
            default:
                return {
                    title: '',
                    text: ''
                };
        }
    }
    return(
        <div className={`${classes.rowWrapper} ${props.topBorder ? classes.topBorder : ''}`}>
            <div className={`${classes.imageWrapper} ${classes.showSmall} ${props.imageFirst ? classes.show : classes.hide}`}>
                { content().image === 'brain' && <img className={classes.image} alt={content().title} src={brainImage} />}
                { content().image === 'outcomes' && <img className={classes.image} alt={content().title} src={outcomesImage} />}
                { content().image === 'feedback' && <img className={classes.image} alt={content().title} src={feedbackImage} />}
            </div>
            <div className={classes.textWrapper}>
                <strong>{content().title}</strong><span className={classes.hideSmall}>&nbsp;&nbsp;</span>{content().text}
                <span className={classes.extra}>&nbsp;{content().extra}</span>
            </div>
            <div className={`${classes.imageWrapper} ${classes.hideSmall} ${!props.imageFirst ? '' : classes.hide}`}>
                { content().image === 'brain' && <img className={classes.image} alt={content().title} src={brainImage} />}
                { content().image === 'outcomes' && <img className={classes.image} alt={content().title} src={outcomesImage} />}
                { content().image === 'feedback' && <img className={classes.image} alt={content().title} src={feedbackImage} />}
            </div>
        </div>
    )
};

export default ResearchCard;