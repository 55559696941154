import classes from './ExportBlock.module.css';
import { database } from '../../util/firebase';
import { set, ref, get, child } from 'firebase/database';
import { BsFiletypeCsv, /* BsFiletypePdf */ } from "react-icons/bs";
import { getAnalytics, logEvent } from "firebase/analytics";

const ExportBlock = (props) => {
    const exportCsv = async () => {
        set(child(ref(database), `user_accounts/${props.token}/has_exported_result_csv`), true);

        try {
            const analytics = getAnalytics();
            logEvent(analytics, 'has_exported_result_csv');
        } catch (error) {
            console.error('Error logging event:', error);
        }

        const csvData = Object.keys(props.resultData.attempts).map(key => ({email: '', first_name: props.resultData.attempts[key].first_name, last_name: props.resultData.attempts[key].last_name, user_id: props.resultData.attempts[key].user_id, score: props.resultData.attempts[key].score || 0}))
        
        // Get emails to attach to the report
        // TODO: Make this SIS_IDs?
        for(let i = 0; i < csvData.length; i++) {
            await get(child(ref(database), `users/${csvData[i].user_id}/email`)).then((snapshot) => {
                if(snapshot.exists()) {
                    csvData[i].email = snapshot.val()
                } else {
                    console.log('failed email')
                }
            }).catch((error) => {
                console.error(error);
            });

            delete csvData[i].user_id;
        }

        // Extract column headers from the first object in csvData
        const headers = Object.keys(csvData[0]);

        // Convert array of objects to CSV string
        const csvString = [
            headers.join(','), // Include column headers as the first row
            ...csvData.map(row => headers.map(header => row[header]).join(','))
        ].join('\n');

        // Create a Blob object from the CSV string
        const blob = new Blob([csvString], { type: 'text/csv' });

        // Create a URL for the Blob object
        const url = URL.createObjectURL(blob);

        // Create an anchor element
        const a = document.createElement('a');
        a.href = url;
        a.download = `${props.resultData.assessment.name} - ${props.resultData.group.name} - Results.csv`; // Set the filename for download
        a.click();

        // Release the URL object
        URL.revokeObjectURL(url);
    };

    return(
        <div className={classes.exportWrapper}>
            <button className={classes.csvExport} onClick={exportCsv}><span className={classes.exportCsvIcon}><BsFiletypeCsv /></span>Export <span className={classes.hideSmall}>&nbsp;Results&nbsp;</span>as CSV</button>
            {/* <button className={classes.pdfExport} disabled><span className={classes.exportPdfIcon}><BsFiletypePdf /></span>Export <span className={classes.hideSmall}>&nbsp;Results&nbsp;</span>as PDF</button> */}
        </div>
    )
}

export default ExportBlock;