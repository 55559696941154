import SingleColumn from '../components/UI/SingleColumn';
import { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import TextBlock from '../components/UI/TextBlock';
import PageHeaderText from '../components/UI/PageHeaderText';
import HomepageBottomBar from '../components/Cards/HomepageBottomBar';

const ThirdPartyVendorsPage = () => {
    const [setHeaderSize] = useOutletContext();
    useEffect(() => {setHeaderSize('small');}, [setHeaderSize]);

  return (
    <SingleColumn>
        <PageHeaderText>Third Party Vendors</PageHeaderText>
        <TextBlock>
        In order to provide users with the Brain Raider service, we utilize a number of third party service vendors as defined in our Privacy Policy.
        <br /><br />This list includes the providers we utilize, what information may be shared with them to provide specific features of the service, and links to their privacy policy.
        <br /><br />
        <table style={{borderCollapse: 'collapse'}}>
            <thead>
                <tr>
                    <th style={{border: '1px solid black', padding: '6px'}}>Party Name</th>
                    <th style={{border: '1px solid black', padding: '6px'}}>Purpose</th>
                    <th style={{border: '1px solid black', padding: '6px'}}>Data Disclosed</th>
                    <th style={{border: '1px solid black', padding: '6px'}}>Privacy Information</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style={{border: '1px solid black', padding: '6px'}}>Firebase (by Google)</td>
                    <td style={{border: '1px solid black', padding: '6px'}}>Cloud hosting of servers for web site and database servers</td>
                    <td style={{border: '1px solid black', padding: '6px'}}>
                    Encrypted forms of:
                    <ul>
                    <li>Names, e-mails, hashed passwords</li>
                    <li>Assessment, student, and class data</li>
                    <li>Backups and log files</li>
                    </ul>
                    </td>
                    <td style={{border: '1px solid black', padding: '6px'}}><a href="https://firebase.google.com/support/privacy">https://firebase.google.com/support/privacy</a></td>
                </tr>
                <tr>
                    <td style={{border: '1px solid black', padding: '6px'}}>Stripe</td>
                    <td style={{border: '1px solid black', padding: '6px'}}>Payment processing for Individual Plan</td>
                    <td style={{border: '1px solid black', padding: '6px'}}>
                    Encrypted forms of:
                    <ul>
                    <li>Names and emails</li>
                    <li>Billing information</li>
                    <li>Transaction details</li>
                    <li>Payment method information</li>
                    </ul>
                    </td>
                    <td style={{border: '1px solid black', padding: '6px'}}><a href="https://stripe.com/privacy">https://stripe.com/privacy</a></td>
                </tr>
            </tbody>
        </table>
        </TextBlock>
        <HomepageBottomBar />
    </SingleColumn>
  );
}

export default ThirdPartyVendorsPage;