import SingleColumn from '../components/UI/SingleColumn';
import { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import PageHeaderText from '../components/UI/PageHeaderText';
import AssessmentToDo from '../components/Cards/AssessmentToDo';
import EmptyBlock from '../components/UI/EmptyBlock';
import StudentResult from '../components/Cards/StudentResult';


const StudentDashboard = () => {
    const [setHeaderSize, authUserData] = useOutletContext();
    useEffect(() => {setHeaderSize('small');}, [setHeaderSize]);

    var unfinishedAttempts = []
    if (authUserData?.unfinished_attempts) {
        Object.keys(authUserData.unfinished_attempts).forEach((key) => {
            unfinishedAttempts.push({_id: key, ...authUserData.unfinished_attempts[key]})
        });
    };

    var finishedAttempts = []
    if (authUserData?.finished_attempts) {
        Object.keys(authUserData.finished_attempts).forEach((key) => {
            finishedAttempts.push({_id: key, ...authUserData.finished_attempts[key]})
        });
        finishedAttempts.reverse();
    };

    return (
        <SingleColumn>
            { authUserData && 
                <div>
                    <PageHeaderText>To-Do</PageHeaderText>
                    { unfinishedAttempts.length === 0 && <EmptyBlock>You're all caught up!</EmptyBlock> }
                    { unfinishedAttempts.length > 0 && unfinishedAttempts.map((attempt, index) => <AssessmentToDo key={attempt._id} attempt={attempt} last={index === unfinishedAttempts.length - 1}/>)}
                    <PageHeaderText>Results</PageHeaderText>
                    { (finishedAttempts.length === 0 || finishedAttempts.filter(attempt => !attempt.archived).length === 0) && <EmptyBlock>No results yet.</EmptyBlock> }
                    { finishedAttempts.length > 0 && finishedAttempts.filter(attempt => !attempt.archived).map(attempt => <StudentResult key={attempt._id} attempt={attempt} /* TODO: Add last?*/ />) }
                </div>
            }
            { !authUserData &&
                <EmptyBlock>You are not authorized to view this resource.</EmptyBlock>
            }
        </SingleColumn>
    );
};

export default StudentDashboard;