import classes from './DropdownInput.module.css';

const DropdownInput = (props) => {
    const selectionChangeHandler = (event) => {
        props.onChange(event.target.value);
    };

    return (
        <div className={ classes.inputWrapper }>
            <select id={ props.name } className={ `${classes.selector} ${ props.nospace ? classes.noSpaceAfter : '' } ${ props.error ? classes.hasError : '' } ${props.alt ? classes.alt : ''}` } value={ props.value } onChange={ selectionChangeHandler }>
                {props.options?.map(option => <option key={option.value} value={option.value}>{option.text}</option>)}
            </select>
            { props.error && <div className={ `${classes.errorText} ${ props.nospaceafter ? classes.noSpaceAfter : '' }` }>{props.error}</div> }
        </div>
    )
};

export default DropdownInput;