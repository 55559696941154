import classes from './DragDropAnchorSelector.module.css';
import { useState, useEffect, useRef } from "react";
import { v4 } from 'uuid';

const DragDropAnchorSelector = (props) => {
    const overlayRef = useRef(null);
    const [anchors, setAnchors] = useState([]);
    const [isCtrlKeyDown, setIsCtrlKeyDown] = useState(false);

    const imgClickHandler = (event) => {
        if(props.static || props.studentMode) return;
        const overlayRect = overlayRef.current.getBoundingClientRect();
    
        const xPercent = (((event.clientX - overlayRect.left) / overlayRect.width) * 100).toFixed(4);;
        const yPercent = (((event.clientY - overlayRect.top) / overlayRect.height) * 100).toFixed(4);;

        let isDuplicate = false;

        // TODO: Add logic to keep the anchors from being too close together

        if(!isDuplicate) {
            const updatedAnchors = {...anchors};
            let new_display_num = 1;
            if(Object.keys(anchors)?.length > 0) new_display_num = Math.max(...Object.keys(updatedAnchors)?.map(key => parseInt(updatedAnchors[key].display_num))) + 1;
            updatedAnchors[v4()] = { x: xPercent, y: yPercent, display_num: new_display_num };
            setAnchors(updatedAnchors);
            props.onChangeAnchors(updatedAnchors);
        };

    };

    const deleteAnchor = (id) => {
        props.onError('');
        console.log(id)
        let updatedAnchors = {...anchors};
        const deleted_num = updatedAnchors[id].display_num;
        delete updatedAnchors[id];

        for(let key in updatedAnchors) {
            if(updatedAnchors[key].display_num > deleted_num) {
                updatedAnchors[key].display_num--;
            }
        }

        setAnchors(updatedAnchors);
        props.onChangeAnchors(updatedAnchors);
    };

    const anchorClickHandler = (id) => {
        // TODO: Update this logic for student taking the quiz
        // if(!props.studentMode && !props.static) props.onError('');
        if(props.static) return;
        if(isCtrlKeyDown && !props.studentMode) {
            deleteAnchor(id)
            return;
        };
    };

    useEffect(() => {
        if(props.static) return;
        // Attach keydown and keyup event listeners
        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('keyup', handleKeyUp);

        // Cleanup function to remove event listeners
        return () => {
            if(props.static) return;
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('keyup', handleKeyUp);
        };
    }, [props.static]);

    // const [hasLoadedAnswers, setHasLoadedAnswers] = useState(false);
    useEffect(() => {
        // if(hasLoadedAnswers) return;
        if(anchors.length > 1 && !props.static) return;

        // console.log('ImageCanvas received props:', props);
        const updatedAnchors = props.initialAnchors || [];
        setAnchors(updatedAnchors);
        props.onChangeAnswerRegions && props.onChangeAnswerRegions(updatedAnchors);
        // setHasLoadedAnswers(true);
        // eslint-disable-next-line
    }, [props.initialAnchors]);

    const handleKeyDown = (event) => {
        if (event.ctrlKey) {
            setIsCtrlKeyDown(true);
        }
    };

    const handleKeyUp = (event) => {
        if (!event.ctrlKey) {
            setIsCtrlKeyDown(false);
        }
    };

    // TODO: Clean up unneccesary CSS still left from ImageCanvas
    return (
        <div className={classes.bigWrapper}>
            <div className={`${classes.wrapper} ${anchors.length > 0 && !props.static && !props.studentMode ? classes.hasResetButton : ''} ${props.static ? classes.static : ''} ${props.studentMode ? classes.studentMode : '' }`}>
                <img className={classes.image} src={props.imagesrc} alt="" />
                <div className={`${classes.overlay} ${props.static ? classes.static : ''} ${props.studentMode ? classes.studentMode : ''}`} ref={overlayRef} onMouseDown={imgClickHandler} />
                {
                    Object.keys(anchors)?.map(key => <div key={key} className={`${classes.anchorCircle} ${anchors[key].isCorrect ? classes.correct : ''} ${props.static ? classes.static : ''} ${props.studentMode ? classes.studentMode : ''}`} style={{ top: `${anchors[key].y}%`, left: `${anchors[key].x}%` }}onClick={anchorClickHandler.bind(null, key)}>{anchors[key].display_num}</div>)
                }
            </div>
            {
                anchors.length > 0 && !props.static && !props.studentMode && 
                <button className={classes.resetButton} onClick={() => {setAnchors([]); props.onChangeAnchors([]);}}>Reset All Anchors</button>
            }
        </div>
    );
};

export default DragDropAnchorSelector;