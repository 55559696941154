import classes from './FlexRow.module.css';

const FlexRow = (props) => {
    return(
        <div className={`${classes.flexRow} ${props.withBorders ? classes.borders : ''} ${ props.width === '3' ? classes.three : ''} ${ props.width === '2' ? classes.two : ''} ${ props.noSpace ? classes.noSpace : '' }`}>
            {props.children}
        </div>
    )
};

export default FlexRow;