import headerArtPng from '../../images/background-art-mobile-png.png'
import headerArtWidePng from '../../images/background-art-wide-png.png'
import { GiAxeSword } from 'react-icons/gi';
import { BsFillPlayFill } from 'react-icons/bs';
import { FaRegUser } from 'react-icons/fa';
import classes from './HeaderBar.module.css';
import { useNavigate } from 'react-router-dom';
import { auth, database } from '../../util/firebase';
import { ref, get, update } from 'firebase/database';

const HeaderBar = (props) => {
    const navigate = useNavigate();

    const logoutHandler = () => {
        if(props.studentMode && props.location?.includes('/attempts/')) {
            const attempt_id = props.location.substring(10);

            const attemptsRef = ref(database, `attempts/${attempt_id}`);
            get(attemptsRef)
                .then((snapshot) => {
                    const data = snapshot.val();
                    const monitoringGroupID = data.monitoring_group_id;

                    const updates = {};
                    updates[`attempts/${attempt_id}/is_focus`] = false;
                    updates[`monitoring_groups/${monitoringGroupID}/attempts/${attempt_id}/is_focus`] = false;
                    update(ref(database), updates);
                })
                .then(() => {
                    auth.signOut();
                })
                .catch((error) => {
                    console.error('Error retrieving data:', error);
                    auth.signOut();
                });
        } else {
            auth.signOut();
        }
    };

    const navToDashboardHandler = () => {
        if(props.studentMode && props.location?.includes('/attempts/')) {
            const attempt_id = props.location.substring(10);

            const attemptsRef = ref(database, `attempts/${attempt_id}`);
            get(attemptsRef)
                .then((snapshot) => {
                    const data = snapshot.val();
                    const monitoringGroupID = data.monitoring_group_id;

                    const updates = {};
                    updates[`attempts/${attempt_id}/is_focus`] = false;
                    updates[`monitoring_groups/${monitoringGroupID}/attempts/${attempt_id}/is_focus`] = false;
                    update(ref(database), updates);
                })
                .then(() => {
                    navigate('/')
                })
                .catch((error) => {
                    console.error('Error closing focus eye:', error);
                    navigate('/')
                });
        } else {
            navigate('/')
        }
    };

    return (
        <div className={ `${classes.headerWrapper} ${props.size === 'large' ? classes.large : '' } ${props.size === 'xlarge' ? classes.xlarge : '' } ${props.size === 'medium' ? classes.medium : '' }` }>
            
            <div className={ `${classes.narrow} ${classes.headerArt} ${props.size === 'large' ? classes.large : '' } ${props.size === 'xlarge' ? classes.xlarge : '' } ${props.size === 'medium' ? classes.medium : '' }` }>
                <img src={headerArtPng} className={ classes.art } alt="" ></img>
            </div>
            <div className={ `${classes.wide} ${classes.headerArt} ${props.size === 'large' ? classes.large : '' } ${props.size === 'xlarge' ? classes.xlarge : '' } ${props.size === 'medium' ? classes.medium : '' }` }>
                <img src={headerArtWidePng} className={ classes.art } alt="" ></img>
            </div>
            {/* <img src={ headerArtWide } alt='' className={ `${classes.wide} ${classes.headerArt} ${props.size === 'large' ? classes.large : '' } ${props.size === 'xlarge' ? classes.xlarge : '' } ${props.size === 'medium' ? classes.medium : '' }` } /> */}
            <div className={ `${classes.centerWrapper} ${props.showLogin || props.studentMode ? classes.leftAlign : '' }`} onClick={() => {if(!props.studentMode) {navigate('/');} else {navToDashboardHandler()};}}>
                BRAIN <GiAxeSword /> RAIDER
            </div>
            { props.studentMode &&
                <>
                    <div className={ classes.accountInfo }><FaRegUser /> {props.studentName}</div>
                    <div className={ classes.studentLogout } onClick={logoutHandler}>Log Out</div>
                </>
            }
            {   props.showLogin && props.location !== '/login' && 
                <button className={ classes.loginButton } onClick={() => {navigate('/login');}}>
                    <div className={ classes.buttonCenterWrapper}>
                        Log In&nbsp;<BsFillPlayFill />
                        </div>
                </button>
            }
        </div>
    );
}

export default HeaderBar;