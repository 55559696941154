import classes from './RightColumn.module.css';

const RightColumn = (props) => {
    return (
        <div className={`${classes.columnWrapper} ${props.narrow ? classes.narrow : ''} ${props.stackable ? classes.stackable : ''}` }>
            { props.children }
        </div>
    );
};

export default RightColumn;