import classes from './ButtonGroup.module.css';

const ButtonGroup = (props) => {
    return (
        <div className={ `${classes.groupWrapper} ${props.fitted ? classes.fitted : ''}` }>
            {props.children}
        </div>
    )
};

export default ButtonGroup;