import classes from './FeatureCard.module.css';
import libraryImage from '../../images/homepage-library-circle.svg';
import assignImage from '../../images/homepage-assign-circle.svg';
import monitorImage from '../../images/homepage-monitor-circle.svg';
import feedbackImage from '../../images/homepage-feedback-circle.svg';
import reportImage from '../../images/homepage-report-circle.svg';
import devicesImage from '../../images/homepage-devices-circle.svg';

const FeatureCard = (props) => {

    const pictureSrc = () => {
        switch(props.feature.picture) {
            case 'library': return libraryImage;
            case 'assign': return assignImage;
            case 'monitor': return monitorImage;
            case 'feedback': return feedbackImage;
            case 'report': return reportImage;
            case 'devices': return devicesImage;
            default: return null;
        }
    };

    return (
        <div className={classes.card}>
            <div className={classes.circle}>
                {
                    pictureSrc() !== null && <img src={pictureSrc()} alt={props.feature.title} />
                }
            </div>
            <div className={classes.title}>
                {props.feature.title || ''}
            </div>
            <div className={classes.description}>
                {props.feature.description || ''}
            </div>
        </div>
    );
};

export default FeatureCard;