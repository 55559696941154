import classes from './HeroText.module.css';

const HeroText = (props) => {
    return (
        <div className={ classes.heroText }>
            {props.children}
        </div>
    );
};

export default HeroText;