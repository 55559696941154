import HorizontalLine from '../UI/HorizontalLine';
import EmptyBlock from '../UI/EmptyBlock';
import { BsFillPlayFill } from 'react-icons/bs';
import { FaRegEdit } from 'react-icons/fa';
import { AiOutlineFileSearch } from "react-icons/ai";
import classes from './AssessmentDetail.module.css';
import { useState, useEffect } from 'react';
import { database } from '../../util/firebase';
import { ref, onValue } from 'firebase/database';
import Capstone from './Capstone';
import { FaUserFriends } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";

const AssessmentDetail = (props) => {
    let standards = '';

    let matchingStandards = [];
    if(props.assessment.standards && props.standardsToShow) {
        Object.keys(props.standardsToShow).forEach(state => {
            Object.keys(props.standardsToShow[state]).forEach(standard_group => {
                if(props.assessment.standards[state]) {
                    if(props.assessment.standards[state]) {
                        if(props.assessment.standards[state][standard_group]) {
                            Object.keys(props.assessment.standards[state][standard_group]).forEach(code => {
                                matchingStandards.push(code);
                            })
                        };
                    };
                };
            })
        })
    };

    if(matchingStandards.length > 0) {
        standards = matchingStandards.map(standard => standard.split(' ').slice(0, 1)).slice(0, 1) + ' ' + matchingStandards.map(standard => standard.split(' ').slice(-1)).join(', ');
    };

    const [hasLoaded, setHasLoaded] = useState(false);
    const [capstoneKeys, setCapstoneKeys] = useState([]);
    useEffect(() => {
        let unsubscribe

        if(props.personal === '' && !props.friendUid) {
            unsubscribe = onValue(ref(database, `assessments/${props.assessment._id}/stimuli`), (snapshot) => {
            if(snapshot.exists()) {
                setCapstoneKeys(Object.keys(snapshot.val()))
            } else {
                setCapstoneKeys([]);
            }});
        setHasLoaded(true);
        } else if (props.friendUid) {
            unsubscribe = onValue(ref(database, `personal_assessments/${props.friendUid}/${props.assessment._id}/stimuli`), (snapshot) => {
                if(snapshot.exists()) {
                    setCapstoneKeys(Object.keys(snapshot.val()))
                } else {
                    setCapstoneKeys([]);
                }});
            setHasLoaded(true);
        } else {
            unsubscribe = onValue(ref(database, `personal_assessments/${props.personal}/${props.assessment._id}/stimuli`), (snapshot) => {
                if(snapshot.exists()) {
                    setCapstoneKeys(Object.keys(snapshot.val()))
                } else {
                    setCapstoneKeys([]);
                }});
            setHasLoaded(true);
        }

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };

    }, [props.assessment, props.personal, props.friendUid]);

    return (
        <>
        <div className={`${classes.header} ${props.assessment.author === 'You' ? classes.own : (props.friendUid ? classes.friend : '')}`}>
                {props.assessment.name}
            </div>
            <div className={`${classes.buttonBar} ${props.assessment.author === 'You' ? classes.own : ''}`}>
                { props.onOpenInEditor && !props.friendUid &&
                    <button className={ `${classes.newButton} ${classes.alt} ${props.assessment.author === 'You' ? classes.personal : ''}` } onClick={ props.onOpenInEditor.bind(null, props.assessment._id) }>
                        <div className={ classes.centerWrapper}>
                        <FaRegEdit />&nbsp;Edit
                        </div>
                    </button>
                }
                {
                    props.onGoDeep && props.assessment.capstone_num !== 0 && 
                    <button className={ `${classes.newButton} ${classes.alt} ${props.assessment.author === 'You' ? classes.personal : ''}` } onClick={ props.onGoDeep.bind(null, props.assessment._id) }>
                        <div className={ classes.centerWrapper}>
                        <AiOutlineFileSearch />&nbsp;<span className={classes.hideSmall}>Detailed View</span><span className={classes.showSmall}>Details</span>
                        </div>
                    </button>
                }
                { props.onAssign &&
                    <button className={ `${classes.newButton} ${(props.assessment.author === 'You' || props.friendUid) ? classes.personal : ''} ${classes.assign}` } onClick={ props.onAssign.bind(null, props.assessment._id, props.assessment.name, props.assessment.description, props.assessment.capstone_num, props.personal || props.friendUid) }>
                        <div className={ classes.centerWrapper}>
                            Assign&nbsp;<BsFillPlayFill />
                        </div>
                    </button>
                }
                {
                    props.onEditDetails && !props.onOpenInEditor && !props.onAssign &&
                    <button className={ `${classes.newButton} ${classes.even} ${classes.alt} ${props.assessment.author === 'You' ? classes.personal : ''}` } onClick={ props.onEditDetails.bind(null, props.assessment._id) }>
                        <div className={ classes.centerWrapper}>
                            <FaRegEdit />&nbsp;<span className={classes.hideSmall}>Edit Details</span><span className={classes.showSmall}>Edit</span>
                        </div>
                    </button>
                }
                {
                    props.onDelete && !props.onOpenInEditor && !props.onAssign &&
                    <button className={ `${classes.newButton} ${classes.alt} ${props.assessment.author === 'You' ? classes.personal : ''}` } onClick={ props.onDelete }>
                        <div className={ classes.centerWrapper}>
                            <MdDeleteOutline />&nbsp;<span className={classes.hideSmall}>Delete Assessment</span><span className={classes.showSmall}>Delete</span>
                        </div>
                    </button>
                }
            </div>
            <div className={`${classes.questions} ${props.assessment.author === 'You' ? classes.own : (props.friendUid ? classes.friend : '')}`}>
                {props.assessment.capstone_num} Stimul{props.assessment.capstone_num === 1 ? 'us' : 'i'} &<br />{props.assessment.question_num ?? '??'} Question{props.assessment.question_num === 1 ? '' : 's'}
            </div>
            <div className={classes.description}>
                {props.assessment.description}
            </div>
            <div className={classes.bottomRow}>
                <div className={classes.bottomLeft}>
                    {props.assessment.author === 'You' ?
                        <span style={{fontSize: '14px'}}><FaUser /></span> :
                        <FaUserFriends />
                    }
                    &nbsp;<span className={classes.owner}>{props.assessment.author === 'You' ? 'You' : 'Brain Raider'}</span>
                </div>
                <div className={classes.bottomRight}>{ standards }</div>
            </div>
            <HorizontalLine />
            <div className={classes.contentWrapper}>
                { capstoneKeys?.length > 0 && 
                    capstoneKeys.map((key, index) =>
                        <Capstone capstoneKey={key} index={index} key={key} onRemove={props.onRemove} last={index === capstoneKeys.length - 1} numbered preview canExpandQuestions withLines isAdmin={props.isAdmin} ultraSmallEnabled={props.onRemove ? true : false} />
                )}
                { hasLoaded && capstoneKeys === null && <EmptyBlock>No questions found.</EmptyBlock>}
            </div>
        </>
    );
};

export default AssessmentDetail;