import SingleColumn from '../components/UI/SingleColumn';
import { useState, useEffect } from 'react';
import { useOutletContext, useLocation } from 'react-router-dom';
import { auth } from '../util/firebase';
import { applyActionCode, verifyPasswordResetCode, confirmPasswordReset, checkActionCode } from 'firebase/auth';
import EmptyBlock from '../components/UI/EmptyBlock';
import TextInput from '../components/UI/TextInput';
import Button from '../components/UI/Button';
import PageHeaderText from '../components/UI/PageHeaderText';
import TextBlock from '../components/UI/TextBlock';

const AccountManagement = () => {
  const [setHeaderSize] = useOutletContext();
  useEffect(() => { setHeaderSize('large'); }, [setHeaderSize]);

  const useQuery = () => new URLSearchParams(useLocation().search);

  const query = useQuery();
  const mode = query.get('mode');
  const oobCode = query.get('oobCode');

  const [text, setText] = useState('Processing request...');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  useEffect(() => {
    if (mode === 'verifyEmail' && oobCode) {
      // Verify the email using Firebase
      applyActionCode(auth, oobCode)
        .then(() => {
            // Email verified successfully
            setText('Email verified successfully. Redirecting...');

            // Wait for 2 seconds before navigating
            setTimeout(() => {
                window.location.href = '/assessments';
            }, 2000); // 2000 milliseconds = 2 seconds
        })
        .catch((error) => {
          // Handle error
          console.error('Error verifying email:', error);
          setText('Failed to verify email. If the issue persists, please reach out to support@brainraider.com.');
        });
    } else if (mode === 'resetPassword' && oobCode) {
      // Handle password reset
      verifyPasswordResetCode(auth, oobCode)
        .then(() => {
          // Password reset code is valid
          setText('Enter your new password.');
        })
        .catch((error) => {
          // Handle error
          console.error('Invalid or expired reset code:', error);
          setText('Failed to verify reset code. If the issue persists, please reach out to support@brainraider.com.');
        });
    } else if (mode === 'verifyAndChangeEmail' && oobCode) {
        // Handle email address change
        checkActionCode(auth, oobCode)
          .then(() => {
            // Email address change verified
            setText('Email address updated successfully.');
          })
          .catch((error) => {
            console.error('Error verifying email address change:', error);
            setText('Failed to update email address. Please try again or reach out to support@brainraider.com.');
          });
      }
  }, [mode, oobCode]);

  const [wasReset, setWasReset] = useState(false);
  const handlePasswordReset = () => {
    confirmPasswordReset(auth, oobCode, password)
      .then(() => {
        setWasReset(true);
      })
      .catch((error) => {
        console.error('Error resetting password:', error);
      });
  };

  return (
    <SingleColumn>
        { (mode === 'verifyEmail' || mode === 'verifyAndChangeEmail') &&
            <EmptyBlock>{text}</EmptyBlock>
        }
        { mode === 'resetPassword' && (
            <>
                <PageHeaderText>Password Reset</PageHeaderText>
                <TextBlock>Please enter your new password.</TextBlock>
                <TextInput
                    type="password"
                    placeholder="Password"
                    value={password}
                    leftIcon='lock'
                    rightIcon='eye'
                    onChange={(e) => {setPassword(e); setPasswordError('')}}
                    error={passwordError}
                    password
                />
                <br />
                <Button confirm onClick={handlePasswordReset} disabled={wasReset}>{wasReset ? 'Success!' : 'Reset Password'}</Button>
            </>
        )}
    </SingleColumn>
  );
};

export default AccountManagement;
