import classes from './FlexToTiles.module.css';

const FlexToTiles = (props) => {
    return (
        <div className={ `${classes.wrapper} ${props.noflex ? classes.noflex : ''}` }>
                { props.children }
        </div>
    );
};

export default FlexToTiles;