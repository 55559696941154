import { useState, useEffect } from 'react';
import classes from './MultiselectInput.module.css';

const MultiselectInput = (props) => {
    const [selectedValues, setSelectedValues] = useState(props.selectedValues || []);

    const selectionChangeHandler = (event) => {
        const newSelectedValues = [...event.target.selectedOptions].map(option => option.value);
        setSelectedValues(newSelectedValues);
        props.onModified(newSelectedValues);
    };

    useEffect(() => {
        setSelectedValues(props.selectedValues || []);
    }, [props.selectedValues]);

    return(
        <div className={ classes.inputWrapper }>
            <select id={ props.name } value={selectedValues} className={ `${classes.selector} ${ props.nospace ? classes.noSpaceAfter : '' } ${props.short ? classes.short : ''}` } onChange={ selectionChangeHandler } multiple>
                {props.options?.map(option => <option key={option.value} value={option.value} >{option.text}</option>)}
            </select>
        </div>
    );
};

export default MultiselectInput;