import classes from './LeftColumn.module.css';

const LeftColumn = (props) => {
    return (
        <div className={`${classes.columnWrapper} ${props.stackable ? classes.stackable : ''}`}>
            { props.children }
        </div>
    );
};

export default LeftColumn;