/* import { MdOutlineDelete, MdOutlineEdit } from 'react-icons/md';
import { GoOrganization } from "react-icons/go"; */
import { FaUser } from "react-icons/fa";
import classes from './UsersTable.module.css';
import EmptyBlock from '../UI/EmptyBlock';
import { FaUserGroup } from "react-icons/fa6";
import { MdManageAccounts, MdSendToMobile } from "react-icons/md";
import { IoDocumentText } from "react-icons/io5";
import { FaChartLine, FaCameraRetro } from "react-icons/fa";

const UsersTable = (props) => {
    const firestoreTimestampToDate = (timestamp) => {
        if (timestamp && timestamp.seconds) {
            return new Date(timestamp.seconds * 1000 + (timestamp.nanoseconds / 1000000));
        }
        return null;
    };
    
    const formatDate = (date) => {
        const options = {
            weekday: 'long',  // Day of the week (e.g., Monday)
            year: 'numeric',  // Year (e.g., 2024)
            month: 'long',    // Month (e.g., July)
            day: 'numeric',   // Day of the month (e.g., 21)
            hour: '2-digit',  // Hour (e.g., 01 or 13)
            minute: '2-digit' // Minute (e.g., 00 or 59)
        };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    };
    
    const getSubStatus = (type, user_id) => {
        const user = props.teacherUsers[user_id];
        const trialExpiryDate = new Date(user.trial_expiry);
        const currentDate = new Date();
    
        if (type === "individual") {
            if (trialExpiryDate > currentDate) {
                return (
                    <span>
                        <span className={classes.trialStatus}>TRIAL</span> Free trial expires {formatDate(trialExpiryDate)} {user.trial_extensions ? `(${user.trial_extensions})` : ''}
                    </span>
                );
            } else if (user.sub_expiry) {
                const subExpiryDate = firestoreTimestampToDate(user.sub_expiry);
                if (subExpiryDate > currentDate) {
                    return (
                        <span>
                            <span className={classes.activeStatus}>ACTIVE</span> Subscription expires {subExpiryDate ? formatDate(subExpiryDate) : 'unknown'}
                        </span>
                    );
                } else {
                    return <span className={classes.expiredStatus}>EXPIRED</span>;
                }
            }
        }
        
        return "No active trial or subscription";
    };

    // Small component definitions
    const GroupIcon = ({ className, title }) => <FaUserGroup className={className} title={title} />;
    const StudentsIcon = ({ className, title }) => <MdManageAccounts className={className} title={title} />;
    const AssessmentIcon = ({ className, title }) => <IoDocumentText className={className} title={title} />;
    const AssignIcon = ({ className, title }) => <MdSendToMobile className={className} title={title} />;
    const MonitorIcon = ({ className, title }) => <FaCameraRetro className={className} title={title} />;
    const ResultIcon = ({ className, title }) => <FaChartLine className={className} title={title} />;


    // Component mapping
    const componentsMap = {
        group: GroupIcon,
        students: StudentsIcon,
        assessment: AssessmentIcon,
        assign: AssignIcon,
        monitor: MonitorIcon,
        result: ResultIcon,
    };

    const getIcons = (user) => {
        const actions = [
          { demo_key: "has_made_demo_group", demo_text: "Added a demo group", real_key: "has_made_real_group", real_text: "Added a real group", component: "group" },
          { demo_key: "has_added_individual_student", demo_text: "Added an individual student", real_key: "has_uploaded_csv", real_text: "Uploaded a CSV of student data", component: "students" },
          { demo_key: "has_viewed_assessment", demo_text: "Viewed an assessment", real_key: "has_viewed_assessment_detail", real_text: "Viewed an assessment in detail view", component: "assessment"},
          { demo_key: "has_assigned_demo_assessment", demo_text: "Assigned an assessment to demo students", real_key: "has_assigned_real_assessment", real_text: "Assigned an assessment to real students", component: "assign"},
          { demo_key: "has_started_demo_assessment", demo_text: "Started an assessment with demo students", real_key: "has_started_real_assessment", real_text: "Started an assessment with real students", component: "monitor"},
          { demo_key: "has_expanded_result", demo_text: "Viewed a result", real_key: "has_exported_result_csv", real_text: "Exported a result CSV", component: "result"},
        ];
      
        return actions.map(action => {
            const { demo_key, real_key, component, demo_text, real_text } = action;
            const IconComponent = componentsMap[component];
            if (user[demo_key] || user[real_key]) {
              const className = user[real_key] ? classes.iconReal : classes.iconDemo;
              const title = user[real_key] ? real_text : demo_text;
              return <IconComponent key={component} className={`${classes.icon} ${className}`} title={title} />;
            }
            return null;
          });
      };
    
    return(
        <>
            <table className={ classes.contentTable }>
                <thead>
                    <tr className={classes.headers}>
                        <th className={classes.header}>&nbsp;</th>
                        <th className={classes.header}>Name</th>
                        <th className={classes.header}>Email</th>
                        <th className={classes.flagsHeader}>Progress</th>
                        <th className={classes.header}>Subscription</th>
                        <th className={classes.header}>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    { props.teacherUsers &&
                        props.teacherUsers &&
                            Object.keys(props.teacherUsers).map((user, index) => (
                                <tr key={index}>
                                    <td className={classes.cell}><span className={classes.typeIcon}>
                                        <FaUser />
                                    </span></td>
                                    <td className={classes.cell}>{props.teacherUsers[user].first_name} {props.teacherUsers[user].last_name}</td>
                                    <td className={classes.cell}>{props.teacherUsers[user].email}</td>
                                    <td className={classes.cell}>{getIcons(props.teacherUsers[user])}</td>
                                    <td className={classes.smallText}>{getSubStatus("individual", user)}</td>
                                    <td className={`${classes.cell} ${classes.actionCell}`}>
                                        <button className={`${classes.action} ${classes.extend}`} onClick={props.onExtend.bind(null, user)}>Extend</button>
                                        <button className={`${classes.action} ${classes.impersonate}`} onClick={props.onImpersonate.bind(null, user)}>Impersonate</button>
                                    </td>
                                </tr>
                            ))
                    }
                </tbody>
            </table>
            { (!props.teacherUsers || Object.keys(props.teacherUsers)?.length === 0) && <EmptyBlock>No teacher accounts found.</EmptyBlock>}
        </>
    );
};

export default UsersTable;