import SingleColumn from '../components/UI/SingleColumn';
import PageHeaderText from '../components/UI/PageHeaderText';
import EmptyBlock from '../components/UI/EmptyBlock';
import { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { database } from '../util/firebase';
import {/*  push, child, set, remove, */ update, ref, onValue } from 'firebase/database';
import VerificationTable from '../components/Cards/VerificationTable';
import TextBlock from '../components/UI/TextBlock';

const VerificationPage = () => {
    const [setHeaderSize, authUserData] = useOutletContext();
    useEffect(() => {setHeaderSize('small');}, [setHeaderSize]);

    const [verificationQueue, setVerificationQueue] = useState(null);
    useEffect(() => {
        const unsubscribe = onValue(ref(database, `verification_queue`), (snapshot) => {setVerificationQueue(snapshot.val());});
        return () => unsubscribe();
    }, []);

    const onApprove = (request) => {
        // remove the unverified_teacher role from the user
        // add the verified_teacher role to the user
        // remove the request from the verification queue
        if(window.confirm('Are you sure you want to approve this user?')) {
            const updates = {}
            updates[`users/${request}/roles/unverified_teacher`] = {};
            updates[`users/${request}/roles/verified_teacher`] = true;
            updates[`verification_queue/${request}`] = {};
            updates[`user_accounts/${request}/verification_status`] = 'accepted';
            update(ref(database), updates);
        }
    }

    const onDeny = (request) => {
        // remove the unverified_teacher role from the user
        // add the rejected_verification role to the user
        // remove the request from the verification queue
        if(window.confirm('Are you sure you want to deny this user?')) {
            const updates = {}
            updates[`users/${request}/roles/unverified_teacher`] = {};
            updates[`users/${request}/roles/rejected_verification`] = true;
            updates[`verification_queue/${request}`] = {};
            updates[`user_accounts/${request}/verification_status`] = 'denied';
            update(ref(database), updates);
        }
    }

    return (
        <SingleColumn wide>
            { authUserData && ('admin' in authUserData?.roles) &&
                <>
                    <PageHeaderText>Verification Queue</PageHeaderText>
                    <TextBlock>Check the provided link and then approve or deny the user's request to become a verified teacher.</TextBlock>
                    <VerificationTable queue={verificationQueue} onApprove={onApprove} onDeny={onDeny} />
                    { !verificationQueue && <><br /><br /><EmptyBlock>You're all caught up!</EmptyBlock></>}
                </>
            }
            { (!authUserData || !('admin' in authUserData?.roles)) && <EmptyBlock>You are not authorized to view this resource.</EmptyBlock> }
        </SingleColumn>
    );
};

export default VerificationPage;