import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getDatabase } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';
import { getAuth, OAuthProvider } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyBiy1359wNoy4deXxaav4vKmV7P-z7_uiQ",
    authDomain: "brainraider.com",
    databaseURL: "https://basic-functional-default-rtdb.firebaseio.com",
    projectId: "basic-functional",
    storageBucket: "basic-functional.appspot.com",
    messagingSenderId: "566469491199",
    appId: "1:566469491199:web:754d3c9ae0cda6009b8fca",
    measurementId: "G-JPPXG5Q16W"
  };

const secondaryFirebaseConfig = {
    apiKey: "AIzaSyBiy1359wNoy4deXxaav4vKmV7P-z7_uiQ",
    authDomain: "basic-functional.firebaseapp.com",
    databaseURL: "https://basic-functional-default-rtdb.firebaseio.com"
}

const app = initializeApp(firebaseConfig);
const secondaryApp = initializeApp(secondaryFirebaseConfig, "Secondary");
const provider = new OAuthProvider('oidc.classlink');
provider.addScope('oneroster.read')
provider.addScope('access_token.read')

export const storage = getStorage(app);
export const database = getDatabase(app);
export const firestoreDb = getFirestore(app);
export const auth = getAuth(app);
export const secondaryAuth = getAuth(secondaryApp);
export const functions = getFunctions(app, 'us-central1');
export const analytics = getAnalytics(app);

export { provider };