import classes from './DragDropStudentCanvas.module.css';
import { useState, useEffect } from "react";
import DroppableAnchor from './DroppableAnchor';
import DraggableCard from './DraggableCard';
import { MdAnchor } from 'react-icons/md';

const DragDropStudentCanvas = (props) => {
    const [anchors, setAnchors] = useState([]);

    // const [hasLoadedAnswers, setHasLoadedAnswers] = useState(false);
    useEffect(() => {
        // if(hasLoadedAnswers) return;
        if(anchors.length > 1 && !props.static) return;

        // console.log('ImageCanvas received props:', props);
        const updatedAnchors = props.initialAnchors || [];
        setAnchors(updatedAnchors);
        props.onChangeAnswerRegions && props.onChangeAnswerRegions(updatedAnchors);
        // setHasLoadedAnswers(true);
        // eslint-disable-next-line
    }, [props.initialAnchors]);

    // TODO: Clean up unneccesary CSS still left from ImageCanvas
    return (
        <div className={classes.bigWrapper}>
            <div className={`${classes.wrapper} ${anchors.length > 0 && !props.static && !props.studentMode ? classes.hasResetButton : ''} ${props.static ? classes.static : ''} ${props.studentMode ? classes.studentMode : '' }`}>
                <img className={classes.image} src={props.imagesrc} alt="" />
                {
                    Object.keys(anchors)?.map(key => 
                        props.anchorAnswerPairs[key] === null ?
                        <div key={key} className={`${classes.anchorCircle} ${props.studentMode ? classes.studentMode : ''}`} style={{ top: `${anchors[key].y}%`, left: `${anchors[key].x}%`}}>
                            <DroppableAnchor id={key}>
                                {/* <div className={`${classes.anchorCircle} ${props.studentMode ? classes.studentMode : ''}`} style={{ top: `${anchors[key].y}%`, left: `${anchors[key].x}%`, backgroundColor: 'red'}}>{anchors[key].display_num}</div> */}
                                {/* anchors[key].display_num */}
                                {<MdAnchor />}
                            </DroppableAnchor>
                        </div>
                        :
                        <div key={props.anchorAnswerPairs[key]} className={classes.draggableContainer} style={{ top: `${anchors[key].y}%`, left: `${anchors[key].x}%`}}>
                            <DraggableCard id={props.anchorAnswerPairs[key]} key={props.anchorAnswerPairs[key]}>{props.capstoneData.question.answers[props.anchorAnswerPairs[key]]}</DraggableCard>
                        </div>
                        )
                }
            </div>
            {
                anchors.length > 0 && !props.static && !props.studentMode && 
                <button className={classes.resetButton} onClick={() => {setAnchors([]); props.onChangeAnchors([]);}}>Reset All Anchors</button>
            }
        </div>
    );
};

export default DragDropStudentCanvas;