import SingleColumn from '../components/UI/SingleColumn';
import { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { query, collection, where, getDocs, addDoc, onSnapshot  } from 'firebase/firestore';
import { firestoreDb } from '../util/firebase';
import EmptyBlock from '../components/UI/EmptyBlock';

// create a query object
const q = query(
    collection(firestoreDb, 'products'), 
    where('active', '==', true)
);

const querySnapshot = await getDocs(q);

// for each product, get the product price info
const productsPromises = querySnapshot.docs.map(async (productDoc) => {
    let productInfo = productDoc.data();

    // fetch prices subcollection per product
    const pricesCollection = collection(productDoc.ref, 'prices');
    const priceQuerySnapshot = await getDocs(pricesCollection);

    // assume there is only one price per product
    const priceDoc = priceQuerySnapshot.docs[0];
    // console.log(priceDoc);
    productInfo['priceId'] = priceDoc.id;
    productInfo['priceInfo'] = priceDoc.data();
    return productInfo;
});

// 'products' is an array of products (including price info)
const products = await Promise.all(productsPromises);

const CheckoutPage = (props) => {
    const [setHeaderSize, authUserData] = useOutletContext();
    useEffect(() => {setHeaderSize('small');}, [setHeaderSize]);

    useEffect(() => {
        if(!authUserData) return;

        const checkoutHandler = async () => {
            let checkoutSessionData = {
                price: products[0].priceId, // price ID from products fetch
                success_url: window.location.origin, // can set this to a custom page
                cancel_url: window.location.origin   // can set this to a custom page
            };
    
            try {
                const checkoutSessionRef = await addDoc(collection(firestoreDb, `customers/${authUserData.token}/checkout_sessions`), checkoutSessionData);
                console.log("Checkout session added with ID: ", checkoutSessionRef.id);
                // The Stripe extension creates a payment link for us
                onSnapshot(checkoutSessionRef, (snap) => {
                    const { error, url } = snap.data();
                    if (error) {
                        // handle error
                        console.error(error)
                    }
                    if (url) {
                        window.location.assign(url);  // redirect to payment link
                    }
              });
                } catch (error) {
                console.error("Error adding checkout session: ", error);
            }
        }

        const cameFromCheckoutPage = localStorage.getItem('cameFromBrainRaiderCheckoutPage');

        if(!cameFromCheckoutPage) {
            localStorage.setItem('cameFromBrainRaiderCheckoutPage', 'true');
            checkoutHandler();
        } else {
            localStorage.removeItem('cameFromBrainRaiderCheckoutPage');
        }

    }, [authUserData])

    return (
        <SingleColumn>
            <EmptyBlock>
                { authUserData ? 'Redirecting to payment processor...' : 'You are not authorized to view this resource.' }
            </EmptyBlock>
        </SingleColumn>
    );
}

export default CheckoutPage;