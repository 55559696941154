import classes from './FeedbackPopup.module.css';

const FeedbackPopup = (props) => {
    window.onkeydown = (key) => {
        if(key.keyCode === 27 && props.show) {
            props.onCancel();
        }
    };

    return (
        <>
            <div className={ `${ classes.wholeScreenWrapper } ${ props.show ? classes.show : '' }` }>
                <div className={ `${ classes.popupWrapper } ${ props.show ? classes.show : '' }` }>
                    <div className={ classes.titleWrapper}>
                        {props.title}
                    </div>
                    {props.children}
                </div>
                <div className={ `${ classes.screenOverlay } ${ props.show ? classes.show : '' }` } onClick={ props.onCancel }/>
            </div>
        </>
    )
};

export default FeedbackPopup;