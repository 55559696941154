import classes from './ReviewQuestionsPopup.module.css';
import CapstoneDetailed from '../Cards/CapstoneDetailed';
import { MdForward} from "react-icons/md";

const ReviewQuestionsPopup = (props) => {
    window.onkeydown = (key) => {
        if(key.keyCode === 27 && props.show) {
            props.onCancel();
        }
    };

    let capstoneData = {...props.questions[props.index].stimulus, questions: {}, stimuliKey: props.questions[props.index].stimulus._id};
    capstoneData.questions[props.questions[props.index].question._id] = {...props.questions[props.index].question}

    console.log(capstoneData)

    return (
        <>
            <div className={ `${ classes.wholeScreenWrapper } ${ props.show ? classes.show : '' }` }>
                <div className={ `${ classes.popupWrapper } ${ props.show ? classes.show : '' }` }>
                <div className={classes.text}>
                    <b>{props.text.prefix}:</b>&nbsp;
                    {props.text.text.charAt(0).toUpperCase() + props.text.text.slice(1)}
                </div>
                    <CapstoneDetailed
                        capstoneData={capstoneData}
                        showCorrect={true} preExpanded
                        noBorder
                        showLocalStats />
                        <div className={classes.buttonRow}>
                            <button className={`${classes.button} ${props.index === 0 ? classes.disabled : ''}`} onClick={props.decreaseIndex} disabled={props.index === 0}><MdForward className={classes.rotate} /></button>
                            <button className={`${classes.button} ${props.index >= props.questions.length - 1 ? classes.disabled : ''}`} onClick={props.increaseIndex} disabled={props.index >= props.questions.length - 1}><MdForward /></button>
                        </div>
                </div>
                <div className={ `${ classes.screenOverlay } ${ props.show ? classes.show : '' }` } onClick={ props.onCancel }/>
            </div>
        </>
    )
};

export default ReviewQuestionsPopup;