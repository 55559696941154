import reorderIcon from '../../images/reorder-icon.svg';
import addIcon from '../../images/add-icon.svg';
import classes from './TopRightButton.module.css';

const TopRightButton = (props) => {
    return (
        <button className={ classes.topRightButton } onClick={ props.onClick }>
            {props.icon === 'reorder' && <img src={ reorderIcon } className={ classes.icon } alt='Reorder'/>}
            {props.icon === 'add' && <img src={ addIcon } className={ classes.icon } alt='Add'/>}
        </button>
    );
};

export default TopRightButton;