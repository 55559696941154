import classes from './OrgsTable.module.css';
import EmptyBlock from '../UI/EmptyBlock';
import { FaBuilding } from "react-icons/fa";

const OrgsTable = (props) => {
    return(
        <>
            <table className={ classes.orgTable }>
                <thead>
                    <tr className={classes.headers}>
                        <th className={classes.headerIcon}>&nbsp;</th>
                        <th className={classes.header}>ID</th>
                        <th className={classes.header}>Name</th>
                        <th className={classes.header}>Users</th>
                        <th className={classes.header}>Contact</th>
                        <th className={classes.header}>Subscription</th>
                        <th className={classes.header}>Notes</th>
                        <th className={classes.header}>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    { props.rosteredOrgs &&
                            Object.keys(props.rosteredOrgs).map((org, index) => (
                                <tr key={index}>
                                    <td className={classes.cell}><span className={classes.typeIcon}>
                                        <FaBuilding />
                                    </span></td>
                                    <td className={classes.cell}>{org}</td>
                                    <td className={classes.cell}>{(props.orgAccounts && props.orgAccounts[org]?.org_name) ? props.orgAccounts[org]?.org_name : <>&nbsp;</>}</td>
                                    <td className={classes.cell}>
                                        {(props.orgAccounts && props.orgAccounts[org]?.teacher_count) ? <>{props.orgAccounts[org]?.teacher_count} Teachers</> : <></>}
                                        {(props.orgAccounts && props.orgAccounts[org]?.teacher_count && props.orgAccounts[org]?.student_count) ? <>, </> : <></>}
                                        {(props.orgAccounts && props.orgAccounts[org]?.student_count) ? <>{props.orgAccounts[org]?.student_count} Students</> : <></>}
                                        {(!props.orgAccounts || (!props.orgAccounts[org]?.teacher_count && !props.orgAccounts[org]?.student_count)) ? <>&nbsp;</> : <></>}
                                    </td>
                                    <td className={classes.cell}>{(props.orgAccounts && props.orgAccounts[org]?.contact_info) ? props.orgAccounts[org]?.contact_info : <>&nbsp;</>}</td>
                                    <td className={classes.cell}>{(props.orgAccounts && props.orgAccounts[org]?.sub_status && props.orgAccounts[org].sub_status === 'active') ? <span className={classes.activeStatus}>Active</span> : <span className={classes.inactiveStatus}>Inactive</span>}</td>
                                    <td className={classes.cell}>{(props.orgAccounts && props.orgAccounts[org]?.notes) ? props.orgAccounts[org]?.notes : <>&nbsp;</>}</td>
                                    <td className={`${classes.cell} ${classes.actionCell}`}>
                                        <button className={`${classes.action} ${classes.edit}`} onClick={props.onEdit.bind(null, org)}>Edit</button>
                                        <button className={`${classes.action} ${classes.toggle}`} onClick={props.onToggle.bind(null, org)}>Toggle</button>
                                    </td>
                                </tr>
                            ))
                    }
                </tbody>
            </table>
            { (!props.rosteredOrgs || Object.keys(props.rosteredOrgs)?.length === 0) && <EmptyBlock>No organization accounts found.</EmptyBlock>}
        </>
    )
};

export default OrgsTable;