import PageHeaderText from '../components/UI/PageHeaderText';
import ResultPreview from '../components/Cards/ResultPreview';
import { useState, useEffect } from 'react';
import { useOutletContext, useNavigate } from 'react-router-dom';
import { database } from '../util/firebase';
import { update, ref, get } from 'firebase/database';
import EmptyBlock from '../components/UI/EmptyBlock';
import { query, collection, where, getDocs } from 'firebase/firestore';
import { firestoreDb } from '../util/firebase';
import LeftColumn from '../components/UI/LeftColumn';
import RightColumn from '../components/UI/RightColumn';
import RevealBelow from '../components/UI/RevealBelow';
import TextBlock from '../components/UI/TextBlock';
import HideBelow from '../components/UI/HideBelow';
import RadioInput from '../components/UI/RadioInput';
import HorizontalLine from '../components/UI/HorizontalLine';
import CheckboxInput from '../components/UI/CheckboxInput';

// TODO: Consider adding teacher ID to RESULTS_DATA for access control purposes
const ResultsPage = () => {
    const [setHeaderSize, authUserData] = useOutletContext();
    useEffect(() => {setHeaderSize('small');}, [setHeaderSize]);
    const navigate = useNavigate();
    
    const [groupBy, setGroupBy] = useState('group_and_assessment'); 
    const [resultsData, setResultsData] = useState([]);
    const [includeFriends, setIncludeFriends] = useState(false);

    useEffect(() => {
        var groupsWithResults = []
        if (! authUserData?.monitoring_groups) {
            setResultsData(groupsWithResults);
            return;
        }
        const loadResultsAsync = async () => {
            if(groupBy === 'group_and_assessment') {
                Object.keys(authUserData.monitoring_groups).forEach((key) => {
                    if(authUserData.monitoring_groups[key].submission_count > 0 && (!authUserData.monitoring_groups[key].archived || authUserData.monitoring_groups[key].archived === false)) {
                        groupsWithResults.push({_id: key, ...authUserData.monitoring_groups[key]})
                    };
                });

                for(let i = 0; i < groupsWithResults.length; i++) {
                    if(groupsWithResults[i].feedback_generated_count !== groupsWithResults[i].submission_count) {
                        const updates = {}
                        updates[`users/${authUserData.token}/monitoring_groups/${groupsWithResults[i]._id}/feedback_needs_regeneration`] = true;
                        updates[`users/${authUserData.token}/monitoring_groups/${groupsWithResults[i]._id}/feedback_generated_count`] = groupsWithResults[i].submission_count;
                        if(!groupsWithResults[i].feedback_text || groupsWithResults[i].feedback_generated_count === 0) {
                            updates[`users/${authUserData.token}/monitoring_groups/${groupsWithResults[i]._id}/feedback_text`] = "Generating...";
                        } else {
                            updates[`users/${authUserData.token}/monitoring_groups/${groupsWithResults[i]._id}/feedback_text`] = "Regenerating...";
                        }
                        update(ref(database), updates);
                    }
                };
            } else if (groupBy === 'assessment') {
                const checkAssessmentId = (attemptsObj, assessmentId) => {
                    // Get an array of the keys (attempt1, attempt2, etc.)
                    const keys = Object.keys(attemptsObj);
                    
                    // Loop through the keys and find the index where assessment_id matches
                    for (let i = 0; i < keys.length; i++) {
                        if (attemptsObj[keys[i]].assessment_id === assessmentId) {
                            return i;  // Return the index of the matching key
                        }
                    }
                    
                    return -1;  // Return -1 if no match is found
                }
                
                Object.keys(authUserData.monitoring_groups).forEach((key) => {
                    if(authUserData.monitoring_groups[key].submission_count > 0) {
                        const index = checkAssessmentId(groupsWithResults, authUserData.monitoring_groups[key].assessment_id);
                        if(index !== -1) {
                            // combine relevant fields
                            if(authUserData.monitoring_groups[key].feedback_generated_count) groupsWithResults[index].feedback_generated_count += authUserData.monitoring_groups[key].feedback_generated_count;
                            if(authUserData.monitoring_groups[key].submission_count) groupsWithResults[index].submission_count += authUserData.monitoring_groups[key].submission_count;
                            if(authUserData.monitoring_groups[key].index > groupsWithResults[index].index) groupsWithResults[index].index = authUserData.monitoring_groups[key].index;
                            groupsWithResults[index].monitoring_group_ids.push(key);
                        } else {
                            groupsWithResults.push({monitoring_group_ids: [key], groupedBy: 'assessment', ...authUserData.monitoring_groups[key]})
                        }
                    };
                });

                if(includeFriends && authUserData.friends?.my_friends) {
                    for(const uid of Object.keys(authUserData.friends.my_friends)) {
                        const friend_monitoring_groups_ref = ref(database, `users/${uid}/monitoring_groups`);
                        const snapshot = await get(friend_monitoring_groups_ref)

                        if(snapshot.exists()) {
                            const friend_monitoring_groups = snapshot.val();
                            Object.keys(friend_monitoring_groups).forEach((key) => {
                                if(friend_monitoring_groups[key].submission_count > 0) {
                                    const index = checkAssessmentId(groupsWithResults, friend_monitoring_groups[key].assessment_id);
                                    if(index !== -1) {
                                        // combine relevant fields
                                        if(friend_monitoring_groups[key].feedback_generated_count) groupsWithResults[index].feedback_generated_count += friend_monitoring_groups[key].feedback_generated_count;
                                        if(friend_monitoring_groups[key].submission_count) groupsWithResults[index].submission_count += friend_monitoring_groups[key].submission_count;
                                        if(friend_monitoring_groups[key].index > groupsWithResults[index].index) groupsWithResults[index].index = friend_monitoring_groups[key].index;
                                        groupsWithResults[index].monitoring_group_ids.push(key);
                                        groupsWithResults[index].has_friend_results = true;
                                    }
                                };
                            });
                        }
                    }
                }
            } else if (groupBy === 'group') {
                const checkAssessmentGroup = (attemptsObj, groupId) => {
                    // Get an array of the keys (attempt1, attempt2, etc.)
                    const keys = Object.keys(attemptsObj);
                    
                    // Loop through the keys and find the index where assessment_id matches
                    for (let i = 0; i < keys.length; i++) {
                        if (attemptsObj[keys[i]].group_id === groupId) {
                            return i;  // Return the index of the matching key
                        }
                    }
                    
                    return -1;  // Return -1 if no match is found
                }
                
                Object.keys(authUserData.monitoring_groups).forEach((key) => {
                    if(authUserData.monitoring_groups[key].submission_count > 0) {
                        const index = checkAssessmentGroup(groupsWithResults, authUserData.monitoring_groups[key].group_id);
                        if(index !== -1) {
                            // combine relevant fields
                            if(authUserData.monitoring_groups[key].feedback_generated_count) groupsWithResults[index].feedback_generated_count += authUserData.monitoring_groups[key].feedback_generated_count;
                            if(authUserData.monitoring_groups[key].submission_count) groupsWithResults[index].submission_count += authUserData.monitoring_groups[key].submission_count;
                            if(authUserData.monitoring_groups[key].index > groupsWithResults[index].index) groupsWithResults[index].index = authUserData.monitoring_groups[key].index;
                            groupsWithResults[index].monitoring_group_ids.push(key);
                        } else {
                            groupsWithResults.push({monitoring_group_ids: [key], groupedBy: 'group', ...authUserData.monitoring_groups[key]})
                        }
                    };
                });

                if(includeFriends && authUserData.friends?.my_friends) {
                    for(const uid of Object.keys(authUserData.friends.my_friends)) {
                        const friend_monitoring_groups_ref = ref(database, `users/${uid}/monitoring_groups`);
                        const snapshot = await get(friend_monitoring_groups_ref)

                        if(snapshot.exists()) {
                            const friend_monitoring_groups = snapshot.val();
                            Object.keys(friend_monitoring_groups).forEach((key) => {
                                if(friend_monitoring_groups[key].submission_count > 0) {
                                    const index = checkAssessmentGroup(groupsWithResults, friend_monitoring_groups[key].group_id);
                                    if(index !== -1) {
                                        // combine relevant fields
                                        if(friend_monitoring_groups[key].feedback_generated_count) groupsWithResults[index].feedback_generated_count += friend_monitoring_groups[key].feedback_generated_count;
                                        if(friend_monitoring_groups[key].submission_count) groupsWithResults[index].submission_count += friend_monitoring_groups[key].submission_count;
                                        if(friend_monitoring_groups[key].index > groupsWithResults[index].index) groupsWithResults[index].index = friend_monitoring_groups[key].index;
                                        groupsWithResults[index].monitoring_group_ids.push(key);
                                        groupsWithResults[index].has_friend_results = true;
                                    }
                                };
                            });
                        }
                    }
                }
            }

            groupsWithResults.filter(result => !result.archived);
            setResultsData(groupsWithResults);
        }

        loadResultsAsync()
    }, [groupBy, authUserData?.monitoring_groups, authUserData?.token, includeFriends, authUserData?.friends?.my_friends]);

    const [subStatus, setSubStatus] = useState('loading')
    const [trialStatus, setTrialStatus] = useState('loading');
    const [remainingDays, setRemainingDays] = useState(1);
    // handle subscriptions
    useEffect(() => {
        if(!authUserData) return;

        const fetchSubData = async () => {
            // create a query object to the current users active subscriptions
            const q = query(
                // currentUser is provided by firebase, via getAuth().currentUser
                collection(firestoreDb, 'customers', authUserData.token, 'subscriptions'), 
                where('status', 'in', ['trialing', 'active'])
            );
            
            // fetch the active subscriptions
            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                const trialStatusRef = ref(database, `users/${authUserData.token}/trial_expiry`);
                const trailStatusInfo = await get(trialStatusRef)

                if (trailStatusInfo.exists()) {
                    const givenDateStr = trailStatusInfo.val();
                    const givenDate = new Date(givenDateStr);
                    const currentDate = new Date();

                    const hasPassed = currentDate > givenDate;
                    if(!hasPassed) {
                        setTrialStatus('active')
                    } else {
                        currentDate.setDate(currentDate.getDate() - 15);
                        const hasPassed = currentDate > givenDate;

                        const timeDifference = givenDate - currentDate;
                        const remainingDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

                        setTrialStatus(hasPassed ? null : 'expiring_soon')
                        setRemainingDays(remainingDays)
                    }
                }
            }

            // assuming user only has one active subscription max
            if(querySnapshot?.docs[0]?.data()) {
                setSubStatus('active');
            } else {
                setSubStatus(null);
            }
        }

        fetchSubData()
    }, [authUserData])

    const navigateToPricing = (event) => {
        event.preventDefault();
        navigate('/#pricing');
    };

    return (
        <div style={{ display: 'flex', minHeight: '100%', justifyContent: 'center', flexWrap: 'wrap'}}>
            { authUserData && !('student' in authUserData?.roles) && (subStatus === 'active' || trialStatus === 'active' || trialStatus === 'expiring_soon' || authUserData.classlink_paid) && 
                <>
                    <LeftColumn stackable>
                    { authUserData && !('student' in authUserData?.roles) && trialStatus === 'expiring_soon' && subStatus !== 'loading' && 
                        <EmptyBlock sideMargins noSpaceAfter>Warning! Your free trial has ended and you do not have an active subscription. You will lose access to this resource in {remainingDays} days. <span onClick={navigateToPricing} style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer'}}>Upgrade</span></EmptyBlock>
                    }
                        <PageHeaderText>Results</PageHeaderText>
                        <RevealBelow>
                        <TextBlock>
                            You can view <strong>Results</strong> in many different ways. Click any result to see a detailed breakdown.
                        </TextBlock>
                        <PageHeaderText small>View by...</PageHeaderText>
                        <RadioInput onChange={(val) => setGroupBy(val)} value={'group_and_assessment'} checked={groupBy === 'group_and_assessment'}>Group & Assessment</RadioInput>
                        <RadioInput onChange={(val) => setGroupBy(val)} value={'assessment'} checked={groupBy === 'assessment'} >Assessment</RadioInput>
                        <RadioInput onChange={(val) => setGroupBy(val)} value={'group'} checked={groupBy === 'group'}>Group</RadioInput>
                        <PageHeaderText small>Options</PageHeaderText>
                        <div style={{paddingLeft: '16px'}}><CheckboxInput checked={ includeFriends } onChange={ () => {setIncludeFriends(state => !state)} } last>Include friends' results in grouped views</CheckboxInput></div>
                        <HorizontalLine />
                        </RevealBelow>
                        { (resultsData.length === 0 || resultsData.length === 0) && <EmptyBlock>No results yet.</EmptyBlock> }
                        { resultsData.length > 0 && resultsData.sort((a, b) => b.index - a.index).map((result, index) => <ResultPreview result={result} key={result._id || result.monitoring_group_ids[0]} last={index === resultsData.length - 1} />) }
                    </LeftColumn>
                    <HideBelow>
                <RightColumn narrow stackable>
                    { authUserData && !('student' in authUserData?.roles) && trialStatus === 'expiring_soon' && subStatus !== 'loading' && 
                        <EmptyBlock sideMargins noSpaceAfter>&nbsp;<br />&nbsp;</EmptyBlock>
                    }
                    <br />
                    <br />
                    <TextBlock>
                        You can view <strong>Results</strong> in many different ways. Click any result to see a detailed breakdown of student performance.
                    </TextBlock>
                    <PageHeaderText small>View by...</PageHeaderText>
                    <RadioInput onChange={(val) => setGroupBy(val)} value={'group_and_assessment'} checked={groupBy === 'group_and_assessment'}>Group & Assessment</RadioInput>
                    <RadioInput onChange={(val) => setGroupBy(val)} value={'assessment'} checked={groupBy === 'assessment'} >Assessment</RadioInput>
                    <RadioInput onChange={(val) => setGroupBy(val)} value={'group'} checked={groupBy === 'group'} last>Group</RadioInput>
                    <PageHeaderText small>Options</PageHeaderText>
                    <div style={{paddingLeft: '16px'}}><CheckboxInput checked={ includeFriends } onChange={ () => {setIncludeFriends(state => !state)} } last>Include friends' results in grouped views</CheckboxInput></div>
                </RightColumn>
                </HideBelow>
                </>
            }
            { authUserData && !('student' in authUserData?.roles) && (subStatus !== 'active' && trialStatus !== 'active' && trialStatus !== 'expiring_soon' && subStatus !== 'loading' && !authUserData.classlink_paid ) && 
                <EmptyBlock sideMargins>Your free trial has expired and you do not have an active subscription. Please <span onClick={navigateToPricing} style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer'}}>upgrade</span> to access this resource.</EmptyBlock>
            }
            { (!authUserData || ('student' in authUserData?.roles)) &&
                <EmptyBlock>You are not authorized to view this resource.</EmptyBlock>
            }
        </div>
    );
}

export default ResultsPage;