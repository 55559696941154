import classes from './CSVUploadInput.module.css';
import StandaloneError from './StandaloneError.js';
import { useState } from 'react';
import { FaCloudUploadAlt } from 'react-icons/fa';
import TextBlock from './TextBlock.js';

const CSVUploadInput = (props) => {
    const [csvUpload, setCsvUpload] = useState(null);
    const [csvData, setCsvData] = useState([]);
    const [error, setError] = useState('');

    const uploadChangeHandler = (event) => {
        const newFile = event.target.files[0];

        // Check if the file exists and has the correct extension
        if (newFile && newFile.name.endsWith('.csv')) {
            setError('');
            // Handle CSV file
            const reader = new FileReader();
            reader.onload = (e) => {
                const contents = e.target.result;

                // Split contents into rows and remove '\r' from each row
                const rows = contents.split('\n').map(row => row.replace(/\r$/, ''));
                
                // Remove the last row if it is empty
                if (rows[rows.length - 1] === '') {
                    rows.pop(); // Remove the last empty row
                }
                
                const header = rows[0].split(',').map(col => col.trim()); // Assuming the first row contains column headers
                const data = rows.slice(1).map(row => {
                    const values = row.split(',');
                    const obj = {};
                    header.forEach((key, index) => {
                        obj[key] = values[index].trim();
                    });
                    return obj;
                });
                setCsvData(data);
            };
            reader.readAsText(newFile);

            setCsvUpload(newFile);
            props.onUpload(newFile);
        } else {
            // Display an error message or handle the case when a non-CSV file is selected
            setError('There was a problem with your file. Please check that you included the required columns and that you saved the file in .CSV format before trying again.');
        }
    };

    const resetCsvUpload = () => {
        setCsvUpload(null);
        props.onUpload(null);
        setError('');

        // Not sure why this is here. It was needed to clear the image properly?
        const inputElement = document.getElementById('csv-upload');
        if (inputElement) {
            inputElement.value = '';
        };
    };

    return (
        <>
            { csvUpload && csvData && csvData.length > 0 && csvData.some(obj => obj.first_name || obj.last_name || obj.email )&& (
                <div className={ classes.csvPreview }>
                    <TextBlock>Please check that all columns look correct in the preview below before finalizing your submission.</TextBlock>
                    <table className={ classes.previewTable }>
                        <thead>
                        <tr>
                            <th>
                                First Name
                            </th>
                            <th>
                                Last Name
                            </th>
                            <th>
                                Email
                            </th>
                            { csvData[0].password && 
                                <th>
                                    Password
                                </th>
                            }
                        </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {csvData[0]?.first_name}
                                </td>
                                <td>
                                    {csvData[0]?.last_name}
                                </td>
                                <td>
                                    {csvData[0]?.email}
                                </td>
                                { csvData[0]?.password && 
                                    <td>
                                        {csvData[0]?.password ? '******' : ''}
                                    </td>
                                }
                            </tr>
                            { csvData.length > 1 &&
                                <tr>
                                    <td>
                                        {csvData[1]?.first_name}
                                    </td>
                                    <td>
                                        {csvData[1]?.last_name}
                                    </td>
                                    <td>
                                        {csvData[1]?.email}
                                    </td>
                                    { csvData[0]?.password && 
                                        <td>
                                            {csvData[1]?.password ? '******' : ''}
                                        </td>
                                    }
                                </tr>
                            }
                            { csvData.length > 2 &&
                                <tr>
                                    <td>
                                        {csvData[2]?.first_name}
                                    </td>
                                    <td>
                                        {csvData[2]?.last_name}
                                    </td>
                                    <td>
                                        {csvData[2]?.email}
                                    </td>
                                    { csvData[0]?.password && 
                                        <td>
                                            {csvData[2]?.password ? '******' : ''}
                                        </td>
                                    }
                                </tr>
                            }
                            { csvData.length > 3 &&
                                <tr>
                                    <td>
                                        {csvData[3]?.first_name}
                                    </td>
                                    <td>
                                        {csvData[3]?.last_name}
                                    </td>
                                    <td>
                                        {csvData[3]?.email}
                                    </td>
                                    { csvData[0]?.password && 
                                        <td>
                                            {csvData[3]?.password ? '******' : ''}
                                        </td>
                                    }
                                </tr>
                            }
                            <tr>
                                <td className={ `${classes.noBottom}` }>
                                    &nbsp;
                                </td>
                                <td className={ `${classes.noBottom}` }>
                                    &nbsp;
                                </td>
                                <td className={ `${classes.noBottom}` }>
                                    &nbsp;
                                </td>
                                { csvData[0]?.password && 
                                    <td className={ `${classes.noBottom}` }>
                                        &nbsp;
                                    </td>
                                }
                            </tr>
                            
                        </tbody>
                    </table>
                    <br />
                    <button className={classes.resetButton} onClick={ resetCsvUpload }>Reset File Upload</button>
                </div>
            )}
        { !csvUpload &&
            <>
                <div className={ `${classes.csvUpload} ${ csvUpload ? classes.hasCsv : '' } ${ error ? classes.hasError : ''}` }>
                    <label
                        htmlFor='csv-upload'
                        className={ classes.csvUploadLabel }
                    >
                        <FaCloudUploadAlt className={ classes.icon } />
                        <p>Drag & Drop to Upload CSV File</p>
                    </label>
                    <input id='csv-upload' type="file" accept=".csv" className={classes.hidden} onChange={uploadChangeHandler} />
                </div>
                { error && <StandaloneError spaceafter>{error}</StandaloneError> }
            </>
        }
    </>
    );
};

export default CSVUploadInput;