import editIcon from '../../images/edit-icon.svg';
import editIconAlt from '../../images/edit-icon-alt.svg';
import mergeIcon from '../../images/merge-icon.svg';
import mergeIconDisabled from '../../images/merge-icon-disabled.svg';
import moveIcon from '../../images/move-icon.svg';
import moveIconDisabled from '../../images/move-icon-disabled.svg';
import archiveIcon from '../../images/archive-icon.svg';
import archiveIconAlt from '../../images/archive-icon-alt.svg';
import resetIcon from '../../images/reset-icon.svg';
import playIcon from '../../images/play-icon.svg';
import pauseIcon from '../../images/pause-icon.svg';
import endIcon from '../../images/end-icon.svg';
import classes from './InlineButton.module.css';

const InlineButton = (props) => {
    return (
        <button className={ `${ classes.buttonWrapper } ${ props.disabled ? classes.disabled : '' } ${ props.centered ? classes.centered : '' } ${ props.light ? classes.light : '' }` } onClick={ props.onClick } onFocus={ props.onFocus } disabled={ props.disabled }>
            { props.children }
            { props.icon === 'edit' && <img src={ editIcon } className={ classes.editIcon } alt='' />}
            { props.icon === 'editAlt' && <img src={ editIconAlt } className={ classes.editIcon } alt='' />}
            { props.icon === 'merge' && !props.disabled && <img src={ mergeIcon } className={ classes.mergeIcon } alt='' />}
            { props.icon === 'merge' && props.disabled && <img src={ mergeIconDisabled } className={ classes.mergeIcon } alt='' />}
            { props.icon === 'move' && !props.disabled && <img src={ moveIcon } className={ classes.moveIcon } alt='' />}
            { props.icon === 'move' && props.disabled && <img src={ moveIconDisabled } className={ classes.moveIcon } alt='' />}
            { props.icon === 'archive' && <img src={ archiveIcon } className={ classes.archiveIcon } alt='' />}
            { props.icon === 'archiveAlt' && <img src={ archiveIconAlt } className={ classes.archiveIcon } alt='' />}
            { props.icon === 'reset' && <img src={ resetIcon } className={ classes.resetIcon } alt='' />}
            { props.icon === 'play' && <img src={ playIcon } className={ classes.playIcon } alt='' />}
            { props.icon === 'pause' && <img src={ pauseIcon } className={ classes.pauseIcon } alt='' />}
            { props.icon === 'end' && <img src={ endIcon } className={ classes.endIcon } alt='' />}
        </button>
    );
};

export default InlineButton;