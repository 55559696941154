import classes from './SlidingScale.module.css';

const SlidingScale = (props) => {
  const handleChange = (event) => {
    props.onChange(event.target.value);
  };

  return (
    <>
        <div className={classes.wrapper}>
            <input 
                type="range"
                className={classes.input} 
                min={props.min || 0} 
                max={props.max || 100}
                step={props.onedecimal ? 0.1 : 1}
                value={props.value} 
                onChange={handleChange} 
            />
            <div className={classes.val}>{(props.value === props.max && props.maxlabel) ? props.maxlabel : props.value}</div>
        </div>
        { props.leftLabel && props.rightLabel &&
            <div className={classes.wrapper}>
                <div className={classes.leftLabel}>{props.leftLabel}</div>
                <div className={classes.rightLabel}>{props.rightLabel}</div>
            </div>
        }
    </>
  );
}

export default SlidingScale;