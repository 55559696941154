import classes from './HomepageBottomBar.module.css';
import PageHeaderText from '../UI/PageHeaderText';
import InlineButton from '../UI/InlineButton';
import { useNavigate } from 'react-router-dom';

const HomepageBottomBar = (props) => {
    const navigate = useNavigate();

    return (
        <>
            <div className={`${classes.wrapper} ${props.lowMargin ? classes.lowMargin : ''}`}>
                <PageHeaderText small>Business Information</PageHeaderText>
                <InlineButton onClick={ () => {navigate('/terms-of-service')} }>Terms of Service</InlineButton>
                <InlineButton onClick={ () => {navigate('/privacy-policy')} }>Privacy Policy</InlineButton>
                <InlineButton onClick={ () => {navigate('/childrens-privacy-policy')} }>Children's Privacy Policy</InlineButton>
                <div className={classes.copyright}>2024 © Brain Raider, LLC. All Rights Reserved.</div>
            </div>
            <div className={classes.zeroHeight}>
                <a href='/terms-of-service'>Terms of Service</a>
                <a href='/privacy-policy'>Privacy Policy</a>
                <a href='/childrens-privacy-policy'>Children's Privacy Policy</a>
            </div>
        </>
    );
};

export default HomepageBottomBar;