import classes from './Slider.module.css';

const Slider = (props) => {

    return (
        <div className={ `${classes.centeredWrapper} ${ props.fitted ? classes.fitted : '' }` }>
            <div className={ `${classes.sliderWrapper} ${ props.fitted ? classes.fitted : '' }` }>
                <button className={ `${classes.sliderButton} ${props.index === 0 ? classes.active : ''}  ${ props.fitted ? classes.fitted : '' }` } onClick={props.onFirstOption}>
                    {props.firstOption}
                    </button>
                <button className={ `${classes.sliderButton} ${props.index === 1 ? classes.active : ''}  ${ props.fitted ? classes.fitted : '' }` } onClick={props.onSecondOption}>
                    {props.secondOption}
                    </button>
            </div>
        </div>
    );
};

export default Slider;