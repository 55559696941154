import classes from './BottomRightButton.module.css';

const BottomRightButton = (props) => {
    return (
        <div className={ `${ classes.buttonRow } ${ props.bigSpace ? classes.bigSpace : '' }` }>
            <button className={ classes.button } onClick={ props.onClick }>
                { props.children }
            </button>
        </div>
    )
};

export default BottomRightButton;