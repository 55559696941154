import {  provider } from '../util/firebase';
import { getAuth, signInWithRedirect } from "firebase/auth";
import { useOutletContext } from 'react-router-dom';
import { useEffect } from 'react';
import SingleColumn from '../components/UI/SingleColumn';
import EmptyBlock from '../components/UI/EmptyBlock'
import InlineButton from '../components/UI/InlineButton';

const Classlink = () => {
    const [authUserData] = useOutletContext();
    useEffect(() => {
        const cameFromLaunchPage = localStorage.getItem('cameFromBrainRaiderLaunchPage');

        if(!cameFromLaunchPage && !authUserData.token) {
            localStorage.setItem('cameFromBrainRaiderLaunchPage', 'true');
            const auth = getAuth();
            signInWithRedirect(auth, provider).catch(error => {
                console.error('Error signing in with redirect:', error);
                localStorage.removeItem('cameFromBrainRaiderLaunchPage');
            });
        } else {
            localStorage.removeItem('cameFromBrainRaiderLaunchPage');
        }
        // Remove authUserData dependency and eslint ignore if auth is broken from adding it
    }, [authUserData])

    const classlinkSignInHandler = (event) => {
        event.preventDefault();
        const auth = getAuth();
        signInWithRedirect(auth, provider)
    };

    return(
        <SingleColumn>
            <EmptyBlock>
                Signing in with Classlink...
                <InlineButton centered onClick={ classlinkSignInHandler }>Click here if you are not automatically redirected</InlineButton>
            </EmptyBlock>
        </SingleColumn>
    );
};

export default Classlink;