import { useState } from 'react';
import mailIcon from '../../images/mail-icon.svg';
import lockIcon from '../../images/lock-icon.svg';
import eyeIcon from '../../images/eye-icon.svg';
import eyeIconAlt from '../../images/visibility-icon.svg';
import personIcon from '../../images/person-icon.svg';
import searchIcon from '../../images/search-icon.svg';
import bulletIcon from '../../images/bullet-icon.svg';
import errorBulletIcon from '../../images/error-bullet-icon.svg'
import classes from './TextInput.module.css';
import checkedBoxIcon from '../../images/checked-box-icon.svg';
import uncheckedBoxIcon from '../../images/unchecked-box-icon.svg';
import checkedRadioIcon from '../../images/checked-radio-icon.svg';
import uncheckedRadioIcon from '../../images/unchecked-radio-icon.svg';
import onePointIcon from '../../images/rubric-one-point.svg';
import onePointAltIcon from '../../images/rubric-one-point-alt.svg';
import webIcon from '../../images/web-icon.svg';
import titleIcon from '../../images/job_title_icon.svg';
import orgIcon from '../../images/org_icon.svg';

const TextInput = (props) => {
    const [hidden, setHidden] = useState(true);

    const textChangeHandler = (event) => {
        props.onChange(event.target.value);
    };

    const inputClasses = `${ classes.input }
        ${ props.nospaceafter ? classes.noSpaceAfter : '' }
        ${ props.nospacebefore ? classes.noSpaceBefore : '' }
        ${ props.leftIcon ? classes.hasIcon : '' }
        ${ props.half ? classes.half : '' }
        ${ props.error ? classes.hasError : '' }
        ${ props.leftIcon === 'bullet' || props.leftIcon === 'error-bullet' ? classes.indented : '' }
        ${ props.leftIcon === 'unchecked-box' || props.leftIcon === 'checked-box' || props.leftIcon === 'unchecked-radio' || props.leftIcon === 'checked-radio'? classes.indented : '' }
        ${ props.last ? classes.last : '' }
        ${ props.lightplaceholder ? classes.lightPlaceHolder : '' }
        ${ props.condensed ? classes.condensed : '' }
        ${ props.superCondensed ? classes.superCondensed : '' }
        ${ props.justPadded ? classes.justPadded : '' }`;

    return (
        <div className={ `${ classes.inputWrapper} ${ props.half ? classes.half : '' } ${ props.right ? classes.right : '' }` }>
            <input
                className={ inputClasses }
                id={ props.name }
                value={ props.value }
                onChange={ textChangeHandler }
                placeholder={ props.placeholder }
                type={ ( props.password && hidden ) ? 'password' : 'text' }
                disabled={ props.disabled }
            />
            { props.leftIcon === 'mail' && <img src={ mailIcon } className={ classes.mailIcon } alt='' /> }
            { props.leftIcon === 'org' && <img src={ orgIcon } className={ classes.orgIcon } alt='' /> }
            { props.leftIcon === 'title' && <img src={ titleIcon } className={ classes.titleIcon } alt='' /> }
            { props.leftIcon === 'lock' && <img src={ lockIcon } className={ classes.lockIcon } alt='' /> }
            { props.leftIcon === 'web' && <img src={ webIcon } className={ classes.webIcon } alt='' /> }
            { props.leftIcon === 'person' && <img src={ personIcon } className={ classes.personIcon } alt='' /> }
            { props.leftIcon === 'bullet' && <img src={ bulletIcon } className={ classes.bulletIcon } alt='' /> }
            { props.leftIcon === 'error-bullet' && <img src={ errorBulletIcon } className={ classes.errorBulletIcon } alt='' /> }
            { props.leftIcon === 'search' && <img src={ searchIcon } className={ `${classes.searchIcon} ${ props.nospacebefore ? classes.noSpaceBefore : '' }` } alt='' /> }
            { props.leftIcon === 'checked-box' && <img src={ checkedBoxIcon } className={ classes.boxIcon } alt='' onClick={ () => {if(!props.last) {props.onIconClick(props.index)}} } /> }
            { props.leftIcon === 'unchecked-box' && <img src={ uncheckedBoxIcon } className={ classes.boxIcon } alt='' onClick={ () => {if(!props.last) {props.onIconClick(props.index)}} } /> }
            { (props.leftIcon === 'checked-box' || props.leftIcon === 'unchecked-box' || props.leftIcon === 'checked-radio' || props.leftIcon === 'unchecked-radio') && props.error && <img src={ errorBulletIcon } className={ classes.extraErrorIcon } alt='' /> }
            { props.leftIcon === 'checked-radio' && <img src={ checkedRadioIcon } className={ classes.boxIcon } alt='' onClick={ () => {if(!props.last) {props.onIconClick(props.index)}} } /> }
            { props.leftIcon === 'unchecked-radio' && <img src={ uncheckedRadioIcon } className={ classes.boxIcon } alt='' onClick={ () => {if(!props.last) {props.onIconClick(props.index)}} } /> }
            { props.leftIcon === 'one_point' && <img src={ onePointIcon }  className={ classes.onePointIcon } alt='' />}
            { props.leftIcon === 'one_point_alt' && <img src={ onePointAltIcon }  className={ classes.onePointIcon } alt='' />}
            { props.rightIcon === 'eye' && hidden && <img src={ eyeIcon } className={ classes.eyeIcon } alt='' onClick={ () => setHidden((value) => !value) } /> }
            { props.rightIcon === 'eye' && !hidden && <img src={ eyeIconAlt } className={ classes.eyeIcon } alt='' onClick={ () => setHidden((value) => !value) } /> }
            { props.error && <div className={ `${classes.errorText} ${ props.nospaceafter ? classes.noSpaceAfter : '' }` }>{props.error}</div> }
        </div>
    )
};

export default TextInput;
