import classes from './TextBlock.module.css'

const TextBlock = (props) => {
    return(
        <div className={`${classes.textBlock} ${props.nospace ? classes.nospace : ''} ${props.bigspace ? classes.bigspace : ''} ${props.gray ? classes.gray : ''} ${props.lightPurple ? classes.lightPurple : ''} ${props.left ? classes.left : ''}`}>
            {props.children}
        </div>
    )
};

export default TextBlock;