import classes from './Button.module.css';

const Button = (props) => {
    const button_classes =
        `${ classes.buttonWrapper }
        ${ props.fitted ? classes.fitted : ''}
        ${ props.left ? classes.left : '' }
        ${ props.right ? classes.right : '' }
        ${ props.alt ? classes.alt : '' }
        ${ props.disabled ? classes.disabled : '' }
        ${ props.yellow ? classes.yellow : ''}
        ${ props.red ? classes.red : ''}
        ${ props.blue ? classes.blue : ''}
        ${ props.altblue ? classes.altblue : ''}
        ${ props.confirm ? classes.green : ''}`;
    
    return (
        <button className={ button_classes } onClick={ props.onClick } disabled={ props.disabled }>
            { props.children }
        </button>
    );
};

export default Button;