import classes from './MisconceptionPerformance.module.css';

const MisconceptionPerformance = (props) => {
    return(
        <ul className={classes.misconceptionList}>
            {
                props.majorMisconceptions.map((misconception, index) =>
                    <li className={`${classes.misconceptionBullet} ${index === props.majorMisconceptions.length - 1 ? classes.last : ''}`}
                    key={index}
                    onClick={() => props.onLaunch({ type: 'misconception', _id: misconception._id })}>
                        {100 - misconception.percent_correct}% of students think that {misconception._id}.
                    </li>)
            }
        </ul>
    )
};

export default MisconceptionPerformance;