import classes from './DraggableCard.module.css';
import { useDraggable } from '@dnd-kit/core';

const DraggableCard = (props) => {
    const {attributes, listeners, setNodeRef, transform} = useDraggable({
        id: props.id,
      });
      const style = transform ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      } : undefined;

    return (
        <div ref={setNodeRef} className={classes.draggableCard} style={style} {...listeners} {...attributes}>
            {props.children}
        </div>
    );
};

export default DraggableCard;