import classes from './QuoteCard.module.css';

const QuoteCard = ({ clientName, contactName, contactTitle, contactEmail, quoteDate, expiryDate, licenses, quote_num }) => {
    return (
        <div className={classes.quoteCard}>
          <div>
            <h2 style={{textAlign: 'right'}}>Official Quote #{quote_num}</h2>
            <p style={{margin: '0'}}><strong>Prepared By:</strong></p>
                <p style={{margin: '8px'}}>Brain Raider, LLC<br />28315 Long Mill Lane<br />Fulshear, TX 77441</p>
            <br />
            <p  style={{margin: '0'}}><strong>Prepared for:</strong></p>
            <p style={{margin: '8px'}}>{contactName} ({contactTitle})<br />{contactEmail}<br />{clientName}</p>
          </div>
          <br />
          <br />
          <div className={classes.quoteDetails}>
            <table style={{margin: '10px auto', width: 'calc(100% - 20px)', borderCollapse: 'collapse'}}>
                <thead>
                    <tr>
                        <th style={{backgroundColor: '#DDDDDD', border: '1px solid black', padding: '10px'}}>Description</th>
                        <th style={{backgroundColor: '#DDDDDD', border: '1px solid black', padding: '10px'}}>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{borderTop: '1px solid black', borderLeft: '1px solid black', borderRight: '1px solid black', padding: '10px', verticalAlign: 'top'}}>{licenses} Brain Raider student licenses (1 year) @ $2.00 per license</td>
                        <td style={{borderTop: '1px solid black', borderLeft: '1px solid black', borderRight: '1px solid black', padding: '10px', verticalAlign: 'top'}}>${licenses * 2}.00</td>
                    </tr>
                    <tr>
                        <td style={{borderBottom: '1px solid black', borderLeft: '1px solid black', borderRight: '1px solid black', padding: '10px', verticalAlign: 'top', height: '100px'}}>Unlimited Brain Raider teacher licenses (1 year)</td>
                        <td style={{borderBottom: '1px solid black', borderLeft: '1px solid black', borderRight: '1px solid black', padding: '10px', verticalAlign: 'top'}}>$0.00</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: 'right', padding: '10px', fontWeight: '700'}}>TOTAL</td>
                        <td style={{border: '1px solid black', padding: '10px', verticalAlign: 'top'}}>${licenses * 2}.00</td>
                    </tr>
                </tbody>
            </table>
          </div>
          <br />
          <br />
          <div className={classes.footer}>
            <p className={classes.questions}>This quotation was generated on {quoteDate} and is valid until {expiryDate}. If you have any questions concerning this quote or would like to complete your purchase, please contact <a href="mailto:eric@brainraider.com">eric@brainraider.com</a>.</p>
            <p style={{width: '100%', textAlign: 'center', fontWeight: '700', fontSize: '20px'}}><strong>THANK YOU FOR YOUR BUSINESS!</strong></p>
            <br />
          </div>
        </div>
      );
  };
  
  export default QuoteCard;