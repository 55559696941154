import classes from './StandardPerformance.module.css';
import { Fragment } from 'react';

const StandardPerformance = (props) => {
    const getStandardText = (key) => {
        for(const state in props.officialStandards) {
            for(const standard_set in props.officialStandards[state]) {
                for(const official_key in props.officialStandards[state][standard_set]) {
                    if(key === official_key) return props.officialStandards[state][standard_set][key];
                }
            }
        }

        return ''
    }

    const getBarColor = (percent) => {
        if (percent > 69) return '#089b08'; // Green
        if (percent >= 40) return '#ffd900'; // Yellow
        return '#f44336'; // Red
    };

    return(
        <>
            <div className={classes.gridWrapper}>
            {props.standards && props.standards.map(standard => (
                <Fragment key={standard._id}>
                    {/* Bar Row */}
                    <div className={classes.gridRow} onClick={() => props.onLaunch({ type: 'standard', _id: standard._id })}>
                        <div className={classes.gridLabel}>{standard._id}</div>
                        <div className={classes.gridBar} style={{ '--bar-size': `${Math.max(standard.percent_correct, 5)}%`, backgroundColor: getBarColor(standard.percent_correct) }}>{standard.percent_correct}%</div>
                    </div>
                    {/* Descriptor Row */}
                    <div className={classes.gridRow} onClick={() => props.onLaunch({ type: 'standard', _id: standard._id })}>
                        <div className={classes.gridLabel}></div> {/* Empty label column */}
                        <div className={classes.gridDescriptor}>{getStandardText(standard._id)}</div> {/* Descriptor column */}
                    </div>
                </Fragment>
            ))}
            </div>
        </>
    )
};

export default StandardPerformance;