import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useOutletContext, useNavigate } from 'react-router-dom';
import { query, collection, where, getDocs } from 'firebase/firestore';
import { firestoreDb } from '../util/firebase';
import EmptyBlock from '../components/UI/EmptyBlock';
import { database } from '../util/firebase';
import { ref, get, onValue, child } from 'firebase/database';
import PageHeaderText from '../components/UI/PageHeaderText';
import SingleColumn from '../components/UI/SingleColumn';
import { MdArrowBack } from 'react-icons/md';
import TextBlock from '../components/UI/TextBlock';
import StandardPerformance from '../components/Cards/StandardPerformance';
import SkillsPerformance from '../components/Cards/SkillsPerformance';
import ReviewQuestionsPopup from '../components/UI/ReviewQuestionsPopup';
import ExportBlock from '../components/Cards/ExportBlock';
import MisconceptionPerformance from '../components/Cards/MisconceptionPerformance';
import StudentScoresBlock from '../components/Cards/StudentScoresBlock';
import HorizontalLine from '../components/UI/HorizontalLine';

const ResultDetailPage = () => {
    const [setHeaderSize, authUserData] = useOutletContext();
    useEffect(() => {setHeaderSize('small');}, [setHeaderSize]);
    const location = useLocation();
    const navigate = useNavigate();

    const [monitoringGroups, setMonitoringGroups] = useState([]);
    useEffect(() => {
        // Parse the query parameters from the URL
        const searchParams = new URLSearchParams(location.search);
        const monitoringGroupsParam = searchParams.get('monitoring_groups');
        
        if (monitoringGroupsParam) {
            // Split the comma-separated string into an array
            const monitoringGroupsArray = monitoringGroupsParam.split(',');
            setMonitoringGroups(monitoringGroupsArray)
        }
    }, [location]);

    const [officialStandards, setOfficialStandards] = useState([]);
    useEffect(() => {
        const unsubscribe = onValue(ref(database, 'standards'), (snapshot) => {
            setOfficialStandards(snapshot.val());
        });

        return () => unsubscribe();
    }, [location]);

    const [subStatus, setSubStatus] = useState('loading')
    const [trialStatus, setTrialStatus] = useState('loading');
    const [remainingDays, setRemainingDays] = useState(1);
    // handle subscriptions
    useEffect(() => {
        if(!authUserData) return;

        const fetchSubData = async () => {
            // create a query object to the current users active subscriptions
            const q = query(
                // currentUser is provided by firebase, via getAuth().currentUser
                collection(firestoreDb, 'customers', authUserData.token, 'subscriptions'), 
                where('status', 'in', ['trialing', 'active'])
            );
            
            // fetch the active subscriptions
            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                const trialStatusRef = ref(database, `users/${authUserData.token}/trial_expiry`);
                const trailStatusInfo = await get(trialStatusRef)

                if (trailStatusInfo.exists()) {
                    const givenDateStr = trailStatusInfo.val();
                    const givenDate = new Date(givenDateStr);
                    const currentDate = new Date();

                    const hasPassed = currentDate > givenDate;
                    if(!hasPassed) {
                        setTrialStatus('active')
                    } else {
                        currentDate.setDate(currentDate.getDate() - 15);
                        const hasPassed = currentDate > givenDate;

                        const timeDifference = givenDate - currentDate;
                        const remainingDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

                        setTrialStatus(hasPassed ? null : 'expiring_soon')
                        setRemainingDays(remainingDays)
                    }
                }
            }

            // assuming user only has one active subscription max
            if(querySnapshot?.docs[0]?.data()) {
                setSubStatus('active');
            } else {
                setSubStatus(null);
            }
        }

        fetchSubData()
    }, [authUserData]);

    const navigateToPricing = (event) => {
        event.preventDefault();
        navigate('/#pricing');
    };

    const backButtonClickHandler = () => {
        navigate('/results')
    };

    const [resultsData, setResultsData] = useState([]);
    const listenToMonitoringGroupHandler = useCallback (() => {
        const unsubscribes = []
        let single_unsubscribe
        
        if(monitoringGroups.length === 1) {
            const monitoringGroupRef = ref(database, `monitoring_groups/${monitoringGroups[0]}`);
            single_unsubscribe = onValue(monitoringGroupRef, (snapshot) => {
                setResultsData(snapshot.val());
            });
        } else if (monitoringGroups.length > 1) {
            for(let i = 0; i < monitoringGroups.length; i++) {
                const monitoringGroupRef = ref(database, `monitoring_groups/${monitoringGroups[i]}`);
                const unsubscribe = onValue(monitoringGroupRef, (snapshot) => {
                    setResultsData(data => {
                        // first, update the invidual pieces of data
                        let newData = { results: {}, groupedBy: ''};
                        if(data && data.individual_data) newData.individual_data = {...data.individual_data}
                        else newData.individual_data = {}
                        newData.individual_data[monitoringGroups[i]] = {}
                        newData.individual_data[monitoringGroups[i]] = snapshot.val();

                        // then update the overall data and return it
                        const mergeResults = (targetTree, sourceTree) => {
                            Object.keys(sourceTree).forEach(key => {
                                if (typeof sourceTree[key] === 'object' && !Array.isArray(sourceTree[key])) {
                                    if (!targetTree[key]) {
                                        targetTree[key] = {};  // Initialize the target key if not present
                                    }
                                    // Recursively merge objects
                                    mergeResults(targetTree[key], sourceTree[key]);
                                } else if (key === 'correct_count' || key === 'total_count') {
                                    // If it's a count, add the values (initialize if not present)
                                    targetTree[key] = (targetTree[key] || 0) + sourceTree[key];
                                }
                            });
                        };
                    
                        // Now merge all the individual results into newData.results
                        Object.values(newData.individual_data).forEach(individualData => {
                            if (individualData && individualData.results) {
                                // Safely merge if results exist
                                mergeResults(newData.results, individualData.results);
                            }
                        });

                        // Collect all assessment_ids and group_ids to compare
                        let assessmentIds = [];
                        let groupIds = [];

                        Object.values(newData.individual_data).forEach(individualData => {
                            if (individualData.assessment.assessment_id) {
                                assessmentIds.push(individualData.assessment.assessment_id);
                            }
                            if (individualData.group.group_id) {
                                groupIds.push(individualData.group.group_id);
                            }
                        });

                        const allSameAssessment = assessmentIds.every(id => id === assessmentIds[0]);
                        const allSameGroup = groupIds.every(id => id === groupIds[0]);

                        if (allSameAssessment) {
                            newData.groupedBy = 'assessment';
                            newData.assessment_name = newData.individual_data[monitoringGroups[0]].assessment.name;
                        } else if (allSameGroup) {
                            newData.groupedBy = 'group';
                            newData.group_name = newData.individual_data[monitoringGroups[0]].group.name;
                        } else {
                            newData.groupedBy = 'mixed';  // If neither is true
                        }

                        return newData;
                    });
                });
                unsubscribes.push(unsubscribe);
            }
        }

        return () => {
            if(single_unsubscribe) single_unsubscribe()
            else if (unsubscribes.length > 0) unsubscribes.forEach(unsub => unsub());
        };
    }, [monitoringGroups]);
    

    useEffect(() => {
        if(monitoringGroups.length > 0) {
            listenToMonitoringGroupHandler();
        }
    }, [listenToMonitoringGroupHandler, monitoringGroups]);

    let conceptsAndSkills = [];
    /* if(resultData?.results?.concepts) {
        for(const key in resultData.results.concepts) {
            conceptsAndSkills.push({ _id: key, type: 'concept', percent_correct: Math.round(resultData.results.concepts[key].correct_count / resultData.results.concepts[key].total_count * 100)})
        }
    } */
    if(resultsData?.results?.skills) {
        for(const key in resultsData.results.skills) {
            conceptsAndSkills.push({ _id: key, type: 'skill', percent_correct: Math.round(resultsData.results.skills[key].correct_count / resultsData.results.skills[key].total_count * 100)})
        }
    }
    conceptsAndSkills.sort((a, b) => b.percent_correct - a.percent_correct);

    let standards = [];
    if(resultsData?.results?.standards) {
        for(const key in resultsData.results.standards) {
            standards.push({ _id: key, type: 'standard', percent_correct: Math.round(resultsData.results.standards[key].correct_count / resultsData.results.standards[key].total_count * 100)})
        }
    }
    standards.sort((a, b) => b.percent_correct - a.percent_correct);

    let bestStandards = [];
    let worstStandards = [];
    if(standards.length > 1 && standards.length <= 3) {
        bestStandards.push(standards[0]);
        worstStandards.push(standards[standards.length - 1]);
    }
    else if(standards.length > 3) {
        bestStandards.push(standards[0]);
        bestStandards.push(standards[1]);
        worstStandards.push(standards[standards.length - 2]);
        worstStandards.push(standards[standards.length - 1]);
    }

    let majorMisconceptions = [];
    if(resultsData?.results?.misconceptions) {
        for(const key in resultsData.results.misconceptions) {
            if(Math.round(resultsData.results.misconceptions[key].correct_count / resultsData.results.misconceptions[key].total_count * 100) < 51) {
                majorMisconceptions.push({ _id: key, type: 'misconceptions', percent_correct: Math.round(resultsData.results.misconceptions[key].correct_count / resultsData.results.misconceptions[key].total_count * 100)})
            }
        }
        majorMisconceptions.sort((a, b) => a.percent_correct - b.percent_correct);
        majorMisconceptions = majorMisconceptions.slice(0, 3); // Trim to the first 3 values
    }

    let custom_text = 'Generating...'
    if(conceptsAndSkills?.length > 1) {
        const length = conceptsAndSkills.length;
        custom_text = `Your students showed strengths in "${conceptsAndSkills[0]?._id}"${length > 3 ? ` and "${conceptsAndSkills[1]?._id}"`:''}. However, they struggled with "${conceptsAndSkills[length - 1]._id}" and could use remediation in this area.`
    } else {
        console.log(resultsData)
    }

    const [questionData, setQuestionData] = useState({})
    useEffect(() => {
        const getData = async () => {
            if(!resultsData) return;
            if(Object.keys(resultsData)?.length === 0) return;

            let newQuestionData = {};

            if(!resultsData.groupedBy){
                const local_stats = resultsData.local_stats;
                for(const stimuli_key in local_stats) {
                    if(!newQuestionData[stimuli_key]) {
                        const stimuliDataSnapshot = await get(child(ref(database), `stimuli/${stimuli_key}`));
                        const stimuliData = stimuliDataSnapshot.val();
                        newQuestionData[stimuli_key] = {image: stimuliData.image || '', text: stimuliData.text || '', standards: stimuliData.standards || {}, questions: stimuliData.questions || {}};
                    }

                    for(const question_key in local_stats[stimuli_key]) {
                        if(!newQuestionData[stimuli_key].questions[question_key]) newQuestionData[stimuli_key].questions[question_key] = {}
                        newQuestionData[stimuli_key].questions[question_key].local_stats = local_stats[stimuli_key][question_key];
                    }
                }
            } else {
                for(const data_group in resultsData.individual_data) {
                    const local_stats = resultsData.individual_data[data_group].local_stats;
                    for(const stimuli_key in local_stats) {
                        if(!newQuestionData[stimuli_key]) {
                            const stimuliDataSnapshot = await get(child(ref(database), `stimuli/${stimuli_key}`));
                            const stimuliData = stimuliDataSnapshot.val();
                            newQuestionData[stimuli_key] = {image: stimuliData.image || '', text: stimuliData.text || '', standards: stimuliData.standards || {}, questions: stimuliData.questions || {}};
                        }
    
                        for(const question_key in local_stats[stimuli_key]) {
                            if(!newQuestionData[stimuli_key].questions[question_key]) newQuestionData[stimuli_key].questions[question_key] = {}
                            newQuestionData[stimuli_key].questions[question_key].local_stats = local_stats[stimuli_key][question_key];
                        }
                    }
                }
            }
            
            setQuestionData(newQuestionData);
        }

        getData();
    }, [resultsData])

    const [displayQuestions, setDisplayQuestions] = useState([])
    const [displayIndex, setDisplayIndex] = useState(0);
    const [displayText, setDisplayText] = useState({prefix: '', text: ''})
    const launchQuestions = (obj) => {
        let questionsArray = [];

        // get an object with only the questions aligned to the topic launched
        if(obj.type === 'skill') {
            setDisplayText({prefix: 'Skill', text: obj._id})
            for(const stimuli_key in questionData) {
                for(const question_key in questionData[stimuli_key].questions) {
                    if(!questionData[stimuli_key].questions[question_key].is_capstone && questionData[stimuli_key].questions[question_key].archived !== true && questionData[stimuli_key].questions[question_key].feedback?.skill === obj._id) {
                        questionsArray.push({
                            stimulus: { image: questionData[stimuli_key].image, text: questionData[stimuli_key].text, _id: stimuli_key },
                            question: {...questionData[stimuli_key].questions[question_key], _id: question_key },
                            local_stats: {...questionData[stimuli_key].questions[question_key].local_stats}
                        })
                    }
                }
            }
        } else if (obj.type === 'misconception') {
            for(const stimuli_key in questionData) {
                setDisplayText({prefix: 'Misconception', text: obj._id})
                for(const question_key in questionData[stimuli_key].questions) {
                    if(!questionData[stimuli_key].questions[question_key].is_capstone && questionData[stimuli_key].questions[question_key].archived !== true && questionData[stimuli_key].questions[question_key].feedback?.misconception === obj._id) {
                        questionsArray.push({
                            stimulus: { image: questionData[stimuli_key].image, text: questionData[stimuli_key].text, _id: stimuli_key },
                            question: {...questionData[stimuli_key].questions[question_key], _id: question_key },
                            local_stats: {...questionData[stimuli_key].questions[question_key].local_stats}
                        })
                    }
                }
            }
        } else if (obj.type === 'standard') {
            let standardText = ''
            for(const stimuli_key in questionData) {
                for(const question_key in questionData[stimuli_key].questions) {
                    if(questionData[stimuli_key].questions[question_key].is_capstone && questionData[stimuli_key].questions[question_key].archived !== true) {
                        for(const state in questionData[stimuli_key].standards) {
                            for(const standard_group in questionData[stimuli_key].standards[state]) {
                                for(const standard_code in questionData[stimuli_key].standards[state][standard_group]) {
                                    // console.log(`code: ${standard_code}, obj._id: ${obj._id}, match: ${standard_code === obj._id}`)
                                    if(standard_code === obj._id) {
                                        standardText = questionData[stimuli_key].standards[state][standard_group][standard_code]
                                        questionsArray.push({
                                            stimulus: { image: questionData[stimuli_key].image, text: questionData[stimuli_key].text, _id: stimuli_key },
                                            question: {...questionData[stimuli_key].questions[question_key], _id: question_key },
                                            local_stats: {...questionData[stimuli_key].questions[question_key].local_stats}
                                        })
                                    }
                                }
                            }
                        }
                    }
                }
            }
            setDisplayText({prefix: obj._id, text: standardText})
        }

        setDisplayQuestions(questionsArray);
        setDisplayIndex(0);
    }

    /* console.log(resultsData) */

    return(
        <div>
            { authUserData && !('student' in authUserData?.roles) && (subStatus === 'active' || trialStatus === 'active' || trialStatus === 'expiring_soon' || authUserData.classlink_paid) && 
                <SingleColumn>
                    { authUserData && !('student' in authUserData?.roles) && trialStatus === 'expiring_soon' && subStatus !== 'loading' && 
                        <EmptyBlock sideMargins noSpaceAfter>Warning! Your free trial has ended and you do not have an active subscription. You will lose access to this resource in {remainingDays} days. <span onClick={navigateToPricing} style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer'}}>Upgrade</span></EmptyBlock>
                    }
                    <PageHeaderText tight>
                        <div style={{padding: '6px 6px 0px 0px'}}><MdArrowBack onClick={backButtonClickHandler} style={{cursor: 'pointer'}}/></div>
                        {monitoringGroups.length === 1 && `${resultsData?.assessment?.name} (${resultsData?.group?.name})` }
                        {monitoringGroups.length > 1 && resultsData.groupedBy === 'assessment' && `${resultsData?.assessment_name}` }
                        {monitoringGroups.length > 1 && resultsData.groupedBy === 'group' && `${resultsData?.group_name}` }
                    </PageHeaderText>
                    <TextBlock>{custom_text}</TextBlock>
                    <HorizontalLine />
                    <TextBlock><div style={{color: '#8184FF', fontWeight: '700', textAlign: 'center'}}>(Click any standard, skill, or misconception to see specific questions)</div></TextBlock>
                    <PageHeaderText small>Performance by Standard</PageHeaderText>
                    <StandardPerformance standards={standards} officialStandards={officialStandards} onLaunch={launchQuestions} />
                    <br />
                    {
                        conceptsAndSkills.length > 0 &&
                        <>
                            <PageHeaderText small>Skill Mastery Ranking</PageHeaderText>
                            <TextBlock>There are many individual skills that go into mastering each {standards.length > 1 ? 'top-level standard' : 'concept' }. Check out your students' specific strengths and weaknesses below:</TextBlock>
                            <SkillsPerformance conceptsAndSkills={conceptsAndSkills} onLaunch={launchQuestions} />
                            <br />
                        </>
                    }
                    {
                        majorMisconceptions?.length > 0 &&
                        <>
                            <PageHeaderText small>Major Misconception{majorMisconceptions.length > 1 ? 's' : ''}</PageHeaderText>
                            <TextBlock>Your students appear to have the following misconception{majorMisconceptions.length > 1 ? 's' : '' }:</TextBlock>
                            <MisconceptionPerformance majorMisconceptions={majorMisconceptions} onLaunch={launchQuestions} />
                            <br />
                        </>
                    }
                    { !resultsData.groupedBy ?
                        <>
                            <PageHeaderText small>Individual Student Results</PageHeaderText>
                            <TextBlock>Click any student's score to view their responses.</TextBlock>
                            <StudentScoresBlock attempts={resultsData?.attempts} />
                            <br />
                            <PageHeaderText small>Export Options</PageHeaderText>
                            <ExportBlock resultData={resultsData} token={authUserData?.token}/>
                            <br /><br /><br />
                        </> :
                        <>
                            <PageHeaderText small>Individual Results & Export Options</PageHeaderText>
                            <TextBlock><div style={{textAlign: 'center', width: '100%', color: '#C8C8C8'}}><br />Individual results and export options are disabled for grouped data views.<br /><br /><br /></div></TextBlock>       
                        </>
                    }
                    {displayQuestions?.length > 0 && <ReviewQuestionsPopup show text={displayText} onCancel={() => setDisplayQuestions([])} index={displayIndex} questions={displayQuestions} increaseIndex={() => {setDisplayIndex(val => val + 1)}} decreaseIndex={() => {setDisplayIndex(val => val - 1)}}/>}
                </SingleColumn>
            }
            { authUserData && !('student' in authUserData?.roles) && (subStatus !== 'active' && trialStatus !== 'active' && trialStatus !== 'expiring_soon' && subStatus !== 'loading' && !authUserData.classlink_paid ) && 
                <EmptyBlock sideMargins>Your free trial has expired and you do not have an active subscription. Please <span onClick={navigateToPricing} style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer'}}>upgrade</span> to access this resource.</EmptyBlock>
            }
            { (!authUserData || ('student' in authUserData?.roles)) &&
                <EmptyBlock>You are not authorized to view this resource.</EmptyBlock>
            }
        </div>
    );
};

export default ResultDetailPage;