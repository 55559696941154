import SingleColumn from '../components/UI/SingleColumn';
import { useState, useEffect } from 'react';
import { useOutletContext, useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth, provider } from '../util/firebase';
import PageHeaderText from '../components/UI/PageHeaderText';
import TextInput from '../components/UI/TextInput';
import Button from '../components/UI/Button';
import HorizontalLine from '../components/UI/HorizontalLine';
import BottomRightButton from '../components/UI/BottomRightButton';
import classlinkLogo from '../images/classlink-logo-invert.svg';
import { getAuth, signInWithRedirect } from "firebase/auth";

// TODO: Display feedback for various authentication errors.
const LoginPage = () => {
    const [setHeaderSize, authUserData] = useOutletContext();
    useEffect(() => {setHeaderSize('large');}, [setHeaderSize]);

    const navigate = useNavigate();
    useEffect(() => {
        if (authUserData?.roles) {
          if ('student' in authUserData.roles) {
            navigate('/dashboard');
          } else {
            navigate('/assessments');
          }
        }
      }, [authUserData, navigate]);

    const navigateToSignupHandler = () => {
        navigate('/signup');
    };
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [isLoggingIn, setIsLoggingIn] = useState(false);

    const loginHandler = (event) => {
        event.preventDefault();

        var errorCounter = 0;
        if(email === '') {
            errorCounter++;
            setEmailError('This field is required.');
        };
        if(password === '') {
            errorCounter++
            setPasswordError('This field is required.');
        }
        if(errorCounter > 0) {return;}

        setIsLoggingIn(true);
        signInWithEmailAndPassword(auth, email, password)
            .then(() => {
            }).catch((error) => {
                setIsLoggingIn(false);
                // TODO: Does this previous logic work anymore with email enumeration turned on?
                if(error.message === 'Firebase: Error (auth/user-not-found).') {setEmailError('User not found.'); setPasswordError('');}
                else if(error.message === 'Firebase: Error (auth/invalid-email).') {setEmailError('Invalid email address.'); setPasswordError('');}
                else if(error.message === 'Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests).') {setEmailError('Too many login attempts - reset your password.'); setPasswordError('');}
                else if(error.message === 'Firebase: Error (auth/wrong-password).') {setEmailError(''); setPasswordError('Invalid password.');}
                else {setEmailError('Invalid username and/or password.'); setPasswordError('');}
            });
    };

    const resetPasswordHandler = (event) => {
        event.preventDefault();
        navigate('/password-reset')
    }

    const classlinkSignInHandler = (event) => {
        event.preventDefault();
        const auth = getAuth();
        signInWithRedirect(auth, provider)
    };

    /* useEffect(() => {
        if(!auth) return;

        getRedirectResult()
        .then((result) => {
            if(!result) {
                console.log('No redirect result');
                return;
            }

            if (result.credential) {
                // This gives you the OAuth access token.
                const credential = result.credential;
                console.log('Login/Credential: ', credential)
                const accessToken = credential.accessToken;
                console.log("Login/AccessToken: ", accessToken);
          
                // Use the access token to access OneRoster data
                getOneRosterData(accessToken);
            } else {
                console.error('There was a problem with the redirect.')
            }
        })
    }, [auth]) */

    /* const getOneRosterData = async (accessToken) => {
        try {
            const response = await fetch('https://nodeapi.classlink.com/v2/my/info', {
                headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
                }
            });
        
            if (!response.ok) {
                throw new Error('Network response was not ok: ' + response.statusText);
            }
        
            const data = await response.json();
            console.log("OneRoster Data: ", data);
          return data;
        } catch (error) {
            console.error("Error fetching OneRoster data: ", error);
        }
    }; */

    return (
        <SingleColumn>
            <div>
                <PageHeaderText>Log In</PageHeaderText>
                <form onSubmit= { loginHandler }>
                    <TextInput name='user-email' value={ email } placeholder='Email' leftIcon='mail' error={ emailError } onChange={ (value) => {setEmail(value); setEmailError('')} } />
                    <TextInput name='user-password' value={ password } placeholder='Password' leftIcon='lock' rightIcon='eye' error={ passwordError } onChange={ (value) => {setPassword(value); setPasswordError('');} } nospaceafter password />
                    <input type="submit" style={{display: 'none'}} />
                    <BottomRightButton onClick={ resetPasswordHandler }>Forgot Password?</BottomRightButton>
                    <Button blue onClick={ loginHandler } disabled={ isLoggingIn }>{ isLoggingIn ? 'Logging in...' : 'Log In' }</Button>
                </form>
                <HorizontalLine>or</HorizontalLine>
                <Button altblue onClick={ classlinkSignInHandler }><img src={classlinkLogo} alt='' style={{height: '18px', marginBottom: '-3px'}}/>&nbsp;&nbsp;Log in with ClassLink</Button>
                <BottomRightButton onClick={ navigateToSignupHandler } bigSpace>Looking for teacher sign-up?</BottomRightButton>
            </div>
        </SingleColumn>
    );
};

export default LoginPage;