import classes from './PageHeaderText.module.css';

const PageHeaderText = (props) => {
    return (
        <div className={ `${classes.headerTextWrapper} ${ props.small ? classes.small : '' } ${ props.floatLeft ? classes.left : '' } ${props.right ? classes.right : ''} ${props.tight ? classes.tight : ''}` }>
            { !props.labelFor && props.children }
            { props.labelFor && 
                <label htmlFor={props.labelFor}>{ props.children }</label>
            }
        </div>
    );
};

export default PageHeaderText;