import assessmentToDoArt from '../../images/assessment-todo-art.svg';
import classes from './AssessmentToDo.module.css';
import { BsFillPlayFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

const AssessmentToDo = (props) => {
    const navigate = useNavigate();
    const navigateToAttemptHandler = () => {
        navigate(`/attempts/${props.attempt._id}`);
    };

    return (
        <div className={ `${ classes.assessmentWrapper } ${ props.last ? classes.last : '' }` }>
            <button className={ `${classes.startButton} ${ props.attempt.status === 'queued' || props.attempt.status === 'paused' || props.attempt.status === 'ready' ? classes.disabled : '' }` } onClick={ navigateToAttemptHandler } disabled={props.attempt.status === 'queued' || props.attempt.status === 'paused' || props.attempt.status === 'ready'}>
                <div className={ classes.centerWrapper}>
                    { props.attempt.status === 'queued' && <>Preparing...</> }
                    { props.attempt.status === 'ready' && <>Ready</> }
                    { props.attempt.status === 'available' && <>Start Now&nbsp;<BsFillPlayFill /></>}
                    { props.attempt.status === 'in_progress' && <>Resume&nbsp;<BsFillPlayFill /></>}
                    { props.attempt.status === 'paused' && <>Paused</>}
                </div>
            </button>
            <div className={ classes.assessmentName }>{ props.attempt.name }</div>
            <div className={ classes.assessmentDescription }>{ props.attempt.description || '' }</div>
            <img src={ assessmentToDoArt } alt='' className={ classes.assessmentArt } />
        </div>
    );
};

export default AssessmentToDo;