import QuoteCard from "../components/Cards/QuoteCard";
import Button from "../components/UI/Button";
import HorizontalLine from "../components/UI/HorizontalLine";
import PageHeaderText from "../components/UI/PageHeaderText";
import SingleColumn from "../components/UI/SingleColumn";
import TextBlock from "../components/UI/TextBlock";
import TextInput from "../components/UI/TextInput";
import { useState, useRef, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { database } from '../util/firebase';
import { ref, get, child, update, increment } from 'firebase/database';

const QuoteGenerator = () => {
    const [setHeaderSize, authUserData] = useOutletContext();
    useEffect(() => {setHeaderSize('small');}, [setHeaderSize]);
    const [formDetails, setFormDetails] = useState({ org_name: '', contact_name: '', contact_title: '', contact_email: '', num_students: 0});
    const [errorDetails, setErrorDetails] = useState({ org_name: '', contact_name: '', contact_title: '', contact_email: '', num_students: ''});
    const [generatedData, setGeneratedData] = useState({});
    const [isGenerating, setIsGenerating] = useState(false);
    const [hasGenerated, setHasGenerated] = useState(false);
    const printRef = useRef(null);
    const quoteRef = useRef(null);

    const getFormattedDate = (date) => {
        const options = { month: 'numeric', day: 'numeric', year: 'numeric' };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    };

    const generateQuoteHandler = async (event) => {
        event.preventDefault();
        let errorCounter = 0;

        if(formDetails.org_name === '') {
            setErrorDetails(state => ({...state, org_name: 'This field is required.'}))
            errorCounter++;
        }
        if(formDetails.contact_name === '') {
            setErrorDetails(state => ({...state, contact_name: 'This field is required.'}))
            errorCounter++;
        }
        if(formDetails.contact_title === '') {
            setErrorDetails(state => ({...state, contact_title: 'This field is required.'}))
            errorCounter++;
        }
        if(formDetails.contact_email === '') {
            setErrorDetails(state => ({...state, contact_email: 'This field is required.'}))
            errorCounter++;
        }
        if (isNaN(formDetails.num_students) || formDetails.num_students <= 0) {
            setErrorDetails(state => ({...state, num_students: 'Please enter a valid number of students.'}));
            errorCounter++;
        }
        if(errorCounter !== 0) return;

        setIsGenerating(true);
        setHasGenerated(true);

        const today = new Date();
        const expiryDate = new Date();
        expiryDate.setDate(today.getDate() + 30);

        const formattedQuoteDate = getFormattedDate(today);
        const formattedExpiryDate = getFormattedDate(expiryDate);
        let newQuoteNum = Math.floor(Math.random() * (20000 - 5000 + 1)) + 5000;

        try {
            const snapshot = await get(child(ref(database), 'generated_quotes/config/last_quote_number'));
            if (snapshot.exists()) {
                // Ensure the value is a number
                const lastQuoteNumber = Number(snapshot.val());
    
                if (!isNaN(lastQuoteNumber)) {
                    // Increment the number by 1
                    newQuoteNum = lastQuoteNumber + 1;
                    /* console.log(`New Quote Number: ${newQuoteNum}`); */
                    // Continue with your logic, such as updating the database with the new quote number
                    // update(child(dbRef, 'generated_quotes/config/last_quote_number'), newQuoteNum);
                } else {
                    console.error('Last quote number is not a valid number.');
                }
            } else {
                console.error('No data available at the specified path.');
            }
        } catch (error) {
            console.error('Error fetching quote number:', error);
        }

        setGeneratedData({...formDetails, quote_date: formattedQuoteDate, expiry_date: formattedExpiryDate, quote_num: newQuoteNum});

        if(quoteRef.current) {
            quoteRef.current.scrollIntoView({
                behavior: 'smooth', // For smooth scrolling
                block: 'start',     // Aligns the element to the top
            });
        };

        // try to post this
        try {
            const updates = {};
            if(authUserData) {
                updates[`generated_quotes/${newQuoteNum}`] = {...formDetails, quote_date: formattedQuoteDate, expiry_date: formattedExpiryDate, submitted_by: { first_name: authUserData.first_name, last_name: authUserData.last_name, email: authUserData.email, uid: authUserData.token}};
            } else {
                updates[`generated_quotes/${newQuoteNum}`] = {...formDetails, quote_date: formattedQuoteDate, expiry_date: formattedExpiryDate};
            }
            updates[`generated_quotes/config/last_quote_number`] = increment(1)
            update(ref(database), updates);
        } catch (error) {
            console.error(error)
        }
        setIsGenerating(false);
    };

    const handlePrint = () => {
        const printWindow = window.open('', '', 'height=600,width=800');
        printWindow.document.write('<html><head><title>Brain Raider, LLC Quote Generator</title>');
        printWindow.document.write('</head><body ><br /><br /><br />');
        printWindow.document.write(printRef.current.innerHTML);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
    };

    return(
        <SingleColumn>
            <PageHeaderText>Quote Generator</PageHeaderText>
            <TextBlock>Enter the information below to get an instant quote right here in your browser - no account creation required!</TextBlock><br />
            <form style={{/*  border: '1px solid black', padding: '24px', marginTop: '10px', borderRadius: '12px' */}}>
                <div style={{display: 'flex', flexWrap: 'wrap', columnGap: '18px'}}>
                    <div style={{width: 'calc(50% - 9px)', flexBasis: '330px', margin: '0 auto'}}>
                        <TextInput name='org-name' placeholder='Organization Name' leftIcon='org' value={formDetails.org_name} error={errorDetails.org_name} onChange={(value) => {setFormDetails(state => ({...state, org_name: value})); setErrorDetails(state => ({...state, org_name: ''}));}} />
                    </div>
                    <div style={{width: 'calc(50% - 9px)', flexBasis: '330px', margin: '0 auto'}}>
                    <TextInput name='contact-name' leftIcon='person' placeholder='Contact Name' value={formDetails.contact_name} error={errorDetails.contact_name} onChange={(value) => {setFormDetails(state => ({...state, contact_name: value})); setErrorDetails(state => ({...state, contact_name: ''}));}} />
                    </div>
                    <div style={{width: 'calc(50% - 9px)', flexBasis: '330px', margin: '0 auto'}}>
                        <TextInput name='contact-title' placeholder='Contact Job Title' leftIcon='title' value={formDetails.contact_title} error={errorDetails.contact_title} onChange={(value) => {setFormDetails(state => ({...state, contact_title: value})); setErrorDetails(state => ({...state, contact_title: ''}));}} />
                    </div>
                    <div style={{width: 'calc(50% - 9px)', flexBasis: '330px', margin: '0 auto'}}>
                        <TextInput name='contact-information' leftIcon='mail' placeholder='Contact Email' value={formDetails.contact_email} error={errorDetails.contact_email} onChange={(value) => {setFormDetails(state => ({...state, contact_email: value})); setErrorDetails(state => ({...state, contact_email: ''}));}} />
                    </div>
                </div>
                <div style={{display: 'flex', flexWrap: 'wrap', columnGap: '18px'}}>
                    <div style={{width: '260px', flexBasis: '330px', margin: '0 auto'}}>
                        <PageHeaderText small right labelFor='num-students'>Number of Student Licenses</PageHeaderText>
                    </div>
                    <div style={{width: 'calc(100% - 278px)', flexBasis: '330px', margin: '0 auto'}}>
                        <TextInput name='num-students' value={formDetails.num_students} error={errorDetails.num_students} onChange={(value) => {setFormDetails(state => ({...state, num_students: value})); setErrorDetails(state => ({...state, num_students: ''}));}} />
                    </div>
                </div>
                <Button onClick={ generateQuoteHandler } confirm disabled={ isGenerating }>{isGenerating ? 'Generating...' : (hasGenerated ? 'Regenerate' : 'Generate')}</Button>
            </form>
            <HorizontalLine />
            <div ref={quoteRef} style={{height: '1px', fontSize: '1px'}}>&nbsp;</div>
            { hasGenerated &&
                <>
                <br />
                <div ref={printRef}>
                    <QuoteCard quote_num={generatedData.quote_num} clientName={generatedData.org_name} contactName={generatedData.contact_name} contactTitle={generatedData.contact_title} contactEmail={generatedData.contact_email} quoteDate={generatedData.quote_date} expiryDate={generatedData.expiry_date} licenses={generatedData.num_students}/>
                </div>
                <div style={{width: '100%', textAlign: 'right'}}>
                    <Button altblue onClick={handlePrint} fitted>Print this Quote</Button>
                </div>
                <br />
                <br />
                <br />
                <br />
                </>
            }
        </SingleColumn>
    )
};

export default QuoteGenerator;