import { useState, useEffect } from 'react';
import { database } from '../util/firebase';
import { ref, onValue, increment, update, get, child } from 'firebase/database';
import { useOutletContext, useNavigate } from 'react-router-dom';
import { getDocs, collection, query, where } from 'firebase/firestore';
import { firestoreDb } from '../util/firebase';
import SingleColumn from '../components/UI/SingleColumn';
import PageHeaderText from '../components/UI/PageHeaderText';
import UsersTable from '../components/Cards/UsersTable';
import TextBlock from '../components/UI/TextBlock';
import OrgsTable from '../components/Cards/OrgsTable';
import PulloutDrawer from '../components/UI/PulloutDrawer';
import TextInput from '../components/UI/TextInput';
import Button from '../components/UI/Button';
import TextAreaInput from '../components/UI/TextAreaInput';

const Users = () => {
    const [setHeaderSize, authUserData] = useOutletContext();
    useEffect(() => {setHeaderSize('small');}, [setHeaderSize]);
    const navigate = useNavigate()

    const firestoreTimestampToDate = (timestamp) => {
        if (timestamp && timestamp.seconds) {
            return new Date(timestamp.seconds * 1000 + (timestamp.nanoseconds / 1000000));
        }
        return null;
    };

    const fetchSubExpiry = async (uid) => {
        // create a query object to the current users active subscriptions
        const q = query(
            // currentUser is provided by firebase, via getAuth().currentUser
            collection(firestoreDb, 'customers', uid, 'subscriptions'), 
            where('status', 'in', ['trialing', 'active'])
        );
        
        // fetch the active subscriptions
        const querySnapshot = await getDocs(q);

        // assuming user only has one active subscription max
        if(querySnapshot?.docs[0]?.data()) {
            return querySnapshot?.docs[0]?.data()?.current_period_end;
        } else {
            return null;
        }
    }

    const [realtimeUsers, setRealtimeUsers] = useState(null);
    const [subCount, setSubCount] = useState(0);
    useEffect(() => {
        const unsubscribe = onValue(ref(database, 'user_accounts'), async (snapshot) => {
            const users = snapshot.val();
            let subCounter = 0;
            if (users) {
                const updatedUsers = {};
                const currentDate = new Date();

                for (const userId in users) {
                    const user = users[userId];
                    if (user.trial_expiry) {
                        const trialExpiryDate = new Date(user.trial_expiry);
                        if (currentDate > trialExpiryDate) {
                            const subExpiryDate = await fetchSubExpiry(userId);
                            updatedUsers[userId] = {
                                ...user,
                                sub_expiry: subExpiryDate
                            };
                            const currentDate = new Date();
                            const dateOfSubExpiry = firestoreTimestampToDate(subExpiryDate);
                            if (dateOfSubExpiry > currentDate) {
                                subCounter++
                            }
                        } else {
                            updatedUsers[userId] = user;
                        }
                    } else {
                        updatedUsers[userId] = user;
                    }
                }

                setRealtimeUsers(updatedUsers);
                setSubCount(subCounter);
            }
        })

        return () => unsubscribe();
    }, []);

    const [rosteredOrgs, setRosteredOrgs] = useState(null);
    useEffect(() => {
        const unsubscribe = onValue(ref(database, `rostered_orgs`), (snapshot) => {setRosteredOrgs(snapshot.val());});
        return () => unsubscribe();
    }, []);

    const [orgAccounts, setOrgAccounts] = useState(null);
    useEffect(() => {
        const unsubscribe = onValue(ref(database, `org_accounts`), (snapshot) => {setOrgAccounts(snapshot.val());});
        return () => unsubscribe();
    }, []);

    const [analytics, setAnalytics] = useState(null);
    useEffect(() => {
        const unsubscribe = onValue(ref(database, `analytics`), (snapshot) => {setAnalytics(snapshot.val());});
        return () => unsubscribe();
    }, []);

    const extendHandler = (user) => {
        if(window.confirm(`Are you sure you'd like to add 30 days to ${realtimeUsers[user]?.first_name} ${realtimeUsers[user]?.last_name}'s trial?`)) {
            // add 30 days to the trial_expiry
            let trialExpiryDate = new Date(realtimeUsers[user]?.trial_expiry);
            // console.log("Old Expiry Date: ", trialExpiryDate)
            trialExpiryDate.setDate(trialExpiryDate.getDate() + 30);
            // console.log("New Expiry Date: ", trialExpiryDate)
            
            // update the database with the new date and increment the number of extensions granted
            const updates = {};
            updates[`users/${user}/trial_expiry`] = trialExpiryDate.toISOString();
            updates[`user_accounts/${user}/trial_expiry`] = trialExpiryDate.toISOString();
            updates[`user_accounts/${user}/trial_extensions`] = increment(1);
            update(ref(database), updates);
        }
    };

    const impersonateHandler = (user) => {
        if(window.confirm('Are you sure?')) {
            if(!authUserData || !authUserData?.roles?.admin) return;
            // set the is_impersonating key under authUser data to the uid to be impersonated
            console.log(user)
            
            if(authUserData.token !== user) {
                const updates = {};
                updates[`users/${authUserData.token}/is_impersonating`] = user;
                update(ref(database), updates);
                navigate('/groups');
            } else {
                console.error('Cannot impersonate yourself.');
            };
        }
    };

    const toggleClickHandler = (org_id) => {
        if(orgAccounts[org_id]?.sub_status === 'active') {
            disableClasslinkOrgHandler(org_id)
        } else {
            enableClasslinkOrgHandler(org_id)
        }
    }

    const enableClasslinkOrgHandler = (org_id) => {
        if(window.confirm('Are you sure?')) {
            // for every teacher in the org, set the appropriate flag to indicate they are a paid user
            get(child(ref(database), `rostered_orgs/${org_id}`))
            .then((snapshot) => {
                if (snapshot.exists()) {
                    const updates = {};
                    // add all teacher accounts to a set
                    const teacherUIDs = new Set();

                    for (const orgId in snapshot.val().orgs) {
                        const org = snapshot.val().orgs[orgId];
                        for (const classId in org.classes) {
                            const classObj = org.classes[classId];
                            for (const teacherId in classObj.teachers) {
                                const teacher = classObj.teachers[teacherId];
                                teacherUIDs.add(teacher.firebase_uid);
                            }
                        }
                    }

                    for (const uid of teacherUIDs) {
                        updates[`users/${uid}/classlink_paid`] = true;
                    }

                    updates[`org_accounts/${org_id}/sub_status`] = 'active';
                    update(ref(database), updates);
                }
            })

            // TODO: edit the python rostering script to automatically apply the flag to new accounts
        }
    }

    const disableClasslinkOrgHandler = (org_id) => {
        if(window.confirm('Are you sure?')) {
            // for every teacher in the org, set the appropriate flag to indicate they are a paid user
            get(child(ref(database), `rostered_orgs/${org_id}`))
            .then((snapshot) => {
                if (snapshot.exists()) {
                    const updates = {};
                    // add all teacher accounts to a set
                    const teacherUIDs = new Set();

                    for (const orgId in snapshot.val().orgs) {
                        const org = snapshot.val().orgs[orgId];
                        for (const classId in org.classes) {
                            const classObj = org.classes[classId];
                            for (const teacherId in classObj.teachers) {
                                const teacher = classObj.teachers[teacherId];
                                teacherUIDs.add(teacher.firebase_uid);
                            }
                        }
                    }

                    for (const uid of teacherUIDs) {
                        updates[`users/${uid}/classlink_paid`] = false;
                    }

                    updates[`org_accounts/${org_id}/sub_status`] = {};
                    update(ref(database), updates);
                }
            })
        }
    }

    const [showDrawer, setShowDrawer] = useState(false);
    const [editingOrg, setEditingOrg] = useState({ _id: '', org_name: '', contact_info: '', notes: ''});
    const [editingOrgErrors, setEditingOrgErrors] = useState({ org_name: '' });
    const editOrgClickHandler = (org_id) => {
        setShowDrawer(true);
        const org_name = (orgAccounts && orgAccounts[org_id]?.org_name) ? orgAccounts[org_id].org_name : '';
        const contact_info = (orgAccounts && orgAccounts[org_id]?.contact_info) ? orgAccounts[org_id].contact_info : '';
        const notes = (orgAccounts && orgAccounts[org_id]?.notes) ? orgAccounts[org_id].notes : '';
        setEditingOrg({ _id: org_id, org_name: org_name, contact_info: contact_info, notes: notes });
        setEditingOrgErrors({ org_name: '', contact_info: '', notes: '' });
    }

    const [isSubmitting, setIsSubmitting] = useState(false);
    const submitFormHandler = (event) => {
        event.preventDefault();
        console.log(editingOrg)

        // do any validation
        let errorCount = 0
        if(editingOrg.org_name === '') {
            errorCount++
            setEditingOrgErrors(state => ({ ...state, org_name: 'Organization must have a name.'}))
        }
        if(errorCount !== 0) return;

        setIsSubmitting(true);

        const updates = {}
        updates[`org_accounts/${editingOrg._id}/org_name`] = editingOrg.org_name;
        updates[`org_accounts/${editingOrg._id}/contact_info`] = editingOrg.contact_info;
        updates[`org_accounts/${editingOrg._id}/notes`] = editingOrg.notes;
        update(ref(database), updates);

        setIsSubmitting(false);
        setShowDrawer(false);
        setEditingOrg({ _id: '', org_name: '', contact_info: '', notes: '' });
        setEditingOrgErrors({ org_name: '', contact_info: '', notes: '' });
    }

    function capitalizeFirstLetter(str) {
        if (!str) return str; // Return the input if it's empty or null
    
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return(
        <SingleColumn wide>
            { authUserData && ('admin' in authUserData?.roles) &&
                <>
                    <PageHeaderText>Statistics</PageHeaderText>
                    <TextBlock>
                        Homepage Visits: {analytics?.homepage_visits || 0}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Total Sign-Ups: {realtimeUsers ? Object.keys(realtimeUsers).length : 0}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Total Active Subs: {subCount}<br /><br />
                        {analytics?.homepage_referrers && Object.keys(analytics.homepage_referrers).length > 0 && (
                            <>
                                <strong>Referrers:&nbsp;</strong>
                                {Object.keys(analytics.homepage_referrers).map(key => <span>{capitalizeFirstLetter(key)}: {analytics.homepage_referrers[key]}&nbsp;&nbsp;&nbsp;&nbsp;</span>)}
                            </>
                        )}
                    </TextBlock>
                    <br />
                    <PageHeaderText>All Rostered Orgs</PageHeaderText>
                    { rosteredOrgs && orgAccounts && 
                        <OrgsTable rosteredOrgs={rosteredOrgs} orgAccounts={orgAccounts} onEdit={editOrgClickHandler} onToggle={toggleClickHandler}/>
                    }
                    <br />
                    <br />
                    <PageHeaderText>All Teacher Users</PageHeaderText>
                    { realtimeUsers &&
                        <UsersTable teacherUsers={realtimeUsers} onExtend={extendHandler} onImpersonate={impersonateHandler}/>
                    }
                    <br /><br /><br /><br />
                    <PulloutDrawer show={showDrawer} header={'Edit Organization'} onCancel={() => {setShowDrawer(false); setEditingOrg({ _id: '', org_name: '', contact_info: '', notes: '' });}}>
                        <form>
                            <PageHeaderText small labelFor='org-name'>Name</PageHeaderText>
                            <TextInput name='org-name' value={ editingOrg?.org_name } onChange={(value) => {setEditingOrg(state => ({...state, org_name: value}))}} error={ editingOrgErrors.org_name }/>
                            <PageHeaderText small labelFor='contact-info'>Contact Info</PageHeaderText>
                            <TextInput name='contact-info' value={ editingOrg?.contact_info } onChange={(value) => {setEditingOrg(state => ({...state, contact_info: value}))}} error={ editingOrgErrors.contact_info }/>
                            <PageHeaderText small labelFor='notes'>Notes</PageHeaderText>
                            <TextBlock>Include information about how many licenses were purchased, when the paid subscription expires, etc.</TextBlock>
                            <TextAreaInput name='notes' value={ editingOrg?.notes } onChange={(value) => {setEditingOrg(state => ({...state, notes: value}))}} error={ editingOrgErrors.notes }/>
                            <Button onClick={ submitFormHandler } disabled={ isSubmitting }>{isSubmitting ? 'Submitting...' : 'Submit'}</Button>
                        </form>
                    </PulloutDrawer>
                </>
            }
        </SingleColumn>
    )
};

export default Users;