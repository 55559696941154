import { useState } from 'react';
import { database } from '../../util/firebase';
import { ref, child, get } from 'firebase/database';
/* import { secondaryAuth } from '../../util/firebase';
import { sendPasswordResetEmail } from 'firebase/auth'; */
import actionsIconAlt from '../../images/actions-icon-alt.svg';
import InlineButton from '../UI/InlineButton';
import classes from './Student.module.css';

const Student = (props) => {
    const [isExpanded, setIsExpanded] = useState(false);

    // TODO: This no longer works after enabling email enumeration.
    /* const [passwordResetSent, setPasswordResetSent] = useState(false);
    const resetPasswordHandler = () => {
        sendPasswordResetEmail(secondaryAuth, props.student.email)
        setPasswordResetSent(true);
    }; */

    const fetchEmailThenEditStudent = () => {
        get(child(ref(database), `users/${props.student._id}/email`)).then((snapshot) => {
            if (snapshot.exists()) {
                props.onEdit(props.student._id, props.student.first_name, props.student.last_name, snapshot.val(), props.student.sis_id);
            } else {
                console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
        });
    };

    return (
        <div className={ `${classes.studentWrapper} ${props.last ? classes.last : '' } ${ isExpanded ? classes.expanded : '' }` }>
            <div className={ classes.studentName }>
                <span className={ classes.studentName }>{ props.student.last_name }, { props.student.first_name }</span>
            </div>
            { !props.disableChages && <img src={ actionsIconAlt } alt='More...' className={ classes.actionsIcon } onClick={() => {setIsExpanded(value => !value) }} />}
            <div className={ classes.actionsWrapper }>
                <InlineButton icon='edit' onFocus={ setIsExpanded } onClick={ fetchEmailThenEditStudent }>Edit<span className={ classes.smallCollapse }> Student</span>...</InlineButton>
                { props.canMove && <InlineButton icon='move' onFocus={ setIsExpanded } onClick={ props.onMove.bind(null, props.student._id, props.student.first_name, props.student.last_name, props.auto_group_id, props.auto_group_name, props.student.sis_id)} disabled={props.moveDisabled}>Move to Group...</InlineButton>}
                <InlineButton icon='archive' onFocus={ setIsExpanded } onClick={ props.onArchive.bind(null, props.student._id) }>Archive<span className={ classes.smallCollapse }> Student</span></InlineButton>
                {/* <InlineButton icon='reset' onFocus={ setIsExpanded } onClick={ resetPasswordHandler } disabled={ passwordResetSent }>{ passwordResetSent ? 'Email Sent!' : 'Reset Password'}</InlineButton> */}
            </div>
        </div>
    );
};

export default Student;