import SingleColumn from '../components/UI/SingleColumn';
import { useEffect } from 'react';
import { useOutletContext, useNavigate } from 'react-router-dom';
import TextBlock from '../components/UI/TextBlock';
import PageHeaderText from '../components/UI/PageHeaderText';
import HomepageBottomBar from '../components/Cards/HomepageBottomBar';

const TermsPage = () => {
    const [setHeaderSize] = useOutletContext();
    useEffect(() => {setHeaderSize('small');}, [setHeaderSize]);

    const navigate = useNavigate();

  return (
    <SingleColumn>
        <PageHeaderText>Brain Raider, LLC Terms of Service</PageHeaderText>
        <TextBlock>
            Effective date: July 1, 2023<br /><br />
            Welcome to Brain Raider, LLC! These Terms of Service ("Terms") govern your access to and use of the Service provided by Brain Raider, LLC, including our website ( the "Service"). By accessing or using our Service, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use our Service.
            <br /><br /><strong>1. Acceptance of Terms</strong><br /><br />
            By accessing or using our Service, you affirm that you are at least 18 years old or the age of majority in your jurisdiction and have the legal capacity to enter into these Terms. If you are accessing or using our Service on behalf of a school or educational institution, you represent and warrant that you have the authority to bind such institutions to these Terms.
            <br /><br /><strong>2. Account Registration</strong><br /><br />
            In order to access certain features of our Service, you may be required to create an account. When registering for an account, you agree to provide accurate and complete information, including your email address, first name, last name, and password. You are solely responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
            <br /><br /><strong>3. Use of Service</strong><br /><br />
            Our Services are intended for use by teachers and educational institutions to create student accounts, administer assessments, and analyze assessment results. You agree to use our Service only for lawful purposes and in accordance with these Terms and all applicable laws and regulations.
            <br /><br /><strong>4. Student Accounts</strong><br /><br />
            Teachers and educational institutions may create student accounts for the purpose of administering assessments. Student accounts require the provision of email addresses, first names, last names, and passwords. By creating student accounts, you represent and warrant that you have obtained any necessary parental consent and that you will comply with all applicable laws and regulations regarding the collection and use of student information.
            <br /><br /><strong>5. Intellectual Property</strong><br /><br />
            The Service and all content, features, and functionality provided by Brain Raider, LLC are owned by or licensed to Brain Raider, LLC and are protected by copyright, trademark, and other intellectual property laws. You may not modify, reproduce, distribute, or create derivative works based on our Service without our prior written consent.
            <br /><br /><strong>6. Privacy</strong><br /><br />
            We are committed to protecting the privacy of our users. Please review our <span onClick={() => navigate('/privacy-policy')} style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}>Privacy Policy</span> to understand how we collect, use, and disclose information about you and your use of our Service.
            <br /><br /><strong>7. Termination</strong><br /><br />
            Brain Raider, LLC reserves the right to terminate or suspend your access to all or any part of the Service at any time, with or without cause, and with or without notice.
            <br /><br /><strong>8. Limitation of Liability</strong><br /><br />
            In no event shall Brain Raider, LLC, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Service.
            <br /><br /><strong>9. Governing Law</strong><br /><br />
            These Terms shall be governed by and construed in accordance with the laws of the State of Texas, without regard to its conflict of law provisions.
            <br /><br /><strong>10. Contact Us</strong><br /><br />
            If you have any questions about these Terms or our Service, please contact us at <a href="mailto:support@brainraider.com">support@brainraider.com</a>.
            <br /><br />By using our Service, you acknowledge that you have read, understood, and agree to be bound by these Terms.<br /><br />
        </TextBlock>
        <HomepageBottomBar />
    </SingleColumn>
  );
}

export default TermsPage;