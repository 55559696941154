import classes from './ReviewDragDropAnchorSelector.module.css';
import { useState, useEffect } from "react";

const ReviewDragDropAnchorSelector = (props) => {
    const [anchors, setAnchors] = useState([]);

    useEffect(() => {
        if(anchors.length > 1 && !props.static) return;

        const updatedAnchors = props.initialAnchors || [];
        setAnchors(updatedAnchors);
        props.onChangeAnswerRegions && props.onChangeAnswerRegions(updatedAnchors);
        // eslint-disable-next-line
    }, [props.initialAnchors]);

    // TODO: Clean up unneccesary CSS still left from ImageCanvas
    return (
        <div className={classes.bigWrapper}>
            <div className={`${classes.wrapper} ${anchors.length > 0 && !props.static && !props.studentMode ? classes.hasResetButton : ''} ${props.static ? classes.static : ''} ${props.studentMode ? classes.studentMode : '' }`}>
                <img className={classes.image} src={props.imagesrc} alt="" />
                {
                    Object.keys(anchors)?.map(key =>
                        <div
                            key={key}
                            className={`${classes.anchorCircle} ${anchors[key].isCorrect ? classes.correct : ''} ${props.static ? classes.static : ''} ${props.studentMode ? classes.studentMode : ''}`}
                            style={{ top: `${anchors[key].y}%`, left: `${anchors[key].x}%` }}>{anchors[key].display_num}
                        </div>
                    )
                }
            </div>
            {
                anchors.length > 0 && !props.static && !props.studentMode && 
                <button className={classes.resetButton} onClick={() => {setAnchors([]); props.onChangeAnchors([]);}}>Reset All Anchors</button>
            }
        </div>
    );
};

export default ReviewDragDropAnchorSelector;