import classes from './StandaloneError.module.css';

const StandaloneError = (props) => {
    // console.log(props);
    return (
        <div className={ `${ classes.errorWrapper } ${ props.children ? '' : classes.hidden } ${ props.spaceafter ? classes.spaceAfter : '' }`}>
            { props.children }
        </div>
    );
};

export default StandaloneError;